import React from "react";
import { Container } from "react-bootstrap";


function Intro_NLP(){

    return(
        <>
            <Container className="mt-3">
                <h4 className="fw-bold">- 자연어 처리 AI는 무엇인가요?</h4>
                <div className="ps-lg-4 lh-lg text-secondary">
                자연어처리 AI는 우리가 일상 속에서 사용하는 언어인 자연어(Narural Language)를 분석하는 인공지능입니다.<br/>
나미에스엔티 AI의 자연어처리 AI는 구글에서 개발한 Word2Vec와 한국지능정보사회진흥원에서 운영하는 AI HUB의 빅데이터를 이용하여
단어간의 관계성을 학습하였습니다.<br/>
                그리고 각 단어의 유사도(Cosine Distance)를 연산하여 단어 사이의 관계성을 보여줍니다.
                <br/>
                또한, Word2Vec에서 지원하지 않는 가속기술을 적용하여 기존 Word2Vec보다 월등히 빠른 연산을 지원합니다.
                <img className="m-auto d-block w-100 w-md-60" src={process.env.PUBLIC_URL + '/assets/img/nlp_intro1.png'} />
                </div>
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- 자연어 처리 AI의 활용법</h4>
                <div className="ps-lg-4 lh-lg text-secondary">
                나미에스엔티 AI의 자연어처리 AI는 단어의 관계성을 필요로 하는 모든 곳에 활용이 가능합니다.<br/>
                예를 들어 문서 분류, 문장 재조합 등에 활용될 수 있습니다.<br/>
                <img className="m-auto d-block w-100 w-md-60" src={process.env.PUBLIC_URL + '/assets/img/nlp_intro3.png'} />
                </div>
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- 자연어처리 AI의 기능소개</h4>
                <h5 className="ps-lg-4 mt-3">1. 한 단어쌍(Single Word)</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                    하나의 단어쌍에 대한 유사도를 연산하여 반환하는 API입니다.<br/>
                    주어진 각 단어를 하나의 단어로 보지 않고, 형태소단위로 추가 분리하여 보다 정밀한 의미파악이 가능합니다.<br/>
                    ('사과나무' 와 '수정테이프'의 비교 =&gt; '사과' '나무', '수정' '테이프'로 분리하여 유사도를 연산)
                </div>
                <h5 className="ps-lg-4 mt-3">2. 여러 단어쌍(Multi Words)</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                    여러 개의 단어쌍을 한번에 입력받아 유사도를 연산하여 반환하는 API입니다.<br/>
                    주어진 각 단어를 하나의 단어로 보지 않고, 형태소단위로 추가 분리하여 보다 정밀한 의미파악이 가능합니다.<br/>
                </div>
                <h5 className="ps-lg-4 mt-3">3. 여러 단어쌍(고속) (Batch Words)</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                    단어쌍이 아닌, 여러 개의 단어를 한번에 입력받아, 입력받은 단어에 대하여 모든 관계성을 고속으로 비교합니다.<br/>
                    ('냉장고', '에어컨' '고양이'의 비교 -&gt; '냉장고'와 '에어컨', '냉장고'와 '고양이, '에어컨'과 '고양이'를 고속 비교)<br/>
                    <br/>고속 연산에는 선형대수에 기반한 가속기술이 적용되었습니다.
                </div>
                <img className="m-auto d-block w-100 w-md-60" src={process.env.PUBLIC_URL + '/assets/img/nlp_intro2.png'} />
                <img className="m-auto d-block w-100 w-md-60" src={process.env.PUBLIC_URL + '/assets/img/nlp_intro4.png'} />
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- 자연어 처리 AI만의 강점</h4>
                <h5 className="ps-lg-4 mt-3">1. 빠르고 안정적인 서비스</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                    나미에스엔티 AI에서 제공하는 자연어처리 AI는 풍부한 컴퓨팅 서버 자원을 기반으로 빠르고 안정적인 서비스를 제공합니다.<br/>
                    기존과 같이 데이터셋을 찾아 PC로 학습하여 구동하는 방식에서 자유롭습니다.
                </div>
                <h5 className="ps-lg-4 mt-3">2. 선형대수를 이용한 가속연산</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                선형대수를 이용한 가속기술을 적용하여, 한번에 많은 단어쌍 연산이 필요한 경우 기존 Word2Vec 라이브러리에 비해 월등히 빠른 속도를 경험하실 수 있습니다.
                </div>
                <h5 className="ps-lg-4 mt-3">3. 지속적인 연구</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                    지속적인 연구를 통해 다양한 버전의 API를 사용하실 수 있습니다.
                </div>
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- API 버전 소개</h4>
                <div className="ps-lg-4 lh-lg text-secondary">
                2023-02-12 : 한국어 위키백과 자료를 기반으로 학습된 인공지능 모델을 사용합니다. 개념의 정의와 설명 등에 대한 단어 관계를 비교하는데 유리합니다.<br/>
2023-04-28 : 한국지능정보사회진흥원에서 운영하는 AI HUB의 데이터를 학습한 인공지능모델을 사용합니다. 뉴스기사 등에 등장하는 단어 관계를 비교하는데 유리합니다.
                </div>
            </Container>
        </>
    );

}

export default Intro_NLP;