import React from "react";
import { useState } from "react";
import { Button, Container, Form, InputGroup, ListGroup, Modal, Nav, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Banner from "../../common/banner";
import Privacy_body from "../elements/policy/privacy";
import { useEffect } from "react";
import axios from "axios";
import Loading from "../../common/Loading";
import Modal_alert from "../../common/modal";
import Agreement_body from "../elements/policy/agreement";


function Register_form(){
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [isBusiness, setIsbusiness] = useState(location.state == null ? false : location.state.isbusiness);
    const [values, setValues] = useState({name:"", nickname:"", id:"", pw:"", pw_cfm:"", email:"", phonenumber1:"", phonenumber2:"", agreement:false, privacy:false, age:false, marketing:false});
    const [validated, setValidated] = useState(false);
    const [register_complete_show, setRegisterCompleteShow] = useState(false);
    const [register_failed_show, setRegisterFailedShow] = useState(false);

    const [sns_register, setSNSRegister] = useState(false);

    const [emailauth, setEmailAuth] = useState(false);
    const [emailauth_cnt, setEmailAuthCount] = useState(300);
    const [emailauthNumber, setEmailAuthNumber] = useState("");

    const [agreement_show, setAgreementShow] = useState(false);
    const [privacy_show, setPrivacyShow] = useState(false);

    const [name_check, setNameCheck] = useState(true);
    const [nickname_check, setNicknameCheck] = useState(true);
    const [id_check, setIDcheck] = useState(true);
    const [pw_check, setPWcheck] = useState(true);
    const [pw_cfm_check, setPWCFMcheck] = useState(true);
    const [email_check, setEmailcheck] = useState(true);
    const [emailauth_check, setEmailAuthcheck] = useState(true);
    const [pn1_check, setPN1check] = useState(true);

    const [taxid_check, setTaxIDcheck] = useState(true);
    const [ceo_name_check, setCEONAMEcheck] = useState(true);

    const [name_valid, setNameValid] = useState(true);
    const [nickname_valid, setNicknameValid] = useState(false);
    const [id_valid, setIDValid] = useState(false);
    const [pw_valid, setPWValid] = useState(false);
    const [pw_cfm_valid, setPWCFMValid] = useState(false);
    const [email_valid, setEmailValid] = useState(false);
    const [emailauth_valid, setEmailAuthValid] = useState(false);
    const [pn1_valid, setPN1Valid] = useState(false); //useState(false);
    
    const [taxid_valid, setTaxIDValid] = useState(true);
    const [ceo_name_valid, setCEONAMEValid] = useState(true);

    const handleNameChange = e => {
        if(e.target.value == "" || e.target.value.length >5){
            setNameCheck(false);
            setNameValid(false);
        }else{
            setNameCheck(true);
            setNameValid(true);
        }
        handleChange(e);
    }
    const handleNicknameChange = e => {
        setNicknameValid(false);
        if(e.target.value == "" || e.target.value.length >5){
            setNicknameCheck(false);
        }else{
            setNicknameCheck(true);
        }
        handleChange(e);
    }
    const handleIDChange = e => {
        setIDValid(false);
        if(e.target.value == "" || e.target.value.length <5){
            setIDcheck(false);
        }else{
            setIDcheck(true);
        }
        handleChange(e);
    }
    const handlePWChange = e => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
        if(e.target.value == "" || !passwordRegex.test(e.target.value) ){
            setPWcheck(false);
            setPWValid(false);
        }else{
            setPWcheck(true);
            setPWValid(true);
        }
        handleChange(e);
    }
    const handlePWCfmChange = e => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
        if(e.target.value == "" || !passwordRegex.test(e.target.value)  || values.pw !== e.target.value){
            setPWCFMcheck(false);
            setPWCFMValid(false);
        }else{
            setPWCFMcheck(true);
            setPWCFMValid(true);
        }
        handleChange(e);
    }
    const handleEmailChange = e => {
        setEmailAuth(false);
        setEmailValid(false);
        setEmailAuthcheck(false);
        setEmailAuthValid(false);
        handleChange(e);
    }
    const handlePN1Change = e => {
        setPN1Valid(false);
        const regex = /^[0-9]+$/;
        if(e.target.value == "" || e.target.value.length <9 ||!regex.test(e.target.value)){
            setPN1check(false);
            setPN1Valid(false);
        }else{
            setPN1check(true);
            setPN1Valid(true);
        }
        handleChange(e);
    }

    const handleTAXIDChange = e => {
        setTaxIDValid(false);
        if(e.target.value == "" || e.target.value.length <5){
            setTaxIDcheck(false);
        }else{
            setTaxIDcheck(true);
        }
        handleChange(e);
    }

    const handleCEONAMEChange = e => {
        setCEONAMEValid(false);
        if(e.target.value == "" || e.target.value.length <5){
            setCEONAMEcheck(false);
        }else{
            setCEONAMEcheck(true);
        }
        handleChange(e);
    }


    const handleChange = e => {
        console.log(e.target.name+" : "+e.target.value);
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
    }

    const handleAgree = () =>{
        setValues({
            ...values,
            agreement :  !(values.agreement),
        })
    }

    const handlePrivacy = () =>{
        setValues({
            ...values,
            privacy :  !(values.privacy),
        })
    }

    const handleAge = () =>{
        setValues({
            ...values,
            age :  !(values.age),
        })
    }

    const handleMarketing = () =>{
        setValues({
            ...values,
            marketing :  !(values.marketing),
        })
    }

    
    const checkSNSRegister = ()=>{
        setLoading(true);
        axios.get('/sso/rest/checkSNSRegister').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                if(response.data.sns_register == true){
                    setSNSRegister(true);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                alert("SNS 계정확인에 실패하였습니다. 잠시후 다시 시도해주세요");
                console.log(error);
            }
        );
    }

    
    const checkDuplicaton = (type, value)=>{
        setLoading(true);
        axios.get('/sso/rest/checkDuplication',{params:
            {
                type:type,
                value:value
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                if(response.data.result == "success"){
                    if(type == "id"){
                        setIDValid(true);
                        setIDcheck(true);
                    }else if(type == "nickname"){
                        setNicknameValid(true);
                        setNicknameCheck(true);
                    }else if(type=="phonenumber1"){
                        setPN1Valid(true);
                        setPN1check(true)
                    }
                }else{
                    if(type == "id"){
                        setIDcheck(false);
                        setIDValid(false);
                    }else if(type == "nickname"){
                        setNicknameCheck(false);
                        setNicknameValid(false);
                    }else if(type=="phonenumber1"){
                        setPN1check(false);
                        setPN1Valid(false);
                    }
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                alert("중복확인에 실패하였습니다. 잠시후 다시 시도해주세요");
                console.log(error);
            }
        );
    }
    const requestRegist = ()=>{
        setLoading(true);
        axios.post('/sso/rest/registerMember',
        { 
            member_type:location.state == null ? '' : location.state.isBusiness ? 'business' : 'person',
            name : values.name,
            nickname : values.nickname,
            id : values.id,
            pw : values.pw,
            pw_cfm : values.pw_cfm,
            email : values.email,
            phonenumber1 : values.phonenumber1,
            phonenumber2 : values.phonenumber2,
            agreement_agree : values.agreement,
            privacy_agree : values.privacy,
            over14age : values.age,
            receive_marketing : values.marketing,
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("요청 오류가 발생하였습니다.");
            }else{
                if(response.data.result == 'success'){
                    setRegisterCompleteShow(true);
                }else{
                    setRegisterFailedShow(true);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                console.log(error);
                return false;
            }
        );
    }


    const emailAuth = (email_addr)=>{
        setLoading(true);
        axios.post('/sso/rest/sendAuthMail',
        { 
            email:email_addr
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("요청 오류가 발생하였습니다.");
                return 0;
            }else{
                if(response.data.result == 'success'){
                    setEmailAuth(true);
                    setEmailAuthCount(300);
                    setEmailcheck(true);
                }else if(response.data.result == 'duplicated email'){
                    setEmailcheck(false);
                }else{
                    alert("인증메일 전송에 실패하였습니다. 잠시후 다시 시도해주시거나 관리자에게 문의해주세요.");
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                console.log(error);
                return 0;
            }
        );
    }
    const checkAuthCode = (code)=>{
        setLoading(true);
        axios.post('/sso/rest/checkAuthCode',
        { 
            authcode:code
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("요청 오류가 발생하였습니다.");
            }else{
                if(response.data.result == 'success'){
                    setEmailValid(true);
                    setEmailAuthValid(true);
                    setEmailAuthcheck(true);
                }else{
                    setEmailAuthValid(false);
                    setEmailAuthcheck(false);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                console.log(error);
                return false;
            }
        );
    }              

    const sendEmail = ()=>{
        //TODO : requesting send sms to server
        setEmailValid(false);
        setEmailAuthValid(false);
        if(values.email == "")
            return;
        emailAuth(values.email);
    }

    useEffect(()=>{
        const cnt = setInterval(()=>{
            setEmailAuthCount(setEmailAuth => setEmailAuth -1);
        }, 1000);
        if(emailauth_cnt === 0 || emailauth_valid ==true){
            clearInterval(cnt);
            setEmailAuth(false);
        }
        return ()=>clearInterval(cnt);
    }, [emailauth_cnt]);

    useEffect(()=>{
        if(sns_register == false){
            if (values.name.length > 0 && name_check && values.nickname.length >0 && nickname_check && values.id.length > 0 && id_check && values.pw.length > 0 &&  pw_check && values.pw_cfm.length > 0 && pw_cfm_check && emailauthNumber.length > 0 && emailauth_check && values.phonenumber1.length > 0 && pn1_check && values.agreement && values.privacy && values.age)
                setValidated(true);
            else
                setValidated(false);
        }else{
            if (values.phonenumber1.length > 0 && pn1_check && values.agreement && values.privacy && values.age)
                setValidated(true);
            else
                setValidated(false);
        }

    }, [values, pn1_check, name_check, nickname_check, id_check, pw_check, pw_cfm_check, emailauth_check]);

    useEffect(()=>{
        //console.log(validated);
    }, [validated]);

    useEffect(()=>{checkSNSRegister();}, []);
    return(
        <>
            {loading == true ? <Loading/> : ""}
            <div className="banner-slider">
                <Banner />
            </div>
            <section className="sections-white pt-5">
                <Container className=" text-center">
                    <strong className="h5 fw-bold">나미에스엔티 통합회원 회원가입</strong>
                    <div className="h6 m-4">가입정보를 입력해주세요</div>
                </Container>
                <Form noValidate className="mw-100 m-auto" style={{width:"400px"}}>
                    {sns_register == false ?
                    <>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>아이디</Form.Label>
                                <InputGroup>
                                    <Form.Control isValid={id_valid} isInvalid={!id_check} required type="text" name="id" value={values.id} onChange={handleIDChange} placeholder="사용하실 아이디를 입력해주세요."/>
                                    <Button variant="light" onClick={(e)=>{checkDuplicaton("id", values.id)}}>중복확인</Button>
                                    <Form.Control.Feedback type="invalid">
                                        아이디가 중복되거나 올바르지 않습니다. 5자 이상의 아이디를 입력해주세요.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback>중복확인 되었습니다 :)</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>비밀번호</Form.Label>
                                <Form.Control isValid={pw_valid} isInvalid={!pw_check} required type="password" name="pw" value={values.pw} onChange={handlePWChange} placeholder="사용하실 비밀번호를 입력해주세요."/>
                                <Form.Control.Feedback type="invalid">
                                    8자이상, 특수문자가 포함된 비밀번호를 입력해주세요.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">좋은 비밀번호입니다 :)</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>비밀번호확인</Form.Label>
                                <Form.Control isValid={pw_cfm_valid} isInvalid={!pw_cfm_check} required type="password" name="pw_cfm" value={values.pw_cfm} onChange={handlePWCfmChange} placeholder="비밀번호를 다시 입력해주세요."/>
                                <Form.Control.Feedback type="invalid">
                                    비밀번호와 확인이 일치하지 않습니다.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">두 비밀번호가 모두 일치합니다 :D</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>이름</Form.Label>
                                <Form.Control  required type="text" name="name" value={values.name} onChange={handleNameChange} placeholder="이름을 입력해주세요."/>
                                <Form.Control.Feedback type="invalid">
                                    이름을 올바르게 입력해주세요.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>닉네임</Form.Label>
                                <InputGroup>
                                    <Form.Control isValid={nickname_valid} isInvalid={!nickname_check} required type="text" name="nickname" value={values.nickname} onChange={handleNicknameChange} placeholder="사용하실 닉네임을 입력해주세요."/>
                                    <Button variant="light" onClick={(e)=>{checkDuplicaton("nickname", values.nickname)}}>중복확인</Button>
                                    <Form.Control.Feedback type="invalid">
                                        닉네임이 중복되거나 올바르지 않습니다. 올바른 닉네임을 입력해주세요.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback>중복확인 되었습니다 :)</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>이메일주소</Form.Label>
                                <InputGroup>
                                    <Form.Control isValid={email_valid} isInvalid={!email_check} required type="email" name="email" value={values.email} onChange={handleEmailChange} placeholder="이메일주소를 입력해주세요."/>
                                    <Button variant="light" onClick={(e)=>{if(emailauth == false)sendEmail()}}>메일인증</Button>
                                    <Form.Control.Feedback type="invalid">
                                        이메일이 중복되거나 올바르지 않습니다.
                                    </Form.Control.Feedback>
                                </InputGroup>
                                    {emailauth==true ?
                                        <Form.Group  className="mt-3">
                                            <InputGroup className="d-inline-block">
                                                <Form.Label>인증번호입력</Form.Label>
                                                <span className="float-end">{parseInt(emailauth_cnt/60)}:{String(emailauth_cnt%60).padStart(2, '0')}</span>
                                            </InputGroup>
                                            <InputGroup>
                                                <Form.Control isValid={emailauth_valid} isInvalid={!emailauth_check} required type="text" name="emailauth" value={emailauthNumber} onChange={(e)=>{setEmailAuthNumber(e.target.value)}} placeholder="인증번호를 입력해주세요."/>
                                                <Button variant="light" onClick={(e)=>{sendEmail()}}>재발송</Button>
                                                <Button variant="light" onClick={(e)=>{checkAuthCode(emailauthNumber)}}>인증확인</Button>
                                            </InputGroup>
                                        </Form.Group>
                                        :
                                        ""
                                    }                            
                            </Form.Group>
                        </Row>
                    </>
                        :
                        <>
                            <Row className="text-center py-3 bg-light">
                                <strong>소셜 계정으로 회원가입 중입니다.</strong>
                            </Row>
                        </>
                    }
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>전화번호</Form.Label>
                                <Form.Control isValid={pn1_valid} isInvalid={!pn1_check} required type="text" name="phonenumber1" value={values.phonenumber1} onChange={handlePN1Change} placeholder="전화번호를 입력해주세요."/>
                                <Form.Control.Feedback type="invalid">
                                    전화번호를 올바르게 입력해주세요.
                                </Form.Control.Feedback>
                                {/*
                                <Button variant="light" onClick={(e)=>{checkDuplicaton("phonenumber1", values.phonenumber1)}}>중복확인</Button>
                                <Form.Control.Feedback type="invalid">
                                    전화번호가 중복되거나 올바르지 않습니다. 올바른 전화번호를 입력해주세요.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback>인증되었습니다 :)</Form.Control.Feedback>
                            */}
                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>추가 전화번호</Form.Label>
                            <Form.Control type="text" name="phonenumber2" value={values.phonenumber2} onChange={handleChange} placeholder="추가 전화번호를 입력해주세요."/>
                        </Form.Group>
                    </Row>
                    {isBusiness ? 
                        <>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>사업자등록번호</Form.Label>
                                <InputGroup>
                                    <Form.Control isValid={taxid_valid} isInvalid={!taxid_check} required type="text" name="id" value={values.taxid} onChange={handleTAXIDChange} placeholder="사업자등록번호를 입력해주세요."/>
                                    <Button variant="light" onClick={(e)=>{checkDuplicaton("taxid", values.taxid)}}>중복확인</Button>
                                    <Form.Control.Feedback type="invalid">
                                        사업자등록번호가 중복되거나 올바르지 않습니다. 숫자만 10자를 입력해주세요.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback>중복확인 되었습니다 :)</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>대표자성명</Form.Label>
                                <InputGroup>
                                    <Form.Control isValid={ceo_name_valid} isInvalid={!ceo_name_check} required type="text" name="id" value={values.ceo_name} onChange={handleCEONAMEChange} placeholder="대표자명을 입력해주세요."/>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>개업연월일</Form.Label>
                                <InputGroup>
                                    <Form.Control isValid={ceo_name_valid} isInvalid={!ceo_name_check} required type="text" name="id" value={values.ceo_name} onChange={handleCEONAMEChange} placeholder="대표자명을 입력해주세요."/>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>소재지</Form.Label>
                                <InputGroup>
                                    <Form.Control isValid={email_valid} isInvalid={!email_check} required type="email" name="email" value={values.email} onChange={handleEmailChange} placeholder="이메일주소를 입력해주세요."/>
                                    <Button variant="light" onClick={(e)=>{if(emailauth == false)sendEmail()}}>메일인증</Button>
                                    <Form.Control.Feedback type="invalid">
                                        소재지가 올바르지 않습니다.
                                    </Form.Control.Feedback>
                                </InputGroup>
                                    {emailauth==true ?
                                        <Form.Group  className="mt-3">
                                            <InputGroup className="d-inline-block">
                                                <Form.Label>인증번호입력</Form.Label>
                                                <span className="float-end">{parseInt(emailauth_cnt/60)}:{String(emailauth_cnt%60).padStart(2, '0')}</span>
                                            </InputGroup>
                                            <InputGroup>
                                                <Form.Control isValid={emailauth_valid} isInvalid={!emailauth_check} required type="text" name="emailauth" value={emailauthNumber} onChange={(e)=>{setEmailAuthNumber(e.target.value)}} placeholder="인증번호를 입력해주세요."/>
                                                <Button variant="light" onClick={(e)=>{sendEmail()}}>재발송</Button>
                                                <Button variant="light" onClick={(e)=>{checkAuthCode(emailauthNumber)}}>인증확인</Button>
                                            </InputGroup>
                                        </Form.Group>
                                        :
                                        ""
                                    }                            
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>업종</Form.Label>
                                <InputGroup>
                                    <Form.Control isValid={ceo_name_valid} isInvalid={!ceo_name_check} required type="text" name="id" value={values.ceo_name} onChange={handleCEONAMEChange} placeholder="대표자명을 입력해주세요."/>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>업태</Form.Label>
                                <InputGroup>
                                    <Form.Control isValid={ceo_name_valid} isInvalid={!ceo_name_check} required type="text" name="id" value={values.ceo_name} onChange={handleCEONAMEChange} placeholder="대표자명을 입력해주세요."/>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                <Form.Label>담당자성명</Form.Label>
                                <InputGroup>
                                    <Form.Control isValid={ceo_name_valid} isInvalid={!ceo_name_check} required type="text" name="id" value={values.ceo_name} onChange={handleCEONAMEChange} placeholder="대표자명을 입력해주세요."/>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        </>
                    :
                        ""
                    }
                    <Form.Group className="">
                    <Container className="mt-5 px-0">                        
                        <ListGroup>
                            <ListGroup.Item>
                                <Form.Check type="checkbox" className="d-inline-block" checked={values.agreement} onChange={handleAgree} label="이용약관에 동의합니다." id="agreement_agree"/>
                                <Button variant="link" className="m-0 p-0 float-end" onClick={()=>{setAgreementShow(true)}}>전문보기</Button>                           
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Form.Check type="checkbox" className="d-inline-block" checked={values.privacy} onChange={handlePrivacy} label="개인정보처리방침에 동의합니다." id="privacy_agree"/>
                                <Button variant="link" onClick={()=>{setPrivacyShow(true)}} className="m-0 p-0 float-end">전문보기</Button>                           
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Form.Check type="checkbox" className="d-inline-block" checked={values.age} onChange={handleAge} label="만 14세 이상입니다." id="over14old_agree"/>                       
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Form.Check type="checkbox" className="d-inline-block" checked={values.marketing} onChange={handleMarketing} label="마케팅 메일 수신에 동의합니다.(선택사항)" id="marketing_agree"/>
                            </ListGroup.Item>
                        </ListGroup>
                    </Container>
                    <Container className="mt-5 px-0">
                        <Button disabled={!validated} onClick={()=>{requestRegist()}} className="w-100">회원가입</Button>
                    </Container>
                    </Form.Group>
                </Form>
                
            </section>
            <Modal_alert show={register_complete_show} handleClose={()=>{navigate("/")}} modal_title="회원가입 완료" content="회원가입이 완료되었습니다 :) 로그인해주세요 :D" primary_btn="확인"/>
            <Modal_alert show={register_failed_show} handleClose={()=>{setRegisterFailedShow(false)}} modal_title="회원가입 실패" content="회원가입에 실패하였습니다. 잠시 후 다시시도해주시거나 고객센터로 연락해주세요." primary_btn="확인"/>

            <Modal
              show={agreement_show}
              onHide={()=>{setAgreementShow(false)}}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>이용약관</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                 <Container>
                    <Agreement_body/>
                 </Container>
              </Modal.Body>
            </Modal>
            <Modal
              show={privacy_show}
              onHide={()=>{setPrivacyShow(false)}}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>개인정보처리방침</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                 <Container>
                    <Privacy_body/>
                 </Container>
              </Modal.Body>
            </Modal>
        </>
    );

}

export default Register_form;