import Button from 'react-bootstrap/Button';
import React from "react";
import { Breadcrumb, BreadcrumbItem, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Card from 'react-bootstrap/Card'
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import Loading from '../../../common/Loading';
import { useNavigate, Link } from 'react-router-dom';
import Status_view from './modules/status_view';
import Modal_Password_alert from '../../../common/modal_password';
import Modal_alert from '../../../common/modal';

function Mypage_Dashboard(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [serviceType, setServiceType]= useState([]);
    const [serviceInfo, setServiceInfo] = useState([]);
    const [orderInfo, setOrderInfo] = useState([]);
    const [noticeInfo, setNoticeInfo] = useState([]);
    
    const [myinfo_pw_show, setMyinfoPWShow] = useState(false);
    const [myinfo_fail_show, setMyinfoFailShow] = useState(false);
    const [myinfo_pw, setMyinfoPW] = useState("");
    
    const [qnaInfo, setQnaInfo] = useState([]);
    const [step] = useState({10: "주문완료", 15:"결제대기",20:"결제완료", 25:"크레딧지급대기", 30:"크레딧지급완료", 80:"결제취소", 90:"주문취소"});


    const scrollToTop = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const getServiceType = () =>{
        axios.get('/rest/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setServiceType(()=>{return response.data});
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );

    }

    const getServiceIndex = (uid)=>{
        if(serviceType.length == 0)
            getServiceType();
        let res_idx = -1;
        serviceType.map(function(item, idx){
            if(item.uid == uid){
                res_idx  = idx;
                return;
            }
        });
        console.log("idx : "+res_idx);
        return res_idx;
    }
    
    const pw_handleSubmit = ()=>{
        setLoading(true);
        axios.post('/sso/rest/myinfo_pw_check',{
                pw : myinfo_pw
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setMyinfoPWShow(false);
                if(response.data.result == 'success'){ 
                    navigate("/mypage/modifyInfo", {state:{key:response.data.key}});
                }else{
                    setMyinfoFailShow(true);
                }
            }
            setLoading(false);
            setMyinfoPW("");
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const requestList = () =>{
        setLoading(true);
        getServiceType();
        axios.get('/rest/getServiceInfo').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setServiceInfo(response.data.data);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    const requestOrderInfo = () =>{
        setLoading(true);
        axios.get('/rest/getLastestOrder').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setOrderInfo(response.data.data);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    const requestNoticeInfo = () =>{
        setLoading(true);
        axios.get('/rest/getNoticeSummary').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setNoticeInfo(response.data.data);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    useEffect(()=>{
        requestList();
        requestOrderInfo();
        requestNoticeInfo();
    }, []);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          준비중입니다.. 조금만 기다려 주세요!
        </Tooltip>
    );

    return(
        <>
            {loading == true ? <Loading/> : ""}
            <section className="section-content" style={{paddingBottom:"0"}}>
            <Status_view setLoading={setLoading} setMyinfoShow={setMyinfoPWShow} modifyInfo={pw_handleSubmit}/>
                <Breadcrumb className='mt-3'>
                    <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        마이페이지
                    </Breadcrumb.Item>
                </Breadcrumb>
            </section>
            <section className="sections-white" style={{marginTop:"1rem", paddingBottom:"0", marginBottom:"0"}}>
                <div className='section-content'>
                    <h5><strong>공지사항</strong></h5>
                    { noticeInfo.length > 0 ?
                        noticeInfo.map(function(a, idx){
                            return(
                                <Card key={idx} className='mx-4'>
                                    <Card.Body>
                                        <Row className='border-bottom'>
                                            <Col lg={8}>
                                                <h6>{a.title}</h6>
                                            </Col>
                                            <Col className='text-end'>
                                                <h6>{a.created_date}</h6>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            );
                        })
                        :
                        <>
                            <h6 className='text-center pb-4 m-0'>공지사항이 없습니다.</h6>
                        </>
                    }
                </div>
            </section>
            <section className="sections-white" style={{ paddingBottom:"0", marginBottom:"0"}}>
                <div className='section-content'>
                    <h5><strong>서비스현황</strong></h5>
                    {
                        serviceInfo.length > 0 ?
                            serviceInfo.map(function(a, idx){
                                return(
                                    <Card key={idx} className='border border-white'>
                                        <Card.Body>
                                            <Row className='px-3'>
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col lg={9}>
                                                                <Row>
                                                                    <h6><strong>{serviceType.length > 0 && a.service_uid != null ? serviceType[getServiceIndex(a.service_uid)].service_name : ""}</strong></h6>
                                                                    <span className='ms-2'>잔여크레딧 : <strong>{a.credit}</strong></span>
                                                                    <span className='ms-2'>만료임박 크레딧 유효기간 : <strong>{a.invalid_date}</strong></span>
                                                                </Row>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <Row className='text-end'>
                                                                    <Link to={{pathname: "/mypage/webapi"}} state={{service_uid: a.service_uid}}>
                                                                        <span>웹에서 이용하기</span>
                                                                    </Link>
                                                                    <Link to={{pathname: "/mypage/viewAPILog"}} state={{service_uid: a.service_uid}}>
                                                                        <span>이용로그 확인</span>
                                                                    </Link>
                                                                    <Link to={{pathname: "/mypage/apiDashboard"}} state={{service_uid: a.service_uid}}>
                                                                        <span>대시보드 이동</span>
                                                                    </Link>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                );
                            })
                        :
                        <>
                            <h6 className='text-center pb-4 m-0'>이용중이신 서비스가 없습니다.</h6>
                        </>
                    }
                </div>
            </section>
            <section className="sections-white" style={{ paddingBottom:"0"}}>
                <div className='section-content'>
                    <div className='justify-content-between d-flex'>
                        <strong className='h5 fw-bold'>최근주문현황</strong>
                        <Button variant='link' className='pe-4 text-secondary' onClick={()=>{navigate('/mypage/orderlist')}}>더 보기</Button>
                    </div>
                    {
                        orderInfo.length > 0 ?
                            orderInfo.map(function(a, idx){
                                return(
                                    <Card key={idx} className='border border-white'>
                                        <Card.Body>
                                            <Row className='px-3'>
                                                <Card>
                                                    <Card.Body>
                                                        <Link to={{pathname: "/mypage/order"}} onClick={()=>{scrollToTop();}} state={{order_id : a.order_id}}>
                                                            <Row>
                                                                <Col lg={10}>
                                                                    <Row>
                                                                        <h6><strong>{a.order_id}</strong></h6>
                                                                        <span className='ms-2'>{a.plan_name}</span>
                                                                        <span className='ms-2'>{a.order_date}</span>
                                                                    </Row>
                                                                </Col>
                                                                <Col className='text-end'>
                                                                        <span className='rounded bg-primary text-light p-2' style={{lineHeight:"5rem"}}>{step[a.step]}</span>
                                                                </Col>
                                                            </Row>
                                                        </Link>
                                                    </Card.Body>
                                                </Card>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                );
                            })
                        :
                        <>
                            <h6 className='text-center pb-4 m-0'>주문이 없습니다.</h6>
                        </>
                    }
                </div>
            </section>
            <section className="sections-white pb-4" style={{ paddingBottom:"0"}}>
                <div className='section-content'>
                    <h5><strong>1:1문의 현황</strong></h5>
                    {
                        qnaInfo.length > 0 ?
                            qnaInfo.map(function(a, idx){
                                return(
                                    <Card className='border border-white'>
                                        <Card.Body>
                                            <Row className='px-3'>
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col lg={10}>
                                                                <Row>
                                                                    <h6><strong>{a.title}</strong></h6>
                                                                    <span className='ms-2'>{a.created_date}</span>
                                                                </Row>
                                                            </Col>
                                                            <Col className='text-end'>
                                                                    <span className='rounded bg-primary text-light p-2' style={{lineHeight:"5rem"}}>{a.step}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                );
                            })
                        :
                        <>
                            <h6 className='text-center pb-4 m-0'>문의가 없습니다.</h6>
                        </>
                    }
                    
                </div>
            </section>
            <Modal_Password_alert show={myinfo_pw_show} pw={myinfo_pw} setSecretPw={setMyinfoPW} handleClose={()=>{setMyinfoPWShow()}} handleSubmit={()=>{pw_handleSubmit()}} modal_title="비밀번호 입력" content="회원정보 수정을 위해 비밀번호를 입력해주세요." secondary="닫기" primary_btn="확인" />
            <Modal_alert show={myinfo_fail_show} handleClose={()=>{setMyinfoFailShow(false); setMyinfoPW("");}} modal_title="비밀번호 불일치" content="비밀번호가 일치하지 않습니다." primary_btn="확인"/>

        </>
    );

}

export default Mypage_Dashboard;