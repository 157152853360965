import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, InputGroup, ListGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal_alert from "../../../common/modal";


function RegisterCoupon(){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [serviceType, setServiceType]= useState([]);

    const [btnText, setBtntext] = useState("");
    
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [delete_show, setDeleteShow] = useState(false);
    const [delete_complete_show, setDeleteCompleteShow] = useState(false);
    const [error_show, setErrShow] = useState(false);
    const [validated, setValidated] = useState(false);

    const [memberSearch, setMembersearch] = useState("");
    const [memberList, setMemberList] = useState([]);
    const [selectedMember, setSelectedMember] = useState([]);
    const [memberListShow, setMemberListShow] = useState(false);

    const [serviceSearch, setServicesearch] = useState("");
    const [serviceList, setServiceList] = useState([]);
    const [selectedService, setSelectedService] = useState([]);
    const [serviceListShow, setServiceListShow] = useState(false);

    const [personFee, setPersonFee] = useState(0);
    const [totalFee, setTotalFee] = useState(0);

    const[values, setValues] = useState({});

    const checkDuplicaton = (arr, target)=> {
        for(let i=0; i<arr.length; i++){
            if(arr[i].uid == target)
                return true;
        }
        return false;
    }
    const deleteMember = (uid, e) =>{
        console.log("deletemember "+uid);
        setSelectedMember(selectedMember.filter(item => item.uid != uid));
    }
    const deleteService = (uid, e) =>{
        console.log("deleteservice "+uid);
        setSelectedService(selectedService.filter(item => item.uid != uid));
   }
    const setEa = (uid, e) =>{
        setSelectedService(
        selectedService.map(item => 
            item.uid == uid ? {...item, ea : e.target.value} : item
        ));
    }
    const setPayAmount = (uid, e) =>{
        setSelectedService(
        selectedService.map(item => 
            item.uid == uid ? {...item, pay_amount : Number(e.target.value)} : item
        ));
    }
    const setValidStart = (uid, e) =>{
        console.log(e);
        setSelectedService(
        selectedService.map(item => 
            item.uid == uid ? {...item, valid_start : e} : item
        ));
    }

    const setValidEnd = (uid, e) =>{
        setSelectedService(
        selectedService.map(item => 
            item.uid == uid ? {...item, valid_end : e} : item
        ));
    }


    const setEaBtn = (uid, direction,e) =>{
        if(direction == "+"){
            setSelectedService(
            selectedService.map(item => 
                item.uid == uid ? {...item, ea : item.ea+1} : item
            ));
        }else if(direction == "-"){
            setSelectedService(
            selectedService.map(item => 
                item.uid == uid ? {...item, ea : item.ea-1} : item
            ));
        }
    }

    const handleMemberSearch = e => {
        setMembersearch(e.target.value);
    }
    const handleServiceSearch = e => {
        setServicesearch(e.target.value);
    }

    const memberSelectHandler = (e) => {
        if(!checkDuplicaton(selectedMember, e.target.getAttribute("uid"))){
            setSelectedMember([
                ...selectedMember,
                {uid : e.target.getAttribute("uid"), id_ : e.target.getAttribute("id_"), name_: e.target.getAttribute("name_")},
            ])
        }
        setMembersearch("");
        setMemberListShow(false);
    }
    const serviceSelectHandler = (e) => {
        if(!checkDuplicaton(selectedService, e.target.getAttribute("uid"))){
            setSelectedService([
                ...selectedService,
                {service_uid : Number(e.target.getAttribute("uid")), name_: e.target.getAttribute("name_"), ea:1, pay_amount:e.target.getAttribute("pay_amount"), valid_start:new Date(), valid_end:new Date()},
            ])
        }
        setServicesearch("");
        setServiceListShow(false);
    }

    const handleChange = e => {
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
      }

    const handleSubmit_delete = (event) => {
        event.preventDefault();
        //setDeleteShow(false);
        //deletePayplan();
      };

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false ||(selectedMember.length ==0 || selectedService.length ==0)) {
        event.stopPropagation();
        setErrShow(true);
      }else{
        regist();
      }

      setValidated(true);
      
    };
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/CouponList");
    };
    const handleShow = () => setShow(true);

    
    const getServiceType = () =>{
        axios.get('/rest/admin/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setServiceType(()=>{return response.data});
                if(location.state != null && location.state.mode != 'mod'){
                    setValues({
                        ...values,
                        servicetype: response.data[0].uid,
                    })
                }
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const searchMember = (q) =>{
        axios.get('/sso/rest/admin/getUserList', 
            {params:
            {
                list_type:"detail",
                page : 0,
                page_limit : 10,
                q_type : 'email',
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setMemberList(()=>{return response.data.list});           
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const searchService = (q) =>{
        getServiceType();
        let list_ = [];
        serviceType.map(function(item, idx){
            if(item.service_name.indexOf(q) >= 0)
                list_.push(item);
        });
        setServiceList(()=>{return list_});  
    }
    const regist = () =>{
        setLoading(true);
        getServiceType();
        axios.post('/rest/admin/registerCouponBatch',
        { 
            members:selectedMember,
            coupons : selectedService
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    setModalTitle("쿠폰 발급 성공");
                    setModalContent("쿠폰발급에 성공하였습니다.");
                    setRegisterResult(true);
                }else{
                    setModalTitle("쿠폰 발급 실패");
                    setModalContent("쿠폰발급에 실패하였습니다.");
                    setRegisterResult(false);
                }
                handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    useEffect(()=>{

    }, [personFee, totalFee]);
    useEffect(() => {
        if(memberList.length > 0){
            setMemberListShow(true);
        }else{
            setMemberListShow(false);
        }

    }, [memberList]);
    useEffect(() => {
        if(serviceList.length > 0){
            setServiceListShow(true);
        }else{
            setServiceListShow(false);
        }

    }, [serviceList]);
    useEffect(()=>{
        let membercnt = selectedMember.length;
        let totalfee = 0;
        selectedService.map(function(item, idx){
            totalfee += Number(item.pay_amount)*item.ea;
        });
        console.log(membercnt+" "+totalfee);
        setTotalFee(membercnt*totalfee);
        setPersonFee(totalfee);
    },[selectedMember, selectedService])
    useEffect(() => {
        if(memberSearch.length > 0)
            searchMember(memberSearch);
        else
            setMemberListShow(false);
    }, [memberSearch]);
    useEffect(() => {
        if(serviceSearch.length > 0)
            searchService(serviceSearch);
        else
            setServiceListShow(false);
    }, [serviceSearch]);
    useEffect(() => {
        getServiceType();
        if(location.state != null && location.state.mode == 'mod'){
            setTitle(()=>{
                return "쿠폰 수정";
            })
            setBtntext(()=>{
                return "수정";
            })
        }else{
            setTitle(()=>{
                return "쿠폰 발급";
            })
            setBtntext(()=>{
                return "발급";
            })
            setValues({});
        }
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form noValidate validated={validated} onSubmit={(e)=>{e.preventDefault()}}>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>쿠폰번호</Form.Label>
                                    <Form.Control required type="text" name="coupon_id" value={values.coupon_id} onChange={handleChange} placeholder="쿠폰 발급 후 표시됩니다." readOnly/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row  className="mb-3">
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>회원검색</Form.Label>
                                    <Form.Control type="text" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none"}} value={memberSearch} onChange={handleMemberSearch} placeholder="주문자 검색" />
                                    <ListGroup className={`${memberListShow ? "" : "d-none"} position-absolute`}>
                                        {memberList.map(function(item, idx){
                                            return(<ListGroup.Item action key={idx} onClick={memberSelectHandler} uid={item.uid} id_={item.id} name_={item.name}>{item.name}({item.id})</ListGroup.Item>)
                                        })}
                                    </ListGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={9}>
                                <Form.Group>
                                        <Form.Label>회원목록</Form.Label>
                                    <Container className="border p-3">
                                        {selectedMember.map(function(item, idx){
                                            return(<Row key={idx} className="border-bottom lh-lg">
                                                <Col lg={5}>
                                                    {item.id_}
                                                </Col>
                                                <Col lg={6}>
                                                    {item.name_}
                                                </Col>
                                                <Col lg={1}>
                                                    <Button variant="outline-danger" className="btn btn-sm py-0" onClick={(e)=>{deleteMember(item.uid, e)}}>X</Button>
                                                </Col>
                                            </Row>)
                                        })}
                                    </Container>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row  className="mb-3">
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>서비스검색</Form.Label>
                                    <Form.Control type="text" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none", paddingRight:0}} value={serviceSearch} onChange={handleServiceSearch} placeholder="서비스 검색" />
                                    <ListGroup className={`${serviceListShow ? "" : "d-none"} position-absolute`}>
                                        {serviceList.map(function(item, idx){
                                            return(<ListGroup.Item action key={idx} onClick={serviceSelectHandler} uid={item.uid} name_={item.service_name}>{item.service_name}</ListGroup.Item>)
                                        })}
                                    </ListGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={9}>
                                <Form.Group>
                                        <Form.Label>서비스목록</Form.Label>
                                    <Container className="border p-3">
                                        {selectedService.map(function(item, idx){
                                            return(<Row key={idx} className="border-bottom lh-lg">
                                                <Col lg={2}  className="h-auto m-auto">
                                                    <span>{item.name_}</span>
                                                </Col>
                                                <Col lg={2}  className="h-auto m-auto">
                                                    <InputGroup className="pt-1">
                                                        <Form.Control type="number" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none", textAlign:"center"}} value={item.pay_amount ? item.pay_amount : 0} onChange={(e)=>{setPayAmount(item.uid,e)}} className=" px-0"/>
                                                    </InputGroup>
                                                </Col>
                                                <Col lg={2}  className="h-auto m-auto">
                                                    <InputGroup className="pt-1">
                                                        <Button variant="outline-secondary" onClick={(e)=>{setEaBtn(item.uid, "-", e)}} className="py-0">
                                                            -
                                                        </Button>
                                                        <Form.Control type="number" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none", textAlign:"center"}} value={item.ea} onChange={(e)=>{setEa(item.uid,e)}} className="px-0"/>
                                                        <Button variant="outline-secondary"  onClick={(e)=>{setEaBtn(item.uid, "+", e)}} className="py-0">
                                                            +
                                                        </Button>
                                                    </InputGroup>
                                                </Col>
                                                <Col lg={4}  className="h-auto m-auto">
                                                    <Form.Group className="pe-5">
                                                        <Form.Label className="m-0">유효시작일자</Form.Label><br/>
                                                        <ReactDatePicker className="form-control" showTimeSelect dateFormat="yyyy-MM-dd hh:mm" dateFormatCalendar="yyyy년 MM월" selected={item.valid_start} onChange={(date) => setValidStart(item.uid, date)} selectsStart startDate={item.valid_start} endDate={item.valid_end}/>
                                                    </Form.Group>
                                                    <Form.Group className="pe-5 mb-3">
                                                        <Form.Label className="m-0">만료일자</Form.Label><br/>
                                                        <ReactDatePicker className="form-control" showTimeSelect dateFormat="yyyy-MM-dd hh:mm" dateFormatCalendar="yyyy년 MM월"  selected={item.valid_end} onChange={(date) => setValidEnd(item.uid, date)} selectsStart startDate={item.valid_start} endDate={item.valid_end}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={1}  className="h-auto m-auto">
                                                    <strong>{(item.pay_amount ? item.pay_amount : 0) * item.ea}원</strong>
                                                </Col>
                                                <Col lg={1}  className="h-auto m-auto">
                                                    <Button variant="outline-danger" className="btn btn-sm py-0" onClick={(e)=>{deleteService(item.uid, e)}}>X</Button>
                                                </Col>
                                            </Row>)
                                        })}
                                    </Container>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="text-end">
                            <h6><strong>주문자별 발급금액(VAT포함) : {personFee}원</strong></h6>
                        </Row>
                        <Row  className="text-end">
                            <h6><strong>총 발급금액(VAT포함) : {totalFee}원</strong></h6>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            
                            <Col sm={1}>
                                <Button type="button" className={`w-100 btn btn-secondary ${location.state != null && location.state.mode == 'mod' ? "" : "d-none"}`} onClick={()=>{setDeleteShow(true)}}>삭제</Button>
                            </Col>
                            <Col sm={1}>
                                <Button type="button" onClick={handleSubmit} className="w-100">{btnText}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
                {location.state != null && location.state.mode == 'mod' ? 
                <><Modal_alert show={delete_show} handleClose={()=>{setDeleteShow(false)}} handleSubmit={handleSubmit_delete} modal_title="" content="정말 플랜을 삭제하시겠습니까?" secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={delete_complete_show} handleClose={()=>{setDeleteCompleteShow(false); navigate("/admin/Payplan"); }} modal_title="삭제완료" content="플랜이 삭제되었습니다." primary_btn={primary_btn}/></>
                :
                ""
                }
                <Modal_alert show={error_show} handleClose={()=>{setErrShow(false)}} modal_title="입력값 오류!" content="이용자정보와 플랜정보를 다시한번 확인해주세요." primary_btn={primary_btn}/>
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
            </div>
        </>
        );

}

export default RegisterCoupon;