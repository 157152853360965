import axios from "axios";
import React from "react";
import { useState } from "react";
import { Breadcrumb, Container, Form, Row, Table, Col, Card, Button, ToggleButton, InputGroup, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../../../common/banner";
import Loading from "../../../common/Loading";
import { useEffect } from "react";

function Order(){
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState([]);
    const [orderer_info, setOrdererInfo] = useState({orderer_name:"", phonenumber:"", email:""});
    const [serviceType, setServiceType]= useState([]);
    const [pay_available, setPayAvailable] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [total_ea, setTotalEa] = useState(0);

    const [coupon_id, setCouponID] = useState("");

    const [depositor, setDepositor] = useState("");
    const [pay_amount, setPayAmount] = useState(0);
    const [tax, setTax] = useState(0);
    const [coupon_discount, setCouponDiscount] = useState(0);

    const [methodValue, setMethodValue] = useState("");

    const handleChange = e => {
        setOrdererInfo({
          ...orderer_info,
          [e.target.name]: e.target.value,
        })
    }


    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          준비중입니다.. 조금만 기다려 주세요!
        </Tooltip>
      );

    const methods = [
      { name: '무통장입금', value: 'cash' },
      { name: '신용카드', value: 'creditcard' },
      { name: '가상계좌', value: 'virtual_account' },
    ];

    const getServiceType = () =>{
        axios.get('/rest/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setServiceType(()=>{return response.data});
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );

    }
    const setTotalEaFromCart= ()=>{
        let ea = 0;
        values.map(function(a, idx){
            ea += a.ea;
        });
        setTotalEa(ea);
    }

    const updateCart = (cart_uid, ea)=>{
        setLoading(true);
        axios.post('/rest/updateCart',{
                cart_uid : cart_uid,
                ea : ea,
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                if(response.data.result == 'success'){ 
                    getCurrentOrder();
                }else{
                    alert("수량변경에 실패하였습니다. 고객센터에 문의해주세요.");
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const checkValidCoupon = ()=>{
        setLoading(true);
        axios.post('/rest/order/checkValidCoupon',{
                coupon_id : coupon_id,
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                if(response.data.result == 'success'){ 
                    let coupon_list = [];
                    let coupon_amount = 0;
                    for(var i=0; i<response.data.coupons.length; i++){
                        coupon_list.push(response.data.coupons[i]);
                        coupon_amount+= response.data.coupons[i].pay_amount;
                    }
                    setCouponDiscount(coupon_amount);
                    setCoupons(coupon_list);
                }else{
                    if(response.data.msg == 'no_session'){
                        alert("세션정보가 존재하지 않습니다.");
                        navigate("/");
                    }else if(response.data.msg == 'invalid_order'){
                        alert("유효하지 않은 주문입니다.");
                        navigate("/");
                    }else if (response.data.msg == 'invalid_coupon'){
                        alert("유효하지 않은 쿠폰입니다.");
                    }else if(response.data.msg == 'duplicated_coupon'){
                        alert("이미 등록된 쿠폰입니다.");
                    }else if(response.data.msg == 'no_match_goods'){
                        alert("쿠폰에 해당하는 주문정보가 아닙니다.");
                    }else if(response.data.msg == 'over_fee'){
                        alert("주문금액보다 쿠폰사용액이 더 큽니다.");
                    }
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const payOrder = ()=>{
        const regex = /^[0-9]+$/;
        if(!regex.test(orderer_info.phonenumber)){
            alert("주문자 연락처에는 숫자만 입력해주세요!")
            return;
        }
        if(methodValue == "" || orderer_info.orderer_name == ""|| orderer_info.phonenumber == "" || orderer_info.email=="" || values.ea <= 0 || (methodValue=="cash" && depositor=="")){
            alert("주문정보를 다시한번 확인해주세요!");
            return;
        }
        setLoading(true);
        axios.post('/rest/processOrder',{
                payment_method : methodValue,
                orderer_name : orderer_info.orderer_name,
                phonenumber : orderer_info.phonenumber,
                email : orderer_info.email,
                coupons : coupons,
                depositor : depositor,
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                if(response.data.result == 'success'){ 
                    alert("주문이 완료되었습니다 !");
                    navigate("/mypage/dashboard");
                }else{
                    alert("주문처리에 실패하였습니다. 고객센터에 문의해주세요.");
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                alert("주문처리에 실패하였습니다. 고객센터에 문의해주세요.");
                navigate("/");
                console.log(error);
            }
        );
    }


    const getServiceIndex = (uid)=>{
        let res_idx = -1;
        serviceType.map(function(item, idx){
            if(item.uid == uid){
                res_idx  = idx;
                return;
            }
        });
        return res_idx;
    }

    const getCurrentOrder = () =>{
        setLoading(true);
        getServiceType();
        axios.get('/rest/getCurrentOrder').then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("주문서를 불러올 수 없습니다.");
                navigate(-1);
            }else{
                if(response.data.result != 'success'){
                    alert("주문서를 불러올 수 없습니다.");
                    navigate(-1);
                }else{
                    setValues(response.data.order_data);
                    setOrdererInfo(response.data.orderer);
                    setCoupons(response.data.coupons);
                    let total = 0;
                    for(var i=0; i<response.data.order_data.length; i++){
                        total += response.data.order_data[i].fee * response.data.order_data[i].ea;
                    }
                    let coupon_total = 0;
                    for(var i=0; i<response.data.coupons.length; i++){
                        coupon_total += response.data.coupons[i].pay_amount;
                    }
                    setPayAmount(total);
                    setCouponDiscount(coupon_total);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    };
    useEffect(()=>{getCurrentOrder();}, []);
    useEffect(()=>{
        //console.log(methodValue);
        //console.log(orderer_info.orderer_name);
        //console.log(orderer_info.phonenumber);
        //console.log(orderer_info.email);
        //console.log(total_ea);

        if(methodValue != "" && orderer_info.orderer_name != "" && orderer_info.phonenumber != "" && orderer_info.email!="" && total_ea > 0 &&(methodValue=="cash" && depositor != ""))
            setPayAvailable(true);
    }, [orderer_info, values, total_ea, methodValue, depositor]);
    useEffect(()=>{setTotalEaFromCart();}, [values]);

    useEffect(()=>{setTax(pay_amount-(pay_amount/1.1));}, [pay_amount]);
    useEffect(()=>{}, [coupons]);
    useEffect(()=>{/*setPayAmount(pay_amount-coupon_discount);*/}, [coupon_discount])


    return(
        <>
            {loading == true ? <Loading/> : ""}
            <div className="banner-slider">
                <Banner />
            </div>
            <section className="section-content" style={{paddingBottom:"0"}}>
                <Breadcrumb className='mt-3'>
                    <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        주문/결제
                    </Breadcrumb.Item>
                </Breadcrumb>
            </section>
            <div className="sections-white pb-5">
                <div className="section-content pt-3">
                    <Row>
                        <Col lg={7}>
                            <Row>
                                <Form.Group className="p-0">
                                    <Container className="px-0" style={{overflowX:'Auto'}}>
                                        <Card className="pb-4 pt-3 px-1 px-lg-3 m-0">
                                            <Form.Label><strong>주문내역</strong></Form.Label>
                                            <Table responsive hover className="m-0" style={{minWidth:'600px'}} >
                                                <thead>
                                                    <tr>
                                                        <th>플랜이름</th>
                                                        <th>서비스유형</th>
                                                        <th>지급 크레딧</th>
                                                        <th>수량</th>
                                                        <th>주문금액</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {values.map(function(item, idx){
                                                    return(<tr key={idx} className="border-bottom lh-lg align-middle">
                                                        <td>
                                                            {item.plan_name}
                                                        </td>
                                                        <td>
                                                            {serviceType[getServiceIndex(item.service_uid)] != undefined ?  serviceType[getServiceIndex(item.service_uid)].service_name : ""}
                                                        </td>
                                                        <td>
                                                            {item.credit_size}개
                                                        </td>
                                                        <td className="col-lg-1 px-0">
                                                            <Form.Control required type="number" name="ea" value={item.ea} onChange={(e)=>{if(e.target.value <= 0){alert("주문수량은 1개이상으로 해주세요!"); return;} updateCart(item.cart_uid, Number(e.target.value)); setTotalEaFromCart();}}/>
                                                        </td>
                                                        <td>
                                                            <strong>{item.fee*item.ea}원</strong>
                                                        </td>
                                                    </tr>)
                                                })}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Container>
                                </Form.Group>
                            </Row>
                            <Row className="mt-3">
                                <Card className="bg-light p-3 pb-5 border-0">
                                    <Row>
                                        <Form.Group>
                                            <Form.Label><strong>주문자 정보</strong></Form.Label>
                                            <Row className="mx-lg-1">
                                                <Form.Group>
                                                    <Form.Label>주문자명</Form.Label>
                                                    <Form.Control className="ms-lg-1" type="text" placeholder="주문자명" name="orderer_name" defaultValue={orderer_info.orderer_name} onChange={handleChange}></Form.Control>
                                                </Form.Group>
                                                <Form.Group className="mt-3">
                                                    <Form.Label>주문자 연락처</Form.Label>
                                                    <Form.Control className="ms-lg-1" type="text" placeholder="주문자 연락처" name="phonenumber"  defaultValue={orderer_info.phonenumber}onChange={handleChange}></Form.Control>
                                                </Form.Group>
                                                <Form.Group className="mt-3">
                                                    <Form.Label>주문자 이메일</Form.Label>
                                                    <Form.Control className="ms-lg-1" type="email" placeholder="주문자 이메일" name="email"  defaultValue={orderer_info.email} onChange={handleChange}></Form.Control>
                                                </Form.Group>
                                            </Row>
                                        </Form.Group>
                                    </Row>
                                </Card>
                            </Row>
                        </Col>
                        <Col lg={5} className="ps-5">
                            <Row>
                                <Form.Group className="h-100">
                                    <Container className="px-0" style={{overflowX:'Auto'}}>
                                        <Form.Label><strong>결제방법</strong></Form.Label>
                                        <Card className="p-lg-2 border-0  h-100">
                                            
                                            {methods.map((radio, idx) => {
                                               if(radio.value != "cash"){
                                                return(
                                                    <OverlayTrigger
                                                        key={idx}
                                                      placement="right"
                                                      delay={{ show: 250, hide: 400 }}
                                                      overlay={renderTooltip}
                                                    >
                                                        <ToggleButton
                                                          className="mt-2 py-3"
                                                          key={idx}
                                                          id={`radio-${idx}`}
                                                          type="radio"
                                                          variant="outline-secondary"
                                                          name="radio"
                                                          value={radio.value}
                                                          checked={methodValue === radio.value}
                                                          /*onChange={(e) => setMethodValue(e.currentTarget.value)}*/
                                                        >
                                                          {radio.name}
                                                        </ToggleButton>
                                                    </OverlayTrigger>
                                                );
                                               }else{ 
                                                return(<><ToggleButton
                                                      className="mt-2 py-3"
                                                      key={idx}
                                                      id={`radio-${idx}`}
                                                      type="radio"
                                                      variant="outline-secondary"
                                                      name="radio"
                                                      value={radio.value}
                                                      checked={methodValue === radio.value}
                                                      onChange={(e) => setMethodValue(e.currentTarget.value)}
                                                    >
                                                      {radio.name}
                                                    </ToggleButton>
                                                    { methodValue == "cash" ?
                                                        <Container className="py-3">
                                                            <span>입금계좌 : 국민은행 042601-04-227563 김노산(나미에스엔티)</span>
                                                            <Form.Group>
                                                                <Form.Label>입금자명</Form.Label>
                                                                <Form.Control required type="text" name="depositor" value={depositor} onChange={(e)=>{if(e.target.value.length > 10){alert("입금자명은 10자 이내로 입력해주세요"); return;} setDepositor(e.target.value);}}/>
                                                            </Form.Group>
                                                        </Container>
                                                        :
                                                        ""
                                                    }
                                                    </>
                                                )
                                               }
                                            })}
                                        </Card>
                                    </Container>
                                </Form.Group>
                            </Row>
                            <Row className="mt-3">
                                <Form.Group className="h-100">
                                    <Container className="px-0" style={{overflowX:'Auto'}}>
                                        <Form.Label><strong>쿠폰할인</strong></Form.Label>
                                        <InputGroup>
                                            <Form.Control type="text" placeholder="쿠폰번호를 입력하세요" value={coupon_id} onChange={(e)=>{setCouponID(e.target.value)}}></Form.Control>
                                            <Button variant="secondary" onClick={()=>{checkValidCoupon()}}>적용</Button>
                                        </InputGroup>
                                    </Container>
                                </Form.Group>
                            </Row>
                            <Row className="mt-3">
                                <Card className="bg-light p-3 pb-5 border-0">
                                    <Row>
                                        <Form.Group>
                                            <Form.Label><strong>결제 정보</strong></Form.Label>
                                            <Row className="mx-lg-1">
                                                <InputGroup className="justify-content-between">
                                                    <span>결제금액</span>
                                                    <span>{Math.round(pay_amount-tax)}원</span>
                                                </InputGroup>
                                            </Row>
                                            <Row className="mx-lg-1">
                                                <InputGroup className="justify-content-between">
                                                    <span>부가가치세</span>
                                                    <span>{Math.round(tax)}원</span>
                                                </InputGroup>
                                            </Row>
                                            <Row className="mx-lg-1">
                                                <InputGroup className="justify-content-between">
                                                    <span>쿠폰할인</span>
                                                    <span>{coupon_discount}원</span>
                                                </InputGroup>
                                                {coupons.map(function(a, idx){
                                                    return(
                                                        <Container key={idx} className="justify-content-between d-flex">
                                                            <span className="ms-3">{a.coupon_id}</span>
                                                            <span className="fs-6">{a.pay_amount}원</span>
                                                        </Container>
                                                    );
                                                })}
                                            </Row>
                                            <Row className="mx-lg-1 mt-4">
                                                <strong>
                                                    <InputGroup className="justify-content-between">
                                                            <span>총 결제금액</span>
                                                            <span>{pay_amount-coupon_discount}원</span>
                                                    </InputGroup>
                                                </strong>
                                            </Row>
                                        </Form.Group>
                                    </Row>
                                </Card>
                            </Row>
                            <Row className="mt-3">
                                <Button disabled={!pay_available} onClick={()=>{payOrder()}}>결제하기</Button>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );

}

export default Order;