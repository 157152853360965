import React from "react";
import { Container } from "react-bootstrap";

function Intro_TTS(){

    return(
        <>
            <Container className="mt-3">
                <h4 className="fw-bold">- 음성합성 AI는 무엇인가요?</h4>
                <div className="ps-lg-4 lh-lg text-secondary">
                    음성합성 AI는 컴퓨터로 작성한 문자를 사람의 음성으로 합성해주는 인공지능입니다.<br/>
                    나미에스엔티 AI의 음성합성 AI는 한국지능정보사회진흥원에서 운영하는 AI HUB의 빅데이터를 이용하여 학습된 모델을 사용합니다.
                </div>
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- 음성합성 AI의 기능 소개</h4>
                <h5 className="ps-lg-4 mt-3">1. 한국어(Korean)</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                    한국어 문자에 대한 음성합성을 지원하는 API입니다.<br/>
                    숫자, 영문자가 포함된 경우 이를 자동으로 한국어로 변환하여 음성을 합성합니다.<br/>
                    여성 2명, 남성 2명으로 총 4가지의 목소리를 지원합니다.
                </div>
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- 음성합성 AI만의 강점</h4>
                <h5 className="ps-lg-4 mt-3">1. 저렴하지만 준수한 품질의 음성</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                나미에스엔티 AI의 음성합성 AI는 저렴한 가격대비 준수한 품질의 음성으로, 부담없이 기능을 이용할 수 있습니다.
                </div>
                <h5 className="ps-lg-4 mt-3">2. RESTFul API를 지원하는 음성합성 AI</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                나미에스엔티 AI의 음성합성 AI는 RESTFul API를 지원하여, 다른 프로그램 혹은 서비스와 연동하여 자동화가 가능합니다.
                </div>
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- API 버전 소개</h4>
                <div className="ps-lg-4 lh-lg text-secondary">
                2023-08-14 : 한국지능정보사회진흥원에서 제작한 여성(1) 목소리입니다. 침착하고 부드러운 음성이 특징입니다.<br/>
                2023-08-15 : 한국지능정보사회진흥원에서 제작한 남성(1) 목소리입니다. 담담한 목소리가 특징입니다.<br/>
                2023-08-16 : 한국지능정보사회진흥원에서 제작한 여성(2) 목소리입니다. 또렷한 음색을 가지고 있습니다.<br/>
                2023-08-17 : 한국지능정보사회진흥원에서 제작한 남성(2) 목소리입니다. 또렷한 목소리가 특징입니다.<br/>
                </div>
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- 문제가 생겼을 때에는 어떡하나요?</h4>
                <div className="ps-lg-4 lh-lg text-secondary">
                나미에스엔티에서 제공하는 음성합성 AI가 오작동하였을 때, 확인 후 차감된 크레딧을 반환받으실 수 있습니다.<br/>
                (결과물이 나오지 않았거나, 합성이 덜되었는데 크레딧이 전체분 만큼 차감된 경우, 합성이 잘못된 경우(알아들을 수 없는 경우 등))<br/>
                </div>
            </Container>
        </>
    );

}

export default Intro_TTS;