import React, {useState, useEffect} from "react";
import Table from 'react-bootstrap/Table'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading.js";
import ListSearch from "../../listSearch.js";

import PageSelector from "../../page_selector.js";

function PayPlan(){
    const navigate = useNavigate();
    const [title, setTitle] = useState("플랜 조회/수정");
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [page, setPage]  = useState(1);
    const [totalSize, setTotalSize] = useState(0);
    const [pagelimit, setPagelimit] = useState(20);
    const [serviceType, setServiceType]= useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [searchFilter, setSearchFilter] = useState([{val:"plan", name:"플랜"}]);
    const [search_kind, setSearchKind] = useState(searchFilter[0].val);
    const [query, setQuery]= useState("");

    const registerHandler = (a, e) =>{
        e.preventDefault();
        navigate('/admin/registerPayplan', {state: {mode : 'mod', uid:a.uid}});
    }

    const PageHandler = (page)=>{
        setPage(page);
        //requestList(search_kind, query, page);
    }


    const getServiceType = () =>{
        axios.get('/rest/admin/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setServiceType(()=>{return response.data});
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );

    }
    const getServiceIndex = (uid)=>{
        if(serviceType.length == 0)
            getServiceType();
        let res_idx = -1;
        serviceType.map(function(item, idx){
            if(item.uid == uid){
                res_idx  = idx;
                return;
            }
        });
        console.log("idx : "+res_idx);
        return res_idx;
    }

    const requestList = (q_type, q) =>{
        setLoading(true);
        getServiceType();
        axios.get('/rest/admin/getPayplanList', 
            {params:
            {
                page : page-1,
                page_limit : pagelimit,
                q_type : q_type,
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                let totalSize = response.data.count;
                let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setListData(list => response.data.list);
                setTotalSize(totalSize);
                setTotalPage(totalPage_);                
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    useEffect(()=>{}, [search_kind, query]);
    useEffect(()=>{}, [listData]);
    //
    useEffect(() => {
        requestList(search_kind, query);
      },[page]);

    return(
        <>
        {loading == true ? <Loading/> : ""}
        <div className={`content card-shadow`}>
            <div>
                <h5><strong>{title}</strong></h5>
            </div>
            <ListSearch goSearch={requestList} search_kind={search_kind} setSearchKind={setSearchKind} query={query} setQuery={setQuery} filter={searchFilter}/>
            <div>
                <span>총 <strong>{totalSize}개</strong>의 결과가 있습니다.</span>
            </div>
            <div style={{overflowX:'Auto'}}>
                <Table striped bordered hover size="sm" style={{minWidth:'1300px'}} >
                    <thead>
                        <tr>
                            <th>순서</th>
                            <th>플랜</th>
                            <th>서비스유형</th>
                            <th>요금</th>
                            <th>크레딧단위</th>
                            <th>공개여부</th>
                            <th>삭제여부</th>
                            <th>생성일자</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData.map(function(a, idx){
                            return (
                                <tr key={idx}>
                                    <td>{idx+1}</td>
                                    <td onClick={(e)=>{registerHandler(a, e)}}>{a.plan_name}</td>
                                    <td>{serviceType.length > 0 && a.service_uid != null ? serviceType[getServiceIndex(a.service_uid)].service_name : ""}</td>
                                    <td>{a.fee}</td>
                                    <td>{a.credit_size}</td>
                                    <td>{a.ispublic == true ? "O" : "X"}</td>
                                    <td>{a.deleted == true ? "O" : "X"}</td>
                                    <td>{a.created_date}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            <PageSelector curPage={page} totalPage={totalPage} page_limit={pagelimit} PageHandler_={PageHandler}/>
        </div>
        </>
    );

}

export default PayPlan;