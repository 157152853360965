import React from "react";
import {Link, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import Dropdown from './Dropdown.js';
import '../css/header.css'
import LoginModal from "./Login_modal.js";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { useCookies } from "react-cookie";

function Header(props){
    const navigate = useNavigate();
    
    const [dropdownVisibility, setDropdownVisibility] = useState(false);
    const [featuresdropdownVisibility, setFeaturesDropdownVisibility] = useState(false);
    const [docsdropdownVisibility, setDocsDropdownVisibility] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(["topBannerShow"]);

    const scrollToTop = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const closeTopBanner = ()=>{
        setCookie("topBannerShow", true, {maxAge: 86400});
        props.setTopBannerShow(false);

    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          준비중입니다.. 조금만 기다려 주세요!
        </Tooltip>
      );

    useEffect(()=>{
        if (cookies.topBannerShow ==undefined){
            props.setTopBannerShow(true);
        }else{
            props.setTopBannerShow(false);
        }

    }, []);

    return (
        <>
            {
                props.topBannerShow == true ? 
                <div className="bg-danger fixed-top" style={{height:"50px"}}>
                    <Container className="text-center text-white" style={{height:"100%", lineHeight:"50px", cursor:"pointer"}} >
                        <span className=" fw-bold " onClick={()=>{navigate('register/intro')}}>
                            회원가입만 해도 누끼, 음성합성 AI 크레딧 지급!
                        </span>
                        <span className="position-absolute" style={{right:"0"}}>
                            <span className="d-inline-flex">
                                <span className="d-none d-lg-block" style={{fontSize:"0.8rem"}}>오늘 하루 보지 않기</span>
                                <Button variant="btn-sm white" onClick={()=>{closeTopBanner();}}>
                                    <i className="bi bi-x-lg"></i>
                                </Button>
                            </span>
                        </span>
                    </Container>
                </div>
                :""
            }
            <Navbar expanded={expanded} expand="lg" bg="light" fixed="top" className="shadow-sm" id="mainNav" style={{marginTop:props.topBannerShow==true ? "50px" : ""}}>
                <Container className="px-5">
                    <Link className="navbar-brand fw-bold" to="/index" onClick={()=>{scrollToTop(); }}><img style={{maxWidth:"200px"}} src={process.env.PUBLIC_URL + '/assets/img/logo.png'} /></Link>
                    <Navbar.Toggle type="button" onClick={()=>{setExpanded(!expanded)}} aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="bi-list"></i>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="navbarResponsive">
                        <Nav className=" ms-auto me-4 my-3 my-lg-0">
                            <li className="nav-item" onMouseOver={e => {setFeaturesDropdownVisibility(true); setDropdownVisibility();  setDocsDropdownVisibility(false);}} onMouseOut={e=> setFeaturesDropdownVisibility(false)}>
                                <a className="nav-link me-lg-3" href="#">특징</a>
                                <Dropdown visibility={featuresdropdownVisibility}>
                                    <ul id="dropdown-demo" className="list-group">
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/intro/bgrm" onClick={()=>{setFeaturesDropdownVisibility(false); setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}} >배경제거 AI</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/intro/nlp"  onClick={()=>{setFeaturesDropdownVisibility(false); setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}} >자연어처리 AI</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/intro/tts"  onClick={()=>{setFeaturesDropdownVisibility(false); setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}} >음성합성 AI</Link></li>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                    >
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" href="#">OCR AI</Link></li>
                                    </OverlayTrigger>
                                    </ul>
                                </Dropdown>
                            </li>
                            <li className="nav-item"  onMouseOver={e => {setDropdownVisibility(true); setFeaturesDropdownVisibility();  setDocsDropdownVisibility(false); }} onMouseOut={e=> setDropdownVisibility(false)}>
                                <a className="nav-link me-lg-3" href="#">데모</a>
                                <Dropdown visibility={dropdownVisibility}>
                                    <ul id="dropdown-demo" className="list-group">
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/demo/bgrm" onClick={()=>{setDropdownVisibility(false); scrollToTop(); setExpanded(false)}} >배경제거 AI</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/demo/nlp" onClick={()=>{setDropdownVisibility(false); scrollToTop(); setExpanded(false)}}  >자연어처리 AI</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/demo/tts" onClick={()=>{setDropdownVisibility(false); scrollToTop(); setExpanded(false)}}  >음성합성 AI</Link></li>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                    >
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" href="#">OCR AI</Link></li>
                                    </OverlayTrigger>
                                    </ul>
                                </Dropdown>
                            </li>
                            <li className="nav-item" ><Link className="nav-link me-lg-3" to="/subscribe" onClick={()=>{scrollToTop(); setExpanded(false)}} >구독/플랜</Link>
                            </li>
                            
                            <li className="nav-item"  onMouseOver={e => {setDocsDropdownVisibility(true); setFeaturesDropdownVisibility();  setDropdownVisibility(false);}} onMouseOut={e=> setDocsDropdownVisibility(false)}>
                                <a className="nav-link me-lg-3" href="#">도움말</a>
                                <Dropdown visibility={docsdropdownVisibility}>
                                    <ul id="dropdown-demo" className="list-group">
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/docs/bgrm" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}} >배경제거 AI</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/docs/nlp" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}}  >자연어처리 AI</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/docs/tts" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}}  >음성합성 AI</Link></li>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                    >
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" href="#">OCR AI</Link></li>
                                    </OverlayTrigger>
                                    </ul>
                                </Dropdown>
                            </li>
                        </Nav>
                        {
                            !props.isLogged ? 
                            <button className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0" onClick={()=>{props.setLoginShow(true); setExpanded(false)}}>
                                <span className="d-flex align-items-center">
                                    <span className="small">로그인</span>
                                </span>
                            </button>
                            :
                            <>
                                <button className="btn btn-outline-primary rounded-pill px-3 mb-2 mb-lg-0" onClick={()=>{navigate("/mypage/dashboard");  setExpanded(false)}}>
                                    <span className="d-flex align-items-center">
                                        <span className="small">마이페이지</span>
                                    </span>
                                </button>
                                <Button variant="light" type="button" size="sm" className="ms-3" onClick={()=>{props.Logout_func();  setExpanded(false)}}>
                                    <img style={{height:"1.5rem"}} src={process.env.PUBLIC_URL + '/assets/img/logout.png'}></img>
                                </Button>
                            </>
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}



export default Header;