import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, ListGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import Modal_alert from "../../../common/modal";


function ModifyServiceType(){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [btnText, setBtntext] = useState("");
    
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);

    
    const [payplanSearch, setPayplansearch] = useState("");
    const [payplanList, setPayplanList] = useState([]);
    const [payplanListShow, setPayplanListShow] = useState(false);

    const[values, setValues] = useState({uid : 0, service_name:"", service_validation:false, auto_order_plan:0, plan_name:"없음"});

    const handleChange = e => {
        console.log(e.target.name+": "+e.target.value);
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
      }

    const handleValidation = () =>{
        setValues({
            ...values,
            service_validation :  !(values.service_validation),
        })
    };

    const handlePayplanSearch = e => {
        setPayplansearch(e.target.value);
    }
    const payplanSelectHandler = (e) => {
        setValues({...values, auto_order_plan:e.target.getAttribute("uid"), plan_name:e.target.getAttribute("name_") });
        setPayplansearch("");
        setPayplanListShow(false);
    }
    const searchPayplan = (q) =>{
        const params = {
            list_type:"detail",
            page : 0,
            page_limit : 10,
            q_type : 'plan',
            query : q

        }
        const params_url = new URLSearchParams(params);
        axios.get('/rest/admin/getPayplanList?'+params_url.toString()).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setPayplanList(()=>{return [{uid:0, plan_name:"없음"}].concat( response.data.list)});           
                setPayplanListShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
      }else{
        updateServiceType();
      }

      setValidated(true);
      
    };
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/serviceTypeList");
    };
    const handleShow = () => setShow(true);

    const getPayplan = () =>{
        setLoading(()=>{return true});
        setValues({uid : location.state.data.uid, service_name:location.state.data.service_name, service_validation:location.state.data.service_validation, auto_order_plan: location.state.data.auto_order_plan != undefined ? location.state.auto_order_plan : 0, plan_name:location.state.data.plan_name != undefined ? location.state.data.plan_name : "없음"});
        setLoading(()=>{return false});
    }
    const updateServiceType = () =>{
        setLoading(true);
        axios.post('/rest/admin/updateServiceType',
        { 
            uid: values.uid,
            service_name: values.service_name,
            service_validation: values.service_validation,
            auto_order_plan : Number(values.auto_order_plan)
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    setModalTitle("서비스유형 업데이트 성공");
                    setModalContent("서비스유형 업데이트에 성공하였습니다.");
                    setRegisterResult(true);
                }else{
                    setModalTitle("서비스유형 업데이트 실패");
                    setModalContent("서비스유형 업데이트에 실패하였습니다.");
                    setRegisterResult(false);
                }
                handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    useEffect(() => {getPayplan();}, []);
    useEffect(()=>{console.log(values)}, [values]);
    useEffect(() => {
        if(payplanSearch.length > 0)
            searchPayplan(payplanSearch);
        else
            setPayplanListShow(false);
    }, [payplanSearch]);
    useEffect(() => {
        if(location.state != null && location.state.mode == 'mod'){
            setTitle(()=>{
                return "서비스유형 수정";
            })
            setBtntext(()=>{
                return "수정";
            })
            getPayplan();
        }else{
            setTitle(()=>{
                return "서비스유형 신규 등록";
            })
            setBtntext(()=>{
                return "등록";
            })
            setValues({uid : 0, service_name:"", service_validation:0, auto_order_plan:0});
        }
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Container>
                        <Row>
                            <Col lg={4}>
                                <Form.Group>
                                    <Form.Label>서비스 유형 이름</Form.Label>
                                    <Form.Control required type="text" name="service_name" defaultValue={values.service_name} onChange={handleChange} placeholder="플랜이름 입력"/>
                                    <Form.Control.Feedback type="invalid">
                                        서비스 유형 이름을 입력해주세요.
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        홈페이지에 공개될 서비스 유형 이름입니다.
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>서비스 활성화 여부</Form.Label>
                                    <Form.Check name="service_validation" type="switch" checked={values.service_validation} onChange={handleValidation} label="공개"></Form.Check>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group>
                                    <Form.Label>회원가입 시 자동 주문 플랜</Form.Label>
                                    <Form.Control required type="text" name="planname" value={values.plan_name} onChange={handleChange} readOnly/>
                                    <Form.Control.Feedback type="invalid">
                                        서비스 유형 이름을 입력해주세요.
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        홈페이지에 공개될 서비스 유형 이름입니다.
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col lg={4}>
                                <Form.Group>
                                    <Form.Label>회원가입 시 자동 주문 플랜검색</Form.Label>
                                    <Form.Control type="text" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none", paddingRight:0}} value={payplanSearch} onChange={handlePayplanSearch} placeholder="플랜 검색" />
                                    <ListGroup className={`${payplanListShow ? "" : "d-none"} position-absolute`}>
                                        {payplanList.map(function(item, idx){
                                            return(<ListGroup.Item action type="button" key={idx} onClick={payplanSelectHandler} uid={item.uid} name_={item.plan_name} fee={item.fee}>{item.plan_name}</ListGroup.Item>)
                                        })}
                                    </ListGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            <Col lg={11}>
                            </Col>
                            <Col sm={1}>
                                <Button type="submit" className="w-100">{btnText}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
            </div>
        </>
        );

}

export default ModifyServiceType;