import React from "react";
import Table from 'react-bootstrap/Table'
import axios from "axios";
import { useLocation, useNavigate, Link} from "react-router-dom";
import Loading from "../../../common/Loading.js";
import ListSearch from "../../../common/listSearch.js";
import PageSelector from "../../../common/page_selector.js";
import { useState, useEffect } from "react";
import Status_view from "./modules/status_view.js";
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Modal_Password_alert from "../../../common/modal_password.js";
import Modal_alert from "../../../common/modal.js";
import { CopyToClipboard } from "react-copy-to-clipboard";

function ApiDashboard(){
    const navigate = useNavigate();
    const location = useLocation();
    const [title] = useState("서비스 목록 조회");
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [page, setPage]  = useState(1);
    const [totalSize, setTotalSize] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [pagelimit, setPagelimit] = useState(20);
    const [serviceType, setServiceType]= useState([]);
    const [searchFilter, setSearchFilter] = useState([{val:"api_version", name:"API버전"},{val:"user_agent", name:"접속기기"}]);
    const [search_kind, setSearchKind] = useState(searchFilter[0].val);
    const [query, setQuery]= useState("");
    const [authInfo, setAuthInfo] = useState({client_secret:"****************************",});
    const [noticeInfo, setNoticeInfo] = useState([]);
    const [secretView, setSecretView] = useState(false);
    const [myinfo_pw_show, setMyinfoPWShow] = useState(false);
    const [secret_pw_fail, setSecretPwFailShow] = useState(false);
    const [secret_change_alert, setSecretChangeAlert] = useState(false);
    const [token_change_alert, setTokenChangeAlert] = useState(false);
    const [refresh_complete, setRefreshComplete] = useState(false);
    const [refresh_failed, setRefreshFailed] = useState(false);

    const [secret_pw, setSecretPw] = useState("");
    const [pw_show, setPwShow] = useState(false);

    const pw_handleSubmit = ()=>{
        setLoading(true);
        axios.post('/rest/getClientSecret',{
                service_uid : location.state.service_uid,
                pw : secret_pw
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setPwShow(false);
                if(response.data.result == 'success'){ 
                    setAuthInfo({...authInfo, client_secret:response.data.client_secret,});
                    setSecretView(true);
                }else{
                    setSecretPwFailShow(true);
                }
            }
            setLoading(false);
            setSecretPw("");
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const pw_myinfo_handleSubmit = ()=>{
        setLoading(true);
        axios.post('/rest/myinfo_pw_check',{
                pw : secret_pw
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setPwShow(false);
                if(response.data.result == 'success'){ 
                    navigate("/mypage/modifyInfo", {state:{key:response.data.key}});
                }else{
                    setSecretPwFailShow(true);
                }
            }
            setLoading(false);
            setSecretPw("");
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    

    const PageHandler = (page)=>{
        setPage(page);
        //requestList(search_kind, query,page, location.state.service_uid);
    }
    
    const getServiceType = () =>{
        axios.get('/rest/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setServiceType(()=>{return response.data});
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );

    }
    const getServiceIndex = (uid)=>{
        let res_idx = -1;
        serviceType.map(function(item, idx){
            if(item.uid == uid){
                res_idx  = idx;
                return;
            }
        });
        return res_idx;
    }

    const requestAuthInfo = () =>{
        setLoading(true);
        getServiceType();
        axios.get('/rest/getAuthInfo',{params:
            {
                service_uid : location.state.service_uid
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setAuthInfo(response.data.data[0]);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const requestList = (q_type, q) =>{
        setLoading(true);
        getServiceType();
        axios.get('/rest/getServiceList', 
            {params:
            {
                service_uid : location.state.service_uid,
                page : page-1,
                page_limit : pagelimit,
                q_type : q_type,
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                let totalSize = response.data.count;
                let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setListData(list => response.data.data);
                setTotalSize(totalSize);
                setTotalPage(totalPage_);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const refreshClientSecret = () =>{
        setLoading(true);
        getServiceType();
        axios.get('/rest/refreshClientSecret',{params:
            {
                service_uid : location.state.service_uid
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setSecretChangeAlert(false);
                if(response.data.result == "success"){
                    setRefreshComplete(true);
                    setSecretView(false);
                    requestAuthInfo();
                }else{
                    setRefreshFailed(true);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const refreshServiceToken = () =>{
        setLoading(true);
        getServiceType();
        axios.get('/rest/refreshServiceToken',{params:
            {
                service_uid : location.state.service_uid
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setTokenChangeAlert(false);
                if(response.data.result == "success"){
                    setSecretView(false);
                    requestAuthInfo();
                }else{
                    setRefreshFailed(true);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          준비중입니다.. 조금만 기다려 주세요!
        </Tooltip>
    );

    useEffect(()=>{}, [search_kind, query]);

    useEffect(()=>{
        if(location.state != null){
            requestAuthInfo();
            //requestList(search_kind,query);
        }else{
            navigate("/");
        }
    }, []);

    //
    useEffect(() => {
        if(location.state != null){
            requestList(search_kind,query);
        }else{
            navigate("/");
        }
      },[page, authInfo]);

    return(
        <>
        {loading == true ? <Loading/> : ""}
        <section className="section-content" style={{paddingBottom:"0"}}>
            <Status_view setLoading={setLoading} setMyinfoShow={setMyinfoPWShow} modifyInfo={pw_myinfo_handleSubmit}/>
            <Breadcrumb className='mt-3'>
                <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                <Breadcrumb.Item href="/mypage/dashboard">
                    마이페이지
                </Breadcrumb.Item>
                <BreadcrumbItem active>API대시보드</BreadcrumbItem>
            </Breadcrumb>
        </section>
        <section className="sections-white" style={{marginTop:"1rem", paddingBottom:"0", marginBottom:"0"}}>
            <div className='section-content'>
                <h5><strong>공지사항</strong></h5>
                { noticeInfo.length > 0 ?
                    noticeInfo.map(function(a, idx){
                        return(
                            <Card key={idx} className='mx-4'>
                                <Card.Body>
                                    <Row className='border-bottom'>
                                        <Col lg={8}>
                                            <h6>{a.title}</h6>
                                        </Col>
                                        <Col className='text-end'>
                                            <h6>{a.created_date}</h6>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        );
                    })
                    :
                    <>
                        <h6 className='text-center pb-4 m-0'>공지사항이 없습니다.</h6>
                    </>
                }
            </div>
        </section>
        <section className="sections-white" style={{ paddingBottom:"0", marginBottom:"0"}}>
            <div className='section-content'>
                <h5><strong>서비스현황</strong></h5>
                {
                    authInfo != null ?
                        <Card className='border border-white'>
                            <Card.Body>
                                <Row className='px-3'>
                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col lg={10}>
                                                    <Row>
                                                        <h6><strong>{serviceType.length > 0 && authInfo.service_uid != null ? serviceType[getServiceIndex(authInfo.service_uid)].service_name : ""}</strong></h6>
                                                        <span className='ms-2'>잔여크레딧 : <strong>{authInfo.credit}</strong></span>
                                                        <span className='ms-2'>만료임박 크레딧 유효기간 : <strong>{authInfo.invalid_date}</strong></span>
                                                        <span className='ms-2'>서비스 토큰 갱신 일시 : <strong>{authInfo.refresh_date}</strong></span>
                                                    </Row>
                                                    <Row className="mt-4">
                                                        <Form.Group  className='ms-2' style={{width:"20rem"}}>
                                                            <Form.Label>클라이언트 아이디</Form.Label>
                                                            <InputGroup>    
                                                                <Form.Control  type="text" defaultValue={authInfo.client_id} readOnly/>
                                                                <CopyToClipboard text={authInfo.client_id} onCopy={()=>{alert("복사되었습니다!")}}>
                                                                    <Button variant="light"><i className="bi bi-clipboard"></i></Button>
                                                                </CopyToClipboard>
                                                            </InputGroup>
                                                        </Form.Group>
                                                        <Form.Group  className='ms-2' style={{width:"20rem"}}>
                                                            <Form.Label>클라이언트 시크릿</Form.Label>
                                                            <InputGroup>
                                                                <Form.Control  type="text" value={authInfo.client_secret || '****************************'} readOnly/>
                                                                {secretView ? 
                                                                    <>
                                                                        <CopyToClipboard text={authInfo.client_secret} onCopy={()=>{alert("복사되었습니다!")}}>
                                                                            <Button variant="light"><i className="bi bi-clipboard"></i></Button>
                                                                        </CopyToClipboard>
                                                                        <Button variant="light" onClick={(e)=>{setSecretChangeAlert(true)}}><i className="bi bi-arrow-clockwise"></i></Button>
                                                                    </>
                                                                    :
                                                                    <Button variant="light"  onClick={(e)=>{setPwShow(true);}}><i className="bi bi-eye"></i></Button>
                                                                }
                                                            </InputGroup>
                                                        </Form.Group>
                                                        <Form.Group className='ms-2' style={{width:"20rem"}}>
                                                            <Form.Label>서비스 토큰</Form.Label>
                                                            <InputGroup>
                                                                <Form.Control  type="text" defaultValue={authInfo.service_token} readOnly/>
                                                                <CopyToClipboard text={authInfo.service_token} onCopy={()=>{alert("복사되었습니다!")}}>
                                                                    <Button variant="light"><i className="bi bi-clipboard"></i></Button>
                                                                </CopyToClipboard>
                                                                <Button variant="light" onClick={(e)=>{setTokenChangeAlert(true)}}><i className="bi bi-arrow-clockwise"></i></Button>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Row>
                                                </Col>
                                                <Col lg={2}>
                                                    <Row className='text-end'>
                                                        <Link to={{pathname: "/mypage/webapi"}} state={{service_uid: authInfo.service_uid}}>
                                                            <span>웹 API 이용하기</span>
                                                        </Link>
                                                        <Link to={{pathname: "/mypage/viewAPILog"}} state={{service_uid: authInfo.service_uid}}>
                                                            <span>이용로그 확인</span>
                                                        </Link>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Card.Body>
                        </Card>
                    :
                    <>
                        <h6 className='text-center pb-4 m-0'>이용중이신 서비스가 없습니다.</h6>
                    </>
                }
            </div>
        </section>
        <section className="sections-white" style={{ paddingBottom:"0", marginBottom:"0"}}>
                <div className='section-content'>
                    <h5><strong>크레딧현황</strong></h5>
                    {
                        listData.length > 0 ?
                        listData.map(function(a, idx){
                                return(
                                    <Card key={idx} className='border border-white'>
                                        <Card.Body>
                                            <Row className='px-3'>
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <Row>
                                                                    <h6><strong>{serviceType.length > 0 && a.service_uid != null ? serviceType[getServiceIndex(a.service_uid)].service_name : ""}</strong></h6>
                                                                    <span className='ms-2'>잔여크레딧 : <strong>{a.credit}</strong></span>
                                                                    <span className='ms-2'>크레딧 유효기간 : <strong>{a.invalid_date}</strong></span>
                                                                    <span className='ms-2'>생성일자 : <strong>{a.created_date}</strong></span>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                );
                            })
                        :
                        <>
                            <h6 className='text-center pb-4 m-0'>이용중이신 서비스가 없습니다.</h6>
                        </>
                    }
                </div>
            </section>
        <Modal_Password_alert pw={secret_pw} setSecretPw={setSecretPw} show={pw_show} handleClose={()=>{setPwShow(false); setSecretPw("");}} handleSubmit={pw_handleSubmit} modal_title="비밀번호 입력" content="클라이언트 시크릿 확인을 위해 비밀번호를 입력해주세요." secondary="닫기" primary_btn="확인"/>
        <Modal_alert show={secret_pw_fail} handleClose={()=>{setSecretPwFailShow(false); }} modal_title="비밀번호 불일치" content="비밀번호가 일치하지 않습니다." primary_btn="확인"/>
        <Modal_alert show={secret_change_alert} handleClose={()=>{setSecretChangeAlert(false); }} handleSubmit={refreshClientSecret} modal_title="갱신여부확인" content="클라이언트 시크릿을 갱신하면 연동된 서비스들에 문제가 생길 수 있습니다. 계속하시겠습니까?" secondary="아니오" primary_btn="네"/>
        <Modal_alert show={token_change_alert} handleClose={()=>{setTokenChangeAlert(false); }}  handleSubmit={refreshServiceToken} modal_title="갱신여부확인" content="서비스 토큰을 갱신하면 연동된 서비스들에 문제가 생길 수 있습니다. 계속하시겠습니까?" secondary="아니오" primary_btn="네"/>
        <Modal_alert show={refresh_complete} handleClose={()=>{setRefreshComplete(false); }} modal_title="갱신성공" content="갱신에 성공하였습니다." primary_btn="확인"/>
        <Modal_alert show={refresh_failed} handleClose={()=>{setRefreshFailed(false); }} modal_title="갱신실패" content="갱신에 실패하였습니다." primary_btn="확인"/>
        <Modal_Password_alert show={myinfo_pw_show} pw={secret_pw} setSecretPw={setSecretPw} handleClose={()=>{setMyinfoPWShow(); setSecretPw("");}} handleSubmit={()=>{pw_myinfo_handleSubmit()}} modal_title="비밀번호 입력" content="회원정보 수정을 위해 비밀번호를 입력해주세요." secondary="닫기" primary_btn="확인" />
        

        </>

    );

}

export default ApiDashboard;