import React from "react";
import Banner from "../../common/banner";
import { Breadcrumb } from "react-bootstrap";
import Docs_NLP from "./docs_nlp";
import Docs_BGRM from "./docs_bgrm";
import Docs_TTS from "./docs_tts";

function Docs(props){
    
    return(
        <>
            <div className="banner-slider">
                <Banner />
            </div>
            <section className="px-lg-5 mx-lg-5 px-lg-0 px-3" style={{paddingBottom:"0"}}>
                <Breadcrumb className='mt-3'>
                    <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {props.page == 'nlp' ? '자연어처리 AI 도움말' : props.page=="bgrm" ? "배경제거 AI 도움말" : props.page=="tts" ? "음성합성 AI 도움말" : ""}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </section>
            <div className="pt-0">
                <section className="sections-white mt-0 p-4">
                    {
                        props.page == "nlp" ?
                        <Docs_NLP/> :
                        props.page == "bgrm" ?
                        <Docs_BGRM/> :
                        props.page == "tts" ?
                        <Docs_TTS/> :
                        ""
                    }
                </section>
            </div>
        </>
    );
}

export default Docs;