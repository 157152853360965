import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, InputGroup, ListGroup } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Modal_alert from "../../modal";

function RegisterCoupon_single(props){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("쿠폰발급(비회원)");
    const [loading, setLoading] = useState(false);
    const [serviceType, setServiceType]= useState([]);

    const [btnText, setBtntext] = useState("수정");
    
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [regist_show, setRegistShow] = useState(false);
    const [regist_complete_show, setRegistCompleteShow] = useState(false);
    const [regist_failed_show, setRegistFailedShow] = useState(false);
    const [validated, setValidated] = useState(false);

    const[values, setValues] = useState({service_uid : 1, pay_amount:"",valid_start : new Date(), valid_end:new Date(),email:"", ea:1});

    const handleChange = e => {
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
      }

    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/couponList");
    };
    const handleShow = () => setShow(true);
    const setValidStart = ( e) =>{
        setValues({...values, valid_start : e})
    }

    const setValidEnd = (e) =>{
        setValues({...values, valid_end : e})
    }
    const getServiceType = () =>{
        axios.get('/rest/admin/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setServiceType(()=>{return response.data});
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }

    const regist = () =>{
        setLoading(true);
        getServiceType();
        axios.post('/rest/admin/registerCouponBatch',
        { 
            members:[],
            coupons : [{service_uid : Number(values.service_uid), pay_amount:Number(values.pay_amount), valid_start:values.valid_start, valid_end:values.valid_end, ea:values.ea}],
            email:values.email
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    setModalTitle("쿠폰 발급 성공");
                    setModalContent("쿠폰발급에 성공하였습니다.");
                    setRegisterResult(true);
                }else{
                    setModalTitle("쿠폰 발급 실패");
                    setModalContent("쿠폰발급에 실패하였습니다.");
                    setRegisterResult(false);
                }
                handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    useEffect(()=>{getServiceType()}, []);
    useEffect(() => {}, [values, serviceType]);
    useEffect(() => {
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={5}>
                                <Form.Group>
                                    <Form.Label>쿠폰번호</Form.Label>
                                    <Form.Control required type="text" name="coupon_id" defaultValue="쿠폰이 발급되면 표시됩니다." readOnly/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>수신이메일</Form.Label>
                                    <Form.Control  type="email" defaultValue={values.email} name="email" onChange={handleChange}/>
                                    
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>발급일시</Form.Label>
                                    <Form.Control  type="text" defaultValue="쿠폰이 발급되면 표시됩니다." readOnly/>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>서비스유형</Form.Label>
                                    <Form.Select name="service_uid" value={values.service_uid} onChange={handleChange} aria-label="서비스종류 선택">
                                        {serviceType.map(function(item, idx){
                                            return (<option key={idx} value={item.uid}>{item.service_name}</option>);
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>쿠폰금액</Form.Label>
                                    <Form.Control  type="number" defaultValue={values.pay_amount} name="pay_amount" onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2}>
                                <Form.Group className="">
                                    <Form.Label className="m-0">유효시작일자</Form.Label><br/>
                                    <ReactDatePicker className="form-control" showTimeSelect dateFormat="yyyy-MM-dd hh:mm" dateFormatCalendar="yyyy년 MM월" selected={values.valid_start} onChange={(date) => setValidStart(date)} selectsStart startDate={values.valid_start} endDate={values.valid_end}/>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="m-0">만료일자</Form.Label><br/>
                                    <ReactDatePicker className="form-control" showTimeSelect dateFormat="yyyy-MM-dd hh:mm" dateFormatCalendar="yyyy년 MM월"  selected={values.valid_end} onChange={(date) => setValidEnd(date)} selectsStart startDate={values.valid_start} endDate={values.valid_end}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            <Col className="text-end">
                                    <Button type="button" className={` btn btn-secondary `}  style={{marginTop:"2rem"}} onClick={()=>{setRegistShow(true)}}>쿠폰 발급</Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
                <Modal_alert show={regist_show} handleClose={()=>{setRegistShow(false)}} handleSubmit={regist} modal_title="" content={`쿠폰을 발급하시겠습니까?`} secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={regist_complete_show} handleClose={()=>{setRegistCompleteShow(false); navigate("/admin/couponList")}} modal_title="쿠폰발급완료" content="쿠폰이 발급되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={regist_failed_show} handleClose={()=>{setRegistFailedShow(false);}} modal_title="쿠폰발급실패" content="쿠폰발급에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>

            </div>
        </>
        );

}

export default RegisterCoupon_single