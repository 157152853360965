import React, {useState} from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import  styles from "../../css/admin/common-admin.module.css"


function Admin_Sidebar(props){
	const [name, setName] = useState('');
	const navigate = useNavigate;
	const check_logged = async() => {
		const response = await axios.get("/rest/admin/getUserInfo");
		setName(response.name);
	};
    return (
        <div className={`${styles.sidebar} ${props.toggle_ ? "" : styles.toggled}`} id={styles.sidebar}>
        	<nav className="navbar navbar-dark bg-white">
                <div className={`container-fluid ${styles["container-fluid"]} table`}>
        	        <div id={styles["logo-container"]} className="col-6">
        	    			<img alt="LOGO" src={process.env.PUBLIC_URL + '/assets/img/logo.png'} id="logo"/>
        	        </div>
                    <button className={`navbar-toggler ${props.toggle_ ? "" : "d-none"}`} type="button" onClick={() =>{props.handleSidebarToggle();}}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
        	        <div className={styles["account-info"]}>
        	            <span>{props.UserName}</span>
        	    	    <button className={`${styles["logout"]} btn btn-sm p-0`} onClick={props.Logout_func}>
        	    	        <strong>LOGOUT</strong>
        	    	    </button>
        	        </div>
                </div>
            </nav>
        	<div className={styles["sidebar-item"]}>
        		<div className={styles["sidebar-item-list"]}>
        			<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>대시보드
        						</a>
        					</div>
        				</li>
        			</ul>
        			<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>API 현황
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
								<Link to="/admin/serviceTypeList" onClick={() =>{props.handleSidebarToggle();}}>
        							<i className=" mr-2"></i>API 서비스 목록 조회
        						</Link>
        					</div>
        				</li>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>API 부하 기준 조정
        						</a>
        					</div>
        				</li>
						<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
								<Link to="/admin/VisitorLog" onClick={() =>{props.handleSidebarToggle();}}>
        							<i className=" mr-2"></i>접속자 로그/통계 조회
        						</Link>
        					</div>
        				</li>
        			</ul>
        			</ul>
        			<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>회원정보
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
								<Link to="/admin/UserList" onClick={() =>{props.handleSidebarToggle();}}>
        							<i className=" mr-2"></i>회원목록조회/수정
        						</Link>
        					</div>
        				</li>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>회원추가
        						</a>
        					</div>
        				</li>
						<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>사업자정보조회
        						</a>
        					</div>
        				</li>
        			</ul>
        			</ul>
        			<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>크레딧
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
								<Link to="/admin/CreditList" onClick={() =>{props.handleSidebarToggle();}}>
        							<i className=" mr-2"></i>크레딧 현황 조회
        						</Link>
        					</div>
        				</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
                                <Link to="/admin/APILogList" onClick={() =>{props.handleSidebarToggle();}}>
        							<i className=" mr-2"></i>크레딧 사용 로그 조회
        						</Link>
        					</div>
        				</li>
        			</ul>

        			</ul>

        			<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>결제관리
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
                                <Link to="/admin/OrderList" onClick={() =>{props.handleSidebarToggle();}}>
        							<i className=" mr-2"></i>주문서/결제 현황 조회
        						</Link> 
        					</div>
        				</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<Link to="/admin/registerOrder" onClick={() =>{props.handleSidebarToggle();}}>
        							<i className=" mr-2"></i>주문서 추가
        						</Link>
        					</div>
        				</li>
						<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
								<Link to="/admin/CouponList" onClick={() =>{props.handleSidebarToggle();}}>
        							<i className=" mr-2"></i>쿠폰 발급 현황 조회
								</Link>
        					</div>
        				</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
								<Link to="/admin/RegisterCoupon" onClick={() =>{props.handleSidebarToggle();}}>

        							<i className=" mr-2"></i>쿠폰발급
        						</Link>
        					</div>
        				</li>
						<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
								<Link to="/admin/RegisterCouponSingle" onClick={() =>{props.handleSidebarToggle();}}>

        							<i className=" mr-2"></i>쿠폰발급(비회원)
        						</Link>
        					</div>
        				</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
								<Link to="/admin/receiptRequestList" onClick={() =>{props.handleSidebarToggle();}}>
        							<i className=" mr-2"></i>현금영수증/세금계산서 발행요청 조회
        						</Link>
        					</div>
        				</li>
        			</ul>

        			</ul>
        			<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>플랜(요금제)관리
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
                                <Link to="/admin/Payplan" onClick={() =>{props.handleSidebarToggle();}}>
        							<i className=" mr-2"></i>플랜 조회/수정
        						</Link> 
        					</div>
        				</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
								<Link to="/admin/registerPayplan" onClick={() =>{props.handleSidebarToggle();}}>
        							<i className=" mr-2"></i>플랜 추가
        						</Link>
        					</div>
        				</li>
        			</ul>
					</ul>
					<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>문의/답변
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>문의 현황 조회
        						</a> 
        					</div>
        				</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>FAQ 조회/수정
        						</a>
        					</div>
        				</li>
        			</ul>

        			</ul>
        		</div>
        	</div>
        </div>
    );

}

export default Admin_Sidebar;