import React from "react";
import UserList from "./userList";
import CreditList from "./creditList";
import PayPlan from "./payplan";
import OrderList from "./orderList";
import APILogList from "./apiLogList";
import RegisterPayplan from "./registerPayplan";
import RegisterOrder from "./registerOrder";
import ModifyOrder from "./modifyOrder";
import CouponList from "./couponList";
import RegisterCoupon from "./registerCoupon";
import ModifyCoupon from "./modifyCoupon";
import RegisterCoupon_single from "./registerCoupon_single";
import VisitorLog from "./visitorLog";
import ReceiptRequestList from "./receiptRequestList";
import ServiceList from "./serviceList";
import ModifyServiceType from "./modifyServiceType";

function Admin_dashboard_content(props){
    if(props.menu == "userlist"){
        return (
            <UserList/>
        );
    }else if(props.menu == 'servicetypelist'){
        return(
            <ServiceList/>
        );
    }else if(props.menu == 'modifyservicetype'){
        return(
            <ModifyServiceType/>
        );
    }else if(props.menu == "creditlist"){
        return(
            <CreditList/>
        );
    }else if(props.menu == "payplan"){
        return(
            <PayPlan/>
        );
    }else if(props.menu == "orderlist"){
        return(
            <OrderList/>
        );
    }else if(props.menu == "apiloglist"){
        return(
            <APILogList/>
        );
    }else if(props.menu == "registerpayplan"){
        return(
            <RegisterPayplan/>
        );
    }else if(props.menu == 'registerorder'){
        return(
            <RegisterOrder/>
        );
    }else if(props.menu == 'modifyorder'){
        return(
            <ModifyOrder/>
        );
    }else if(props.menu == 'couponlist'){
        return(
            <CouponList/>
        );
    }else if(props.menu === 'registercoupon'){
        return(
            <RegisterCoupon/>
        );
    }else if(props.menu == 'modifycoupon'){
        return(
            <ModifyCoupon/>
        );
    }else if(props.menu == 'single_registercoupon'){
        return(
            <RegisterCoupon_single/>
        );
    }else if (props.menu == 'visitorlog'){
        return(
            <VisitorLog/>
        );
    }else if(props.menu == 'receiptrequestlist'){
        return(
            <ReceiptRequestList/>
        );
    }else{
        return (
            <>404 error : page not found</>
        );
    }


}

export default Admin_dashboard_content;