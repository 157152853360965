import React from "react";
//import 'bootstrap/dist/css/bootstrap.css'
import Modal_Demo_select from "./modal_demo_select";
import { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import '../css/style.css'
import { Link, useNavigate } from "react-router-dom";

function BannerIndex(){
    const navigate = useNavigate();
    const [demo_show, setDemoShow] = useState(false);
    return (
        <>
            <header className="masthead">
                <Container className="px-5">
                    <Row className="gx-5 align-items-center">
                        <Col lg={6}>
                            <div className="mb-5 mb-lg-0 text-center text-lg-start">
                                <h1 className="display-6 lh-2 mb-3">인공지능을 더 쉽게,<br/> 더 강력하게.</h1>
                                <p className="lead fw-normal text-muted mb-5">나미에스엔티의 다양한 인공지능 API를 이용하여 쉽고 빠르게<br></br>빅데이터를 처리하세요!</p>
                                <div className="d-flex flex-column flex-lg-row align-items-center">
                                    <a onClick={()=>{setDemoShow(true)}} className="me-lg-3 mb-4 mb-lg-0" href="#!"><div style={{width:"12rem", height:"4rem", textAlign:"center",lineHeight:"4rem", borderRadius:"10px", fontSize:"1.5rem", color:"white", backgroundColor:"black"}}>데모 체험하기</div></a>
                                    <Link to="/subscribe" className="me-lg-3 mb-4 mb-lg-0" href="#!"><div style={{width:"15rem", height:"4rem", textAlign:"center",lineHeight:"4rem", borderRadius:"10px", fontSize:"1.5rem", color:"white", backgroundColor:"black"}}>구독/플랜 알아보기</div></Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="masthead-device-mockup">
                                <svg className="circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="circleGradient" gradientTransform="rotate(45)">
                                            <stop className="gradient-start-color" offset="0%"></stop>
                                            <stop className="gradient-end-color" offset="100%"></stop>
                                        </linearGradient>
                                    </defs>
                                    <circle cx="50" cy="50" r="50"></circle></svg>
                                    <svg className="shape-1 d-none d-sm-block" viewBox="0 0 240.83 240.83" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(120.42 -49.88) rotate(45)"></rect>
                                    <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(-49.88 120.42) rotate(-45)"></rect></svg>
                                    <svg className="shape-2 d-none d-sm-block" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"></circle></svg>
                                    <img className="d-none d-sm-block" src={process.env.PUBLIC_URL + '/assets/img/Banner_index.png'} style={{maxWidth:'70%', height:'70%', zIndex:"100", marginLeft:"0.5rem"}}></img>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
            <Container className="py-5 bg-body-secondary mw-100">
                <Container className=" gx-5 align-items-center section-content">
                    <Row>
                        <h3 className="text-center fw-bold mb-5 pt-5">인공지능을 직접 개발하지 않아도 됩니다.<br/>고성능 시스템이 필요하지도 않습니다.</h3>
                    </Row>
                    <Row className="pb-5">
                        <h4 className="text-center fw-bold mb-3">나미에스엔티 AI에서는 개발자를 위한 REST API를 지원합니다.</h4>
                        <p className="text-center">인터넷만 되면 OK. 서비스 개발에 필요한 인공지능 API를 저비용으로 이용하실 수 있습니다.</p>
                    </Row>
                </Container>
            </Container>
            <Container className="py-5 mw-100 bg-white">
                <Container className=" gx-5 align-items-center section-content">
                    <Row>
                        <h3 className="text-center fw-bold pt-5">나미에스엔티 AI의<br/>다양한 인공지능 API 서비스를 만나보세요.</h3>
                        <p className="text-center mb-5">놀라운 인공지능 서비스를 만들기 위해 항상 노력하고 있습니다.</p>
                    </Row>
                    <Row>
                        <Col lg={4} className="text-center">
                            <h4 className="text-center fw-bold mb-3 pt-5">이미지 배경제거 AI</h4>
                            <p className="text-center">편리한 방법으로 사진에서 배경을 제거하고<br/>새로운 디자인으로 꾸며보세요 :)</p>
                            <Button variant="outline-secondary" onClick={()=>{navigate("/demo/bgrm")}}>체험하기</Button>
                        </Col>
                        <Col lg={4} className="text-center">
                            <h4 className="text-center fw-bold mb-3 pt-5">자연어처리 AI</h4>
                            <p className="text-center">단어기반 자연어처리 인공지능 모델을 이용해<br/>여러분의 아이디어를 실현해보세요!</p>
                            <Button variant="outline-secondary" onClick={()=>{navigate("/demo/nlp")}} >체험하기</Button>
                        </Col>
                        <Col lg={4} className="text-center">
                            <h4 className="text-center fw-bold mb-3 pt-5">음성합성 AI</h4>
                            <p className="text-center">가격대비 저렴한 음성합성 AI로<br/>부담없이 목소리를 만들어보세요 :D</p>
                            <Button variant="outline-secondary" onClick={()=>{navigate("/demo/tts")}} >체험하기</Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="py-5 mw-100 bg-white">
                <Container className=" gx-5 align-items-center section-content d-grid">
                    <Row>
                        <h3 className="text-center fw-bold pt-5">제휴/기타문의</h3>
                        <p className="text-center mb-5">상담문의는 언제나 환영입니다.</p>
                    </Row>
                        <span className="m-auto fs-5" style={{width:"fit-content"}}>
                            <span className="">
                                <i className="bi bi-geo-alt me-4 fs-4" style={{color:"#0097dd"}}></i>
                                <span>[05340] 서울특별시 강동구 천호대로 1073, 지하2층 26호(천호동, 힐탑프라자)</span>
                            </span><br/>
                            <span className="">
                                <i className="bi bi-envelope me-4 fs-4" style={{color:"#0097dd"}}></i> 
                                <span>work@namisnt.com</span>
                            </span><br/>
                            <span className="">
                                <i className="bi bi-telephone me-4 fs-4" style={{color:"#0097dd"}}></i>
                                <span>1588-7397</span>
                            </span>
                        </span>
                        <div className="text-center">
                            <Link to="javascript:chatChannel()">
                                <img className="w-md-25 w-100 mt-3" src={process.env.PUBLIC_URL + '/assets/img/kakao_channel.png'} />
                            </Link>
                        </div>
                </Container>
            </Container>
            <Modal_Demo_select show={demo_show} handleClose={()=>{setDemoShow(false)}}/>
        </>
    );
}
export default BannerIndex;
