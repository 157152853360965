import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import Modal_alert from "../../modal";
import Modal_Register_Receipt from "../../modal_register_receipt.js";


function ModifyOrder(props){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("주문서 상세조회/수정");
    const [loading, setLoading] = useState(false);
    const [serviceType, setServiceType]= useState([]);

    const [btnText, setBtntext] = useState("수정");
    
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [pay_show, setPayShow] = useState(false);
    const [pay_complete_show, setPayCompleteShow] = useState(false);
    const [pay_failed_show, setPayFailedShow] = useState(false);
    const [order_cancel_show, setOrderCancelShow] = useState(false);
    const [order_canceled_show, setOrderCanceledShow] = useState(false);
    const [order_cancel_failed_show, setOrderCancelFailedShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [payAmount, setPayAmount] = useState(0);

    const [totalpayed, setTotalPayed] = useState(0);

    const[values, setValues] = useState({order_id :"", id : "", name:"",ordered_date : "", step:10, total_fee: 0,payment_method:"", depositor:"", items:[], payments:[]});

    const [step] = useState({10: "주문완료", 15:"결제대기",20:"결제완료", 25:"크레딧지급대기", 30:"크레딧지급완료", 80:"결제취소", 90:"주문취소"});
    const [method] = useState({cash: "현금/무통장", creditcard:"신용/체크카드", coupon:"쿠폰결제", virtual_account:"가상계좌"});


    const [registerReceiptShow, setRegisterReceiptShow] = useState(false);
    const [receiptData, setReceiptData] = useState({receipt:"{}"}); 
    const [approveCode, setApproveCode] = useState("");
    const [receipt_complete_show, setReceiptCompleteShow] = useState(false);
    const [receipt_failed_show, setReceiptFailedShow] = useState(false);


    const handleChange = e => {
        console.log(e.target.name+": "+e.target.value);
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
      }

    const handleSubmit_pay = (event) => {
        event.preventDefault();
        setPayShow(false);
        payCash();
      };
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/Payplan");
    };
    const handleShow = () => setShow(true);

    
    const getServiceType = () =>{
        axios.get('/rest/admin/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setServiceType(()=>{return response.data});
                if(location.state == null || location.state.mode != 'mod'){
                    setValues({
                        ...values,
                        servicetype: response.data[0].uid,
                    })
                }
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const getOrder = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/getOrder',
        { params : {orderid : location.state.orderid}}).then(response =>{
            let total_payed = 0;
            setValues({order_id : response.data.order_id, id : response.data.id, name:response.data.name,ordered_date : response.data.ordered_date, step:response.data.step, total_fee: response.data.total_fee,payment_method:response.data.payment_method, depositor : response.data.depositor, items:response.data.items, payments:response.data.payments});
            response.data.payments.map(function(item, idx){
                total_payed += item.payment_amount;
            });
            setTotalPayed(total_payed);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const cancelOrder = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/cancelOrder',
        { params : {order_id : location.state.orderid}}).then(response =>{
            if(response.data.result == 'success'){
                setOrderCancelShow(false);
                setOrderCanceledShow(true);
            }else{
                setOrderCancelFailedShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    //TODO : 현금영수증 / 세금계산서 등록
    const registerReceipt = () =>{
        setLoading(()=>{return true});
        axios.post('/rest/admin/registerReceipt',
        { order_id : values.order_id, uid: receiptData.uid, code:approveCode}).then(response =>{
            if(response.data.result == 'success'){
                setReceiptCompleteShow(true);
                getOrder();
            }else{
                setReceiptFailedShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const payCash = () =>{
        setLoading(()=>{return true});
        axios.post('/rest/admin/cashPaymentProcess',
        { order_id : values.order_id, payment_amount: Number(payAmount)}).then(response =>{
            if(response.data.result == 'success'){
                setPayCompleteShow(true);
                getOrder();
            }else{
                setPayFailedShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const setOrderStep = () =>{
        setLoading(()=>{return true});
        axios.post('/rest/admin/setOrderStep',
        { order_id : values.order_id, step: Number(90)}).then(response =>{
            if(response.data.result == 'success'){
                setOrderCancelShow(false);
                setOrderCanceledShow(true);
                getOrder();
            }else{
                setOrderCancelFailedShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    useEffect(() => {}, [values, totalpayed]);
    useEffect(() => {
        getServiceType();
        getOrder();
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={5}>
                                <Form.Group>
                                    <Form.Label>주문번호</Form.Label>
                                    <Form.Control required type="text" name="order_id" defaultValue={values.order_id} readOnly/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={1}>
                                <Form.Group>
                                    <Form.Label>주문단계</Form.Label>
                                    <br/>
                                    <strong>{step[values.step]}</strong>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>주문자명</Form.Label>
                                    <Form.Control  type="text" defaultValue={values.name} readOnly/>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>주문자아이디</Form.Label>
                                    <Form.Control  type="text" defaultValue={values.id} readOnly/>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>주문일시</Form.Label>
                                    <Form.Control  type="text" defaultValue={values.ordered_date} readOnly/>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>결제수단</Form.Label>
                                    <Form.Control  type="text" defaultValue={method[values.payment_method]} readOnly/>
                                </Form.Group>
                            </Col>
                            {values.payment_method == "cash" ?
                                <Col lg={2}>
                                    <Form.Group>
                                        <Form.Label>입금자명</Form.Label>
                                        <Form.Control  type="text" defaultValue={values.depositor} readOnly/>
                                    </Form.Group>
                                </Col>
                                :
                                ""
                            }
                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Label>주문내역</Form.Label>
                                    <Container className="border p-3" style={{overflowX:'Auto'}}>
                                        <Table striped bordered hover size="sm" style={{minWidth:'1000px'}} >
                                            <thead>
                                                <tr>
                                                    <th>플랜이름</th>
                                                    <th>서비스유형</th>
                                                    <th>지급 크레딧</th>
                                                    <th>주문수량</th>
                                                    <th>주문금액</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {values.items.map(function(item, idx){
                                                return(<tr key={idx} className="border-bottom lh-lg">
                                                    <td>
                                                        {item.plan_name}
                                                    </td>
                                                    <td>
                                                        {item.service_name}
                                                    </td>
                                                    <td>
                                                        {item.credit}개
                                                    </td>
                                                    <td className="col-lg-1">
                                                            <strong>{item.ea}</strong>
                                                    </td>
                                                    <td>
                                                        <strong>{item.fee}원</strong>
                                                    </td>
                                                </tr>)
                                            })}
                                            </tbody>
                                        </Table>
                                    </Container>
                                </Form.Group>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>총 주문요금</Form.Label>
                                    <Form.Control required type="number" name="fee" value={values.total_fee} readOnly/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group>
                                        <Form.Label>결제내역</Form.Label>
                                    <Container className="border p-3">
                                        <Table striped bordered hover size="sm" style={{minWidth:'1000px'}} >
                                            <thead>
                                                <tr>
                                                    <th>결제/승인번호</th>
                                                    <th>결제수단</th>
                                                    <th>결제금액</th>
                                                    <th>은행/카드사</th>
                                                    <th>결제일시</th>
                                                    <th>영수증</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {values.payments.map(function(item, idx){
                                                return(<tr key={idx} className="border-bottom lh-lg">
                                                    <td>
                                                        {item.payment_code}
                                                    </td>
                                                    <td>
                                                        {method[item.method]}
                                                    </td>
                                                    <td>
                                                        <strong>{item.payment_amount}</strong>
                                                    </td>
                                                    <td>
                                                        {item.bank}
                                                    </td>
                                                    <td>
                                                        {item.created_date}
                                                    </td>
                                                    <td>
                                                        {
                                                            JSON.parse(item.receipt).status != undefined ? 
                                                                JSON.parse(item.receipt).status == "completed" ?
                                                                    <>
                                                                        <Button>영수증조회</Button>
                                                                    </>
                                                                :
                                                                JSON.parse(item.receipt).status == "requested" ?
                                                                    <>
                                                                        <Button onClick={()=>{setReceiptData(()=>{return item}); setRegisterReceiptShow(true)}}>영수증 발행</Button>
                                                                    </>
                                                                :
                                                                ""
                                                            :
                                                            ""
                                                        }
                                                    </td>
                                                </tr>)
                                            })}
                                            </tbody>
                                        </Table>
                                    </Container>
                                </Form.Group>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>잔여 결제요금</Form.Label>
                                    <br/>
                                    <strong>{values.total_fee - totalpayed}</strong>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/><br/>
                        {values.step < 20 ?
                            <>
                                <Row>
                                    <h6><strong>무통장입금 결제 처리</strong></h6>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group>
                                            <Form.Label>결제금액</Form.Label>
                                            <Form.Control type="number" name="payments_cash" value={payAmount} onChange={(e)=>{setPayAmount(e.target.value)}} placeholder="결제금액 입력"/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Button type="button" className={` btn btn-secondary `}  style={{marginTop:"2rem"}} onClick={()=>{setPayShow(true)}}>결제등록</Button>
                                    </Col>
                                </Row>
                            </>
                            :
                            ""
                        }
                        <Row className="mt-4 justify-content-lg-between">
                            {values.step < 15 ? 
                                <>
                                    <Col className="text-end">
                                        <Button type="button" className={`btn btn-danger`} onClick={()=>{setOrderCancelShow(true)}}>주문취소</Button>
                                    </Col>
                                </>
                                :
                                ""
                            }
                        </Row>
                    </Container>
                </Form>
                <Modal_alert show={pay_show} handleClose={()=>{setPayShow(false)}} handleSubmit={handleSubmit_pay} modal_title="" content={`무통장 결제를 등록하시겠습니까? 결제금액 : ${payAmount}`} secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={pay_complete_show} handleClose={()=>{setPayCompleteShow(false); setPayAmount(0); }} modal_title="결제등록완료" content="무통장 결제가 등록되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={pay_failed_show} handleClose={()=>{setPayFailedShow(false);}} modal_title="결제등록실패" content="무통장 결제에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={order_cancel_show} handleClose={()=>{setOrderCancelShow(false);}} handleSubmit={()=>{cancelOrder()}}  modal_title="주문취소" content="주문을 취소하시겠습니까?"  secondary={true} primary_btn={primary_btn} />
                <Modal_alert show={order_canceled_show} handleClose={()=>{setOrderCanceledShow(false);getOrder();}} modal_title="주문취소완료" content="주문이 취소되었습니다." primary_btn={primary_btn} />
                <Modal_alert show={order_cancel_failed_show} handleClose={()=>{setOrderCancelFailedShow(false);}} modal_title="주문취소실패" content="주문취소에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
                <Modal_alert show={receipt_complete_show} handleClose={()=>{setReceiptCompleteShow(false); setReceiptData({receipt:"{}"}); setApproveCode(""); }} modal_title="영수증 등록완료" content="영수증이 등록되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={receipt_failed_show} handleClose={()=>{setReceiptFailedShow(false);setReceiptData({receipt:"{}"}); setApproveCode("");}} modal_title="영수증 등록실패" content="영수증 등록에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_Register_Receipt receiptData={receiptData} setReceiptData={setReceiptData} approveCode={approveCode} setApproveCode={setApproveCode} show={registerReceiptShow} handleClose={()=>{setRegisterReceiptShow(false)}} modal_title="영수증 등록" content="현금영수증/세금계산서를 등록해주세요." handleSubmit={()=>{registerReceipt();}} secondary="닫기" primary_btn="등록"/>
            </div>
        </>
        );

}

export default ModifyOrder;