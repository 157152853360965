import React from "react";
import { useState } from "react";
import { useRef } from "react";
import {Row, Col, Container, ListGroup, Card, Table, Navbar, Nav } from "react-bootstrap";
import Docs from "./docs";

function Docs_TTS(){

    const [mobileExpanded, setMobileExpanded] = useState(false);

    const userauth = useRef(null);
    const korean = useRef(null);
    const ttslog = useRef(null);
    const getapicredit =useRef(null);
    const refreshtoken = useRef(null);
    const responselist = useRef(null);

    const userauthClick = ()=>{userauth.current?.scrollIntoView({behavior: 'smooth'})};
    const koreanClick = ()=>{korean.current?.scrollIntoView({behavior: 'smooth'})};
    const ttslogClick = ()=>{ttslog.current?.scrollIntoView({behavior: 'smooth'})};
    const getapicreditClick = ()=>{getapicredit.current?.scrollIntoView({behavior: 'smooth'})};
    const refreshtokenClick = ()=>{refreshtoken.current?.scrollIntoView({behavior: 'smooth'})};
    const responselistClick = ()=>{responselist.current?.scrollIntoView({behavior: 'smooth'})};


    return(
        <>
        <Navbar expanded={mobileExpanded} expand="lg" bg="light" fixed="bottom" className="shadow-sm d-lg-none" id="mainNav">
                <Container className="px-5">
                    <Navbar.Toggle type="button" className="w-100" onClick={()=>{setMobileExpanded(!mobileExpanded)}} aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        Quick Menu
                    </Navbar.Toggle>
                    <Navbar.Collapse id="navbarResponsive">
                        <Nav className=" ms-auto me-4 my-3 my-lg-0">
                            <Nav.Item className="fw-bold">공통</Nav.Item>
                            <Nav.Item onClick={()=>{userauthClick(); setMobileExpanded(false)}}>사용자인증</Nav.Item>
                            <Nav.Item className="fw-bold">음성합성 AI (REST API)</Nav.Item>
                            <Nav.Item onClick={()=>{koreanClick(); setMobileExpanded(false)}}>korean(한국어)</Nav.Item>
                            <Nav.Item onClick={()=>{ttslogClick(); setMobileExpanded(false)}}>TTS Log 확인</Nav.Item>
                            <Nav.Item onClick={()=>{getapicreditClick(); setMobileExpanded(false)}}>잔여 크레딧 확인</Nav.Item>
                            <Nav.Item onClick={()=>{refreshtokenClick(); setMobileExpanded(false)}}>서비스토큰 갱신</Nav.Item>
                            <Nav.Item onClick={()=>{responselistClick(); setMobileExpanded(false)}}>응답코드 목록</Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        <div className="px-lg-5 mx-lg-5">
            
            <Row>
                <Col lg={2} className="d-lg-block d-none">
                    <ListGroup variant="flush" style={{position:"sticky", top:"70px"}}>
                        <ListGroup.Item className="fw-bold fs-6">공통</ListGroup.Item>
                        <ListGroup.Item action onClick={userauthClick} className="ps-4 fs-6">사용자인증</ListGroup.Item>
                        <ListGroup.Item className="fw-bold fs-6">음성합성 AI (REST API)</ListGroup.Item>
                        <ListGroup.Item action onClick={koreanClick} className="ps-4 fs-6">korean(한국어)</ListGroup.Item>
                        <ListGroup.Item action onClick={ttslogClick} className="ps-4 fs-6">TTS Log 확인</ListGroup.Item>
                        <ListGroup.Item action onClick={getapicreditClick} className="ps-4 fs-6">잔여 크레딧 확인</ListGroup.Item>
                        <ListGroup.Item action onClick={refreshtokenClick} className="ps-4 fs-6">서비스토큰 갱신</ListGroup.Item>
                        <ListGroup.Item action onClick={responselistClick} className="ps-4 fs-6">응답코드 목록</ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col lg={10} className="ps-lg-4">
                    <Row>
                        <div className="fw-bold fs-3 p-0">음성합성 AI 도움말</div>
                    </Row>
                    <Row ref={userauth} style={{paddingTop:"4rem"}}>
                        <Row className="fs-3 mb-4 fw-bold">사용자인증</Row>
                        <Row className="m-auto">
                            <Col lg={12}>
                                <p>NAMISNT AI의 모든 API는 공통된 사용자 인증 및 토큰 갱신 프로세스를 가지고 있습니다.</p>
                                <p>서비스를 이용하기 위해서는 아래의 정보가 Request Header에 포함되어 있어야 합니다.</p><br/>
                                <p>Authorization : Bearer [ServiceToken]</p>
                                <p>X-namiai-version : [사용하고자 하는 API의 버전]</p>
                            </Col>
                        </Row>
                    </Row>
                    <Row ref={korean} style={{paddingTop:"2rem"}}>
                        <Row className="fs-3 mb-4 mt-5 fw-bold">korean(한국어)</Row>
                        <Row className="m-auto">
                            <Col lg={6} className="pe-4">
                                <p>음성합성 AI의 korean(한국어)기능은 아래의 정보를 통해 사용이 가능합니다.</p><br/>
                                <p>Request URL : https://api1.namisnt.com/api/ttsai/tts/korean</p>
                                <p>Request Method : POST</p><br/>
                                <p>[Request Parameters]</p>
                                <p>text : 음성으로 변환할 텍스트 데이터</p>
                            </Col>
                            <Col lg={6} className="p-0">
                                <Row>
                                    <Card bg="dark" text="white" className="px-0">
                                        <Card.Header className="bg-secondary">Request</Card.Header>
                                        <Card.Body>
                                            <p className="fw-bold" style={{fontSize:"0.8rem", color:"#dedede"}}>
                                                curl -X POST \
                                                <span style={{color:"#add089"}}>"https://api1.namisnt.com/api/ttsai/tts/korean"</span> \<br/>
                                                -H <span style={{color:"#add089"}}>"Content-Type: application/x-www-form-urlencoded"</span> \<br/>
                                                -H <span style={{color:"#add089"}}>"Authorization: Bearer [ServiceToken]"</span> \<br/>
                                                -H <span style={{color:"#add089"}}>"X-namiai-version: [API Version]</span><br/>
                                                -d<span style={{color:"#add089"}}> "text=[Text Data to Speech]"</span><br/>
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Row>
                                <Row className="mt-4">
                                    <Card bg="dark" text="white" className="px-0">
                                        <Card.Header className="bg-secondary">Response</Card.Header>
                                        <Card.Body>
                                            <p className="fw-bold" style={{fontSize:"0.8rem", color:"#dedede"}}>
                                                Binary(audio/mp3 Download)
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                    <Row ref={ttslog} style={{paddingTop:"4rem"}}>
                        <Row className="fs-3 mb-4 mt-5 fw-bold">TTS Log 확인</Row>
                        <Row className="m-auto">
                            <Col lg={6} className="pe-4">
                                <p>음성합성 AI의 TTS API 호출 후 Progress Log확인은 아래의 정보를 통해 확인이 가능합니다.</p><br/>
                                <p>Request URL : https://api1.namisnt.com/api/ttsai/ttsai/getBatchLog?uid=[Progress Log ID]</p>
                                <p>Request Method : GET</p><br/>
                                <p>[Request Parameters]</p>
                                <p>uid : Progress Log ID</p>
                            </Col>
                            <Col lg={6} className="p-0">
                                <Row>
                                    <Card bg="dark" text="white" className="px-0">
                                        <Card.Header className="bg-secondary">Request</Card.Header>
                                        <Card.Body>
                                            <p className="fw-bold" style={{fontSize:"0.8rem", color:"#dedede"}}>
                                                curl -X GET \
                                                <span style={{color:"#add089"}}>"https://api1.namisnt.com/api/ttsai/tts/getBatchLog?uid=[Progress Log ID]"</span> \<br/>
                                                -H <span style={{color:"#add089"}}>"Authorization: Bearer [ServiceToken]"</span> \<br/>
                                                -H <span style={{color:"#add089"}}>"X-namiai-version: [API Version]</span><br/>
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Row>
                                <Row className="mt-4">
                                    <Card bg="dark" text="white" className="px-0">
                                        <Card.Header className="bg-secondary">Response</Card.Header>
                                        <Card.Body>
                                            <p className="fw-bold" style={{fontSize:"0.8rem", color:"#dedede"}}>
                                                &#123;<br/>
                                                <span className="ps-3">"data":</span><br/>
                                                &#123;<br/>
                                                <span className="ps-5">"download_link":</span><span style={{color:"#add089"}}>"https://apifile.namisnt.com/tts/2/[filename].mp3"</span>, <br/>
                                                <span className="ps-5">"log":</span><span style={{color:"#add089"}}>[Progress Log]</span>, <br/>
                                                <span className="ps-5">"progress":</span><span style={{color:"#add089"}}>[Progress Percentage]</span>, <br/>
                                                <span className="ps-5">"start_date":</span><span style={{color:"#add089"}}>"Wed, 26 Jul 2023 22:39:56 GMT"</span>, <br/>
                                                &#125;<br/>
                                                <span className="ps-5">"status":</span><span style={{color:"#add089"}}>"finished"</span> <br/>
                                                <span className="ps-3">"result":</span><span style={{color:"#add089"}}>200</span><br/>
                                                &#125;
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                    <Row ref={getapicredit} style={{paddingTop:"4rem"}}>
                        <Row className="fs-3 mb-4 fw-bold">잔여 크레딧 확인</Row>
                        <Row className="m-auto">
                            <Col lg={6} className="pe-4">
                                <p>음성합성 AI의 잔여 크레딧은 아래의 정보를 통해 확인할 수 있습니다.</p><br/>
                                <p>Request URL : https://api1.namisnt.com/api/ttsai/getApiCredit</p>
                                <p>Request Method : POST</p><br/>
                                <p>[Request Parameters]</p>
                                <p>해당 무</p>
                            </Col>
                            <Col lg={6} className="p-0">
                                <Row>
                                    <Card bg="dark" text="white" className="px-0">
                                        <Card.Header className="bg-secondary">Request</Card.Header>
                                        <Card.Body>
                                            <p className="fw-bold" style={{fontSize:"0.8rem", color:"#dedede"}}>
                                                curl -X POST \
                                                <span style={{color:"#add089"}}>"https://api1.namisnt.com/api/ttsai/getApiCredit"</span> \<br/>
                                                -H <span style={{color:"#add089"}}>"Authorization: Bearer [ServiceToken]"</span> \<br/>
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Row>
                                <Row className="mt-4">
                                    <Card bg="dark" text="white" className="px-0">
                                        <Card.Header className="bg-secondary">Response</Card.Header>
                                        <Card.Body>
                                            <p className="fw-bold" style={{fontSize:"0.8rem", color:"#dedede"}}>
                                                &#123;<br/>
                                                <span className="ps-3">"remained" : </span><span style={{color:"#add089"}}>[Size of Remained Credits]</span>, <br/>
                                                <span className="ps-3">"result": </span><span style={{color:"#add089"}}>200</span><br/>
                                                &#125;
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                    <Row ref={refreshtoken} style={{paddingTop:"4rem"}}>
                        <Row className="fs-3 mb-4 mt-5 fw-bold">서비스토큰 갱신</Row>
                        <Row className="m-auto">
                            <Col lg={6} className="pe-4">
                                <p>음성합성 AI의 서비스토큰 갱신은 아래의 정보를 통해 진행할 수 있습니다.</p><br/>
                                <p>Request URL : https://api1.namisnt.com/api/ttsai/refreshToken</p>
                                <p>Request Method : POST</p><br/>
                                <p>[Request Parameters]</p>
                                <p>해당 무</p>
                            </Col>
                            <Col lg={6} className="p-0">
                                <Row>
                                    <Card bg="dark" text="white" className="px-0">
                                        <Card.Header className="bg-secondary">Request</Card.Header>
                                        <Card.Body>
                                            <p className="fw-bold" style={{fontSize:"0.8rem", color:"#dedede"}}>
                                                curl -X POST \
                                                <span style={{color:"#add089"}}>"https://api1.namisnt.com/api/ttsai/refreshToken"</span> \<br/>
                                                -H <span style={{color:"#add089"}}>"Authorization: Basic [base64encoded(Client_ID:ServiceSecret)]"</span> \<br/>
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Row>
                                <Row className="mt-4">
                                    <Card bg="dark" text="white" className="px-0">
                                        <Card.Header className="bg-secondary">Response</Card.Header>
                                        <Card.Body>
                                            <p className="fw-bold" style={{fontSize:"0.8rem", color:"#dedede"}}>
                                                &#123;<br/>
                                                <span className="ps-3">"date" : </span><span style={{color:"#add089"}}>[Refreshed Date]</span>, <br/>
                                                <span className="ps-3">"result": </span><span style={{color:"#add089"}}>200</span><br/>
                                                <span className="ps-3">"service_token" : </span><span style={{color:"#add089"}}>[Refreshed Service Token]</span>, <br/>
                                                &#125;
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                    <Row ref={responselist} style={{paddingTop:"4rem"}}>
                        <Row className="fs-3 mb-4 mt-5 fw-bold">응답코드 목록</Row>
                        <Row className="m-auto">
                            <p>이미지 배경제거 AI의 응답코드는 아래와 같습니다.</p><br/>
                            <Table striped bordered responsive style={{width:"1345px"}}>
                                <thead>
                                    <tr>
                                        <th style={{minWidth:"6rem"}}>응답코드</th>
                                        <th style={{minWidth:"13rem"}}>응답분류</th>
                                        <th>응답설명</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>200</strong>
                                        </td>
                                        <td>
                                            정상처리
                                        </td>
                                        <td>요청이 정상적으로 처리되었을 때 반환되는 코드입니다. 크레딧이 차감됩니다.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>420</strong>
                                        </td>
                                        <td>
                                            서비스 시크릿 불일치
                                        </td>
                                        <td>서비스 시크릿이 불일치할 경우 반환되는 코드입니다. 처리가 진행되지 않습니다.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>421</strong>
                                        </td>
                                        <td>
                                            서비스 토큰 불일치
                                        </td>
                                        <td>서비스 토큰이 불일치할 경우 반환되는 코드입니다. 처리가 진행되지 않습니다.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>422</strong>
                                        </td>
                                        <td>
                                            API 버전 이상
                                        </td>
                                        <td>잘못된 API 버전이 요청된 경우 반환되는 코드입니다.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>423</strong>
                                        </td>
                                        <td>
                                            서비스 토큰 만료
                                        </td>
                                        <td>서비스 토큰이 만료된 경우 반환되는 코드입니다. 서비스 토큰의 유효기간은 14일이며, 그 이전까지 갱신하지 않을 경우 서비스 토큰이 만료되어 서비스 이용이 불가합니다.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>425</strong>
                                        </td>
                                        <td>
                                            잔여 크레딧 부족
                                        </td>
                                        <td>잔여 크레딧이 부족할 경우 반환되는 코드입니다. 추가 결제를 하여 다른 주문서에 크레딧이 남아있다 하더라도, 유효기간이 가장 빠른 크레딧을 모두 소진하여야 이용이 가능합니다.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>426</strong>
                                        </td>
                                        <td>
                                            크레딧 정보 없음
                                        </td>
                                        <td>크레딧 정보가 없을 경우 반환되는 코드입니다. 플랜을 구입하여 크레딧이 충전되면 이용이 가능합니다.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>427</strong>
                                        </td>
                                        <td>
                                            크레딧 활성화 오류
                                        </td>
                                        <td>잔여 크레딧이 있으나, 크레딧이 활성화되지 않은 경우 반환되는 코드입니다. 고객센터에 문의해주시기 바랍니다.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>429</strong>
                                        </td>
                                        <td>
                                            잘못된 요청
                                        </td>
                                        <td>잘못된 데이터가 요청된 경우 반환되는 코드입니다. 서비스 토큰, API 버전, 요청 데이터를 다시한번 확인해주세요.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>430</strong>
                                        </td>
                                        <td>
                                            처리 실패
                                        </td>
                                        <td>처리에 실패한 경우 반환되는 코드입니다. 크레딧이 차감되지 않습니다.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>602</strong>
                                        </td>
                                        <td>
                                            최대 문자열 길이 초과
                                        </td>
                                        <td>1회당 최대 길이를 초과하였을 때 반환되는 코드입니다. 작업이 시작되지 않으며, 1회 최대 지원 문자열길이는 200자(공백, 특수문자 제외)입니다.</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </div>
        </>
    );

}

export default Docs_TTS;