import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

function Demo_Banner(){
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          준비중입니다.. 조금만 기다려 주세요!
        </Tooltip>
      );
    return(
        <div className="section-content demo-banner">
            <p className="h1 text-light demo-banner-title">무료버전은 조금 아쉽지 않으세요?</p>
            <p className="h6 text-light pt-4">정기구독하고 다양한 기능을 만나보세요!</p>
            <div className="d-flex flex-column flex-lg-row align-items-center">
                <Link className="m-auto mb-4 mb-lg-0 pt-3" to="/subscribe">
                    <div className="bg-black border-light text-light" style={{width:"12rem", height:"4rem", textAlign:"center",lineHeight:"4rem", border:"2px solid #FFFFFF", borderRadius:"10px", fontSize:"1.5rem", backgroundColor:"#0F0F0F"}}>
                        구독/플랜
                    </div>
                </Link>
            </div>
        </div>

    );

}

export default Demo_Banner;