import React from "react";
import '../../../css/demo.css';

function DEMO_EXPLAIN(){

    return (
        <section className="section-content p-3  my-5 text-left">
            <strong className="h3 m-3 dtitle">AI DEMO 사용안내</strong>
            <div className="mt-3">
                <ul className="list-group">
                    <li className="list-group-item">
                        <span>본 인공지능 데모는 플랜 구독/프리페이드 결제 전 시험용으로 사용할 수 있습니다.</span>
                    </li>
                    <li className="list-group-item">
                        <span>맞춤형서비스가 필요하신경우 ns416@namisnt.com으로 관련내용을 보내주시기 바랍니다.</span>
                    </li>
                </ul>
            </div>
        </section>
    );

}

export default DEMO_EXPLAIN;