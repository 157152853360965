import React from "react";

import Header from "./header.js"
import Footer from "./footer.js"

import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import LoginModal from "./Login_modal.js";
function MainLayout(){
    const location = useLocation();
    const navigate = useNavigate();
    const [loginShow, setLoginShow] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [topBannerShow, setTopBannerShow] = useState(false);
    const getUserInfo = () =>{
        axios.get('/sso/rest/getUserInfo',{headers:{'Referer_pass':document.referrer}}).then(response => {
            if(response.data.logged === false || response.data == ''){
                setIsLogged(false);
            }else{
                setIsLogged(true);
                setUserInfo(response.data);
            }
        }).catch(
            error=>{
                //navigate("/");
                //console.log(error);
            }
        );
    }
    const Logout_func = () =>{
        axios.get('/sso/rest/logout').then(response => {
            if(response.data.result == 'success'){
                setIsLogged(false);
                setUserInfo({});
                navigate("/");
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    useEffect(()=>{getUserInfo()}, [isLogged])
    return (
        <>
            <Header setTopBannerShow={setTopBannerShow} topBannerShow={topBannerShow} isLogged={isLogged} loginShow={loginShow} setLoginShow={setLoginShow} userInfo={userInfo} getUserInfo={getUserInfo} Logout_func={Logout_func}/>
            <div  className={topBannerShow == true ? "top-section-banner" : location.pathname=='/index' ? "" : "top-section"} >
                <Outlet context={{isLogged:isLogged, loginShow:loginShow, setLoginShow:setLoginShow, userInfo:userInfo}}/>
            </div>
            <Footer/>
            <LoginModal show={loginShow} setLoginShow={setLoginShow} setIsLogged={setIsLogged} handleClose={()=>{setLoginShow(false)}}/>
        </>
    );
}

export default MainLayout;