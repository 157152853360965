import React from "react";
import { Container } from "react-bootstrap";

function Intro_BGRM(){

    return(
        <>
            <Container className="mt-3">
                <h4 className="fw-bold">- 이미지 배경제거 AI는 무엇인가요?</h4>
                <div className="ps-lg-4 lh-lg text-secondary">
                    이미지 배경제거 AI는 사진과 같은 이미지에서 특정물체의 윤곽선을 인식하여 피사체만 남기고 배경을 제거하는 인공지능입니다.<br/>
                    흔히 이야기하는 '누끼 따기' 작업을 말합니다.<br/><br/>
                    나미에스엔티 AI의 이미지 배경제거 AI는 뛰어난 성능으로 주어진 이미지에서 배경을 제거합니다.
                    <br/>
                    또한, REST API와 웹페이지에서 사용이 가능한 웹 API를 지원하여 다양한 방법의 사용이 가능합니다.
                    <img className="m-auto d-block w-100 w-md-60" src={process.env.PUBLIC_URL + '/assets/img/bgrm_intro1.png'} />
                </div>
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- 이미지 배경제거 AI의 기능 소개</h4>
                <h5 className="ps-lg-4 mt-3">1. 단일 이미지(singleImg)</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                    한 장의 이미지에 대한 배경제거 작업을 수행하는 API입니다.
                </div>
                <img className="m-auto d-block w-100 w-md-60" src={process.env.PUBLIC_URL + '/assets/img/bgrm_intro2.png'} />
                <img className="m-auto d-block w-100 w-md-60" src={process.env.PUBLIC_URL + '/assets/img/bgrm_intro2-1.png'} />
                <h5 className="ps-lg-4 mt-3">2. 여러 이미지(batchImg)</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                    여러 장의 이미지가 압축된 압축파일을 입력받아, 한번에 여러 장의 이미지에 대해 배경제거 작업을 수행하는 API입니다.
                </div>
                <img className="m-auto d-block my-3 w-100 w-md-60" src={process.env.PUBLIC_URL + '/assets/img/bgrm_intro3.png'} />
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- 이미지 배경제거 AI만의 강점</h4>
                <h5 className="ps-lg-4 mt-3">1. 빠르고 안정적인 서비스</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                    나미에스엔티 AI에서 제공하는 이미지 배경제거 AI는 풍부한 컴퓨팅 서버 자원을 기반으로 빠르고 안정적인 서비스를 제공합니다.
                </div>
                <h5 className="ps-lg-4 mt-3">2. 고품질의 배경제거 이미지</h5>
                <div className="ps-lg-5 lh-lg text-secondary">
                    나미에스엔티에서 제공하는 이미지 배경제거 AI는 인공신경망을 기반으로 하는 인공지능 모델이 윤곽선을 인식하여 이미지의 배경을 제거합니다.<br/>
                    기존과 같이 수작업으로 배경을 제거하거나, 윤곽선 인식 능력이 다소 떨어지는 프로그램을 사용하지 않아도 됩니다.<br/>
                    또한, 자동으로 안티앨리어싱(Anti-Aliasing)이 적용되어 부드러운 테두리가 있는 깔끔한 결과물을 확인하실 수 있습니다.
                </div>
                <img className="m-auto d-block my-3 w-100 w-md-60" src={process.env.PUBLIC_URL + '/assets/img/bgrm_intro4.png'} />
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- API 버전 소개</h4>
                <div className="ps-lg-4 lh-lg text-secondary">
                2023-04-25 : U2Net 기반 신경망 인공지능이 이미지의 배경을 제거합니다.<br/>
                2023-08-23 : 사람의 신체가 피사체인 이미지의 배경을 제거하는데 적합합니다.<br/>
                2024-01-08[권장] : 통계적 알고리즘이 사용된 고성능 배경제거 모델을 사용합니다. 주로 인물 사진에 적합합니다.<br/>
                </div>
                <img className="m-auto d-block my-3 w-100 w-md-60" src={process.env.PUBLIC_URL + '/assets/img/bgrm_intro5.png'} />
            </Container>
            <Container className="mt-3">
                <h4 className="fw-bold">- 문제가 생겼을 때에는 어떡하나요?</h4>
                <div className="ps-lg-4 lh-lg text-secondary">
                    나미에스엔티에서 제공하는 이미지 배경제거 AI가 오작동하였을 때, 확인 후 차감된 크레딧을 반환받으실 수 있습니다.<br/>
                    (결과물이 나오지 않았는데 크레딧이 차감된 경우, 제거를 원하지 않는 부분이 제거되었을 경우 등)<br/>
                </div>
            </Container>
        </>
    );

}

export default Intro_BGRM;