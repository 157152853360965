import React, {useState, useEffect} from "react";
import { Card, Form, Button, Col } from "react-bootstrap";

function ListSearch(props){
    const goSearch = () =>{
        props.goSearch(props.search_kind, props.query, props.service_uid);
    }

    const handleKind = (e) => {
        props.setSearchKind(e.target.value);
    }
    const handleQuery = (e) => {
        props.setQuery(e.target.value)
    }

    return (
        <>
            <Card.Header className="py-3 border-0 align-items-center justify-content-between bg-transparent px-5 text-end">
                <Form.Group className="m-0 form-inline right-menu px-0 d-lg-block d-lg-inline-flex d-none">
                    <Col lg={5}  className="me-1">
		        	    <Form.Select id="opt-cls" name="search_kind" onChange={handleKind}>
      	        	    	{props.filter.map(function(item, idx){
                                return(
                                <option key={idx} value={item.val}>{item.name}</option>
                                );
                            })}
    	        	    </Form.Select>
                    </Col>
		        	 <div className="me-lg-4 d-inline-flex align-middle">
		        	 <Form.Control type="text" onChange={handleQuery} className="me-1 datepicker col-10 col-lg-9 d-inline mr-lg-1" name="query" id="search-stu" placeholder="검색"/>  
		        	 <Button variant="secondary" onClick={goSearch} className="px-0 col-2 col-lg-4" id="search">조회</Button>
		        	</div>
                </Form.Group>
            </Card.Header>
        </>
    );

}

export default ListSearch;