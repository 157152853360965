import React from "react";
import { Form, InputGroup, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { useEffect } from "react";

function Modal_Register_Receipt(props){

  const [receiptType] = useState({cashReceipt : "현금영수증", tax:"세금계산서"});
  const [cashReceiptType] = useState({person : "개인소득공제", business:"사업자지출증빙"});
  const [cashReceiptCodeType] = useState({person : "휴대전화번호", business:"사업자등록번호"});
  const [json_data, setJSONDATA] = useState("");
  useEffect(()=>{if(props.receiptData!= undefined) setJSONDATA(JSON.parse(props.receiptData.receipt))}, [props.receiptData]);
    return (
        <>
            <Modal
              show={props.show}
              onHide={props.handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{props.modal_title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {props.content}
                <Row className="mt-3">
                    <Form.Label>요청 구분</Form.Label>
                    <InputGroup>
                      <Form.Control required type="text" name="receipt_type" defaultValue={receiptType[json_data.type]} readOnly/>
                    </InputGroup>
                  </Row>
                {
                  json_data.type == 'cashReceipt' ?
                <>
                  <Row className="mt-3">
                    <Form.Label>영수증 구분</Form.Label>
                    <InputGroup>
                      <Form.Control required type="text" name="cashreceipt_type" defaultValue={cashReceiptType[json_data.cashreceiptType]} readOnly/>
                    </InputGroup>
                  </Row>
                  <Row className="mt-3">
                    <Form.Group>
                        <Form.Label>{`${cashReceiptCodeType[json_data.cashreceiptType]}`}</Form.Label>
                        <Form.Control required type="text" name="cashreceiptcode" defaultValue={json_data.code} readOnly/>
                    </Form.Group>
                  </Row>
                  <Row className="mt-3">
                    <Form.Group>
                        <Form.Label>승인번호 입력</Form.Label>
                        <Form.Control required type="text" name="approveCode" defaultValue={props.approveCode} onChange={(e)=>{props.setApproveCode(e.target.value)}} placeholder="승인번호를 입력해주세요."/>
                    </Form.Group>
                  </Row>
                </>
                :
                json_data.type == 'tax' ?
                <>
                </>
                :
                ""
                }
              </Modal.Body>
              <Modal.Footer>
              {props.secondary && <Button variant="secondary" onClick={()=>{props.setReceiptData(); props.handleClose();}}>
                  닫기
                </Button>}
                <Button variant="primary" onClick={!(props.secondary && props.handleSubmit) ? props.handleClose : ()=>{props.setReceiptData(); props.handleClose();props.handleSubmit();}} >{props.primary_btn}</Button>
              </Modal.Footer>
            </Modal>
        </>
      );

}

export default Modal_Register_Receipt;