import React from "react";
import { useState } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Container, Form, InputGroup, ListGroup, Modal, Nav, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import Loading from "../../../common/Loading";
import Modal_alert from "../../../common/modal";
import Status_view from "./modules/status_view";
import Modal_Password_alert from "../../../common/modal_password";

function ModifyInfo(){

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    //const [isBusiness, setIsbusiness] = useState(location.state.isbusiness);
    const [values, setValues] = useState({name:"", nickname:"", id:"", pw:"", pw_cfm:"", email:"", phonenumber1:"", phonenumber2:"", sns_type:null});
    const [validated, setValidated] = useState(false);
    const [modify_complete_show, setModifyCompleteShow] = useState(false);
    const [modify_failed_show, setModifyFailedShow] = useState(false);
    const [delete_pw_show, setDeletePWShow] = useState(false);
    const [secret_pw, setSecretPw] = useState("");
    const [delete_complete_show, setDeleteCompleteShow] = useState(false);

    const [sns_delete_show, setSNSDeleteShow] = useState(false);
    

    const [nickname_check, setNicknameCheck] = useState(true);
    const [pw_check, setPWcheck] = useState(true);
    const [pw_cfm_check, setPWCFMcheck] = useState(true);
    const [pn1_check, setPN1check] = useState(true);

    const [nickname_valid, setNicknameValid] = useState(false);
    const [pw_valid, setPWValid] = useState(false);
    const [pw_cfm_valid, setPWCFMValid] = useState(false);
    const [pn1_valid, setPN1Valid] = useState(false);

    const pw_delete_myinfo_handleSubmit = e=>{
        setDeletePWShow(false);
        setLoading(true);
        axios.post('/sso/rest/delete_account',
        { 
            key:location.state.key
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("요청 오류가 발생하였습니다.");
            }else{
                if(response.data.result == 'success'){
                    setDeleteCompleteShow(true);
                }else{
                    alert("요청 오류가 발생하였습니다.");
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                console.log(error);
                return false;
            }
        );
    }

    const handleNicknameChange = e => {
        setNicknameValid(false);
        if(e.target.value == "" || e.target.value.length >5){
            setNicknameCheck(false);
        }else{
            setNicknameCheck(true);
        }
        handleChange(e);
    }
    const handlePWChange = e => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
        if(e.target.value == "" || !passwordRegex.test(e.target.value) ){
            setPWcheck(false);
            setPWValid(false);
        }else{
            setPWcheck(true);
            setPWValid(true);
        }
        handleChange(e);
    }
    const handlePWCfmChange = e => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
        if(e.target.value == "" || !passwordRegex.test(e.target.value)  || values.pw !== e.target.value){
            setPWCFMcheck(false);
            setPWCFMValid(false);
        }else{
            setPWCFMcheck(true);
            setPWCFMValid(true);
        }
        handleChange(e);
    }
    const handlePN1Change = e => {
        setPN1Valid(false);
        handleChange(e);
    }
    const handleChange = e => {
        console.log(e.target.name+" : "+e.target.value);
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
    }

    const checkDuplicaton = (type, value)=>{
        setLoading(true);
        axios.get('/sso/rest/checkDuplication',{params:
            {
                type:type,
                value:value
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                if(response.data.result == "success"){
                    if(type == "nickname"){
                        setNicknameValid(true);
                        setNicknameCheck(true);
                    }else if(type=="phonenumber1"){
                        setPN1Valid(true);
                        setPN1check(true)
                    }
                }else{
                    if(type == "nickname"){
                        setNicknameCheck(false);
                        setNicknameValid(false);
                    }else if(type=="phonenumber1"){
                        setPN1check(false);
                        setPN1Valid(false);
                    }
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                alert("중복확인에 실패하였습니다. 잠시후 다시 시도해주세요");
                console.log(error);
            }
        );
    }

    const requestInfo = ()=>{
        setLoading(true);
        axios.post('/sso/rest/myinfo_view',
        { 
            key:location.state.key,
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("요청 오류가 발생하였습니다.");
            }else{
                if(response.data.result == 'success'){
                    setValues(response.data.data);
                }else{
                    alert("요청 오류가 발생하였습니다.");
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                console.log(error);
                return false;
            }
        );
    }

    const requestModify = ()=>{
        setLoading(true);
        axios.post('/sso/rest/myinfo_update',
        { 
            nickname : values.nickname,
            pw : values.pw,
            pw_cfm : values.pw_cfm,
            phonenumber1 : values.phonenumber1,
            phonenumber2 : values.phonenumber2,
            key:location.state.key,
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("요청 오류가 발생하였습니다.");
            }else{
                if(response.data.result == 'success'){
                    setModifyCompleteShow(true);
                }else{
                    setModifyFailedShow(true);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                console.log(error);
                return false;
            }
        );
    }

    useEffect(()=>{
        if ( values.nickname.length >0 && nickname_check && (values.sns_type == null && (values.pw != undefined && values.pw.length > 0 &&  pw_check && values.pw_cfm != undefined && values.pw_cfm.length > 0 && pw_cfm_check) || values.sns_type != null) && pn1_check)
            setValidated(true);
        else
            setValidated(false);
    }, [values]);

    useEffect(()=>{
        requestInfo();
    }, [])

    return(
        <>
            {loading == true ? <Loading/> : ""}
            <section className="section-content" style={{ paddingBottom:"0"}}>
                <Status_view setLoading={setLoading} mypagebtn={false}/>
                <Breadcrumb className='mt-3'>
                    <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                    <Breadcrumb.Item href="/mypage/dashboard">
                        마이페이지
                    </Breadcrumb.Item>
                    <BreadcrumbItem active>회원정보수정</BreadcrumbItem>
                </Breadcrumb>
            </section>
            <section className="sections-white py-5" style={{marginTop:"1rem"}}>
                <Container className=" text-center">
                    <strong className="h5 fw-bold">회원정보수정</strong>
                    <div className="h6 m-4">회원정보를 입력해주세요</div>
                </Container>
                <Form noValidate className="mw-100 m-auto" style={{width:"400px"}}>
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>아이디</Form.Label>
                            <InputGroup>
                                <Form.Control type="text" name="id" value={values.sns_type == null ? values.id:"소셜계정으로 로그인되었습니다."} readOnly/>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    {
                        values.sns_type == null ?
                        <>
                            <Row className="mt-3">
                                <Form.Group>
                                    <Form.Label>비밀번호</Form.Label>
                                    <Form.Control isValid={pw_valid} isInvalid={!pw_check} required type="password" name="pw" value={values.pw || ""} onChange={handlePWChange} placeholder="사용하실 비밀번호를 입력해주세요."/>
                                    <Form.Control.Feedback type="invalid">
                                        8자이상, 특수문자가 포함된 비밀번호를 입력해주세요.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="valid">좋은 비밀번호입니다 :)</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mt-3">
                                <Form.Group>
                                    <Form.Label>비밀번호확인</Form.Label>
                                    <Form.Control isValid={pw_cfm_valid} isInvalid={!pw_cfm_check} required type="password" name="pw_cfm" value={values.pw_cfm  || ""} onChange={handlePWCfmChange} placeholder="비밀번호를 다시 입력해주세요."/>
                                    <Form.Control.Feedback type="invalid">
                                        비밀번호와 확인이 일치하지 않습니다.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="valid">두 비밀번호가 모두 일치합니다 :D</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </>
                        :
                        ""
                    }
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>이름</Form.Label>
                            <Form.Control type="text" name="name" value={values.name} readOnly/>
                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>닉네임</Form.Label>
                            <InputGroup>
                                <Form.Control isValid={nickname_valid} isInvalid={!nickname_check} required type="text" name="nickname" value={values.nickname} onChange={handleNicknameChange} placeholder="사용하실 닉네임을 입력해주세요."/>
                                <Button variant="light" onClick={(e)=>{checkDuplicaton("nickname", values.nickname)}}>중복확인</Button>
                                <Form.Control.Feedback type="invalid">
                                    닉네임이 중복되거나 올바르지 않습니다. 올바른 닉네임을 입력해주세요.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback>중복확인 되었습니다 :)</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>이메일주소</Form.Label>
                            <InputGroup>
                                <Form.Control type="email" name="email" value={values.email} readOnly/>
                            </InputGroup>                      
                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>전화번호</Form.Label>
                            <InputGroup>
                                <Form.Control isValid={pn1_valid} isInvalid={!pn1_check} required type="number" name="phonenumber1" value={values.phonenumber1} onChange={handlePN1Change} placeholder="전화번호를 입력해주세요."/>
                                <Button variant="light" onClick={(e)=>{checkDuplicaton("phonenumber1", values.phonenumber1)}}>중복확인</Button>
                                <Form.Control.Feedback type="invalid">
                                    전화번호가 중복되거나 올바르지 않습니다. 올바른 전화번호를 입력해주세요.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback>인증되었습니다 :)</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>추가 전화번호</Form.Label>
                            <Form.Control type="number" name="phonenumber2" value={values.phonenumber2} onChange={handleChange} placeholder="추가 전화번호를 입력해주세요."/>
                        </Form.Group>
                    </Row>
                    <Form.Group className="">
                    <Container className="mt-5 px-0">
                        <Button disabled={!validated} onClick={()=>{requestModify()}} className="w-100">회원정보수정</Button>
                        <Button variant="secondary" onClick={()=>{if(values.sns_type == null){setDeletePWShow(true)}else{setSNSDeleteShow(true);}}} className="mt-3 w-100">회원탈퇴</Button>
                    </Container>
                    </Form.Group>
                </Form>
            </section>
            <Modal_alert show={modify_complete_show} handleClose={()=>{navigate("/mypage/dashboard")}} modal_title="회원정보 수정 완료" content="회원정보수정이 완료되었습니다 :)" primary_btn="확인"/>
            <Modal_alert show={modify_failed_show} handleClose={()=>{setModifyFailedShow(false)}} modal_title="회원정보 수정 실패" content="회원정보수정에 실패하였습니다. 잠시 후 다시시도해주시거나 고객센터로 연락해주세요." primary_btn="확인"/>
            <Modal_alert show={delete_complete_show} handleClose={()=>{navigate("/")}} modal_title="회원탈퇴 완료" content="회원탈퇴가 완료되었습니다. 이용해주셔서 감사합니다." primary_btn="확인"/>
            <Modal_alert show={sns_delete_show} handleClose={()=>{setSNSDeleteShow();}} handleSubmit={()=>{pw_delete_myinfo_handleSubmit()}} modal_title="회원탈퇴하시겠습니까?" content="회원탈퇴 시 동일 SNS계정으로 재가입이 불가능합니다. 정말 탈퇴하시겠습니까?" secondary="닫기" primary_btn="확인"/>
            <Modal_Password_alert show={delete_pw_show} pw={secret_pw} setSecretPw={setSecretPw} handleClose={()=>{setDeletePWShow(); setSecretPw("");}} handleSubmit={()=>{pw_delete_myinfo_handleSubmit()}} modal_title="비밀번호 입력" content="정말 탈퇴하시겠습니까? 탈퇴하시려면 비밀번호를 입력해주세요." secondary="닫기" primary_btn="확인" />
        </>
    );
}

export default ModifyInfo;