import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Banner from "../common/banner.js"
import '../css/banner.css';
import NLP_demo_play from "./elements/demos/nlp_demo/nlp_demo_play.js";
import Demo_Banner from "./elements/demos/demo_banner.js";
import DEMO_EXPLAIN from "./elements/demos/demo_explain.js";
import BGRM_demo_play from "./elements/demos/nlp_demo/bgrm_demo_play.js";
import { useState } from "react";
import Loading from "../common/Loading.js";
import TTS_demo_play from "./elements/demos/nlp_demo/tts_demo_play.js";


function WebAPI_DEMO(props){
    const [loading, setLoading] = useState(false);

    return (
        <div className="App">
                {loading == true ? <Loading/> : ""}
                <div className="banner-slider">
                    <Banner />
                </div>
                <div className="sections">
                    {
                        props.service_uid== 1 ?
                            <NLP_demo_play loading={loading} setLoading={setLoading} />
                        :
                        props.service_uid == 2 ?
                            <BGRM_demo_play loading={loading} setLoading={setLoading}/>
                        :
                        props.service_uid == 3 ?
                            <TTS_demo_play loading={loading} setLoading={setLoading}/>
                        :
                        ""
                    }
                </div>
                <div className="sections-black">
                    <Demo_Banner />
                </div>
                <div className="sections">
                    <DEMO_EXPLAIN />
                </div>
        </div>

    );

}

export default WebAPI_DEMO;