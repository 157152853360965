import React from "react";
import Intro_NLP from "./intro_nlp";
import Intro_BGRM from "./intro_bgrm";
import Banner from "../../common/banner";
import { Breadcrumb } from "react-bootstrap";
import Intro_TTS from "./intro_tts";

function Introduction(props){
    
    return(
        <>
            <div className="banner-slider">
                <Banner />
            </div>
            <section className="section-content" style={{paddingBottom:"0"}}>
                <Breadcrumb className='mt-3'>
                    <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {props.page == 'nlp' ? '자연어처리 AI 소개' : props.page=="bgrm" ? "배경제거 AI 소개" : props.page=="tts" ? "음성합성 AI 소개" : ""}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </section>
            <div className="sections pt-0">
                <section className="sections-white mt-0">
                    {
                        props.page == "nlp" ?
                        <Intro_NLP/> :
                        props.page == "bgrm" ?
                        <Intro_BGRM/> :
                        props.page == "tts" ?
                        <Intro_TTS/> :
                        ""
                    }
                </section>
            </div>
        </>
    );
}

export default Introduction;