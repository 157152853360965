import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, InputGroup, ListGroup } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Modal_alert from "../../modal";

function ModifyCoupon(props){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("쿠폰 상세조회/수정");
    const [loading, setLoading] = useState(false);
    const [serviceType, setServiceType]= useState([]);

    const [btnText, setBtntext] = useState("수정");
    
    const coupon_id = location.state.coupon_id;
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [update_show, setUpdateShow] = useState(false);
    const [update_complete_show, setUpdateCompleteShow] = useState(false);
    const [update_failed_show, setUpdateFailedShow] = useState(false);
    const [coupon_expired_show, setCouponExpiredShow] = useState(false);
    const [coupon_expired_complete_show, setCouponExpiredCompleteShow] = useState(false);
    const [coupon_expired_failed_show, setCouponExpiredFailedShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [memberSearch, setMembersearch] = useState("");
    const [memberList, setMemberList] = useState([]);
    const [selectedMember, setSelectedMember] = useState({});
    const [memberListShow, setMemberListShow] = useState(false);

    const[values, setValues] = useState({coupon_id :"", service_uid : 1, pay_amount:"",valid_start : new Date(), valid_end:new Date(), created_date: "",user_id:"", user_name:"", user_nickname:"", invalid:true});


    const handleMemberSearch = e => {
        setMembersearch(e.target.value);
    }
    const memberSelectHandler = (e) => {
        e.preventDefault();
        setSelectedMember(
            {uid : e.target.getAttribute("uid"), id_ : e.target.getAttribute("id_"), name_: e.target.getAttribute("name_")},
        )
        setMembersearch(()=>{return e.target.getAttribute("id_")});
        setMemberListShow(()=>{return false});
    }


    const handleChange = e => {
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
      }

    const handleSubmit_update = (event) => {
        event.preventDefault();
        setUpdateShow(false);
        updateCoupon();
      };
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/couponList");
    };
    const handleShow = () => setShow(true);
    const getServiceIndex = (uid)=>{
        if(serviceType.length == 0)
            getServiceType();
        let res_idx = -1;
        serviceType.map(function(item, idx){
            if(item.uid == uid){
                res_idx  = idx;
                return;
            }
        });
        console.log("idx : "+res_idx);
        return res_idx;
    }
    const setValidStart = ( e) =>{
        setValues({...values, valid_start : e})
    }

    const setValidEnd = (e) =>{
        setValues({...values, valid_end : e})
    }

    const searchMember = (q) =>{
        axios.get('/sso/rest/admin/getUserList', 
            {params:
            {
                list_type:"detail",
                page : 0,
                page_limit : 10,
                q_type : 'id',
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setMemberList(()=>{return response.data.list});           
                setMemberListShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const getServiceType = () =>{
        axios.get('/rest/admin/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setServiceType(()=>{return response.data});
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const getCouponInfo = () =>{
        getServiceType();
        setLoading(()=>{return true});
        axios.get('/rest/admin/getCouponInfo',
        { params : {coupon_id : location.state.coupon_id}}).then(response =>{
            response.data.data.valid_start = new Date(Date.parse(response.data.data.valid_start));
            response.data.data.valid_end = new Date(Date.parse(response.data.data.valid_end));
            setValues(response.data.data);
            if(response.data.data.user_id != null)
                setSelectedMember({uid : 0, id_ : response.data.data.user_id, name_:response.data.data.user_name, nickname_ : response.data.data.user_nickname})
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const updateCoupon = () =>{
        setLoading(()=>{return true});
        axios.post('/rest/admin/updateCoupon',
        {coupon_id : location.state.coupon_id, user_id : (values.user_id != undefined ? "" : selectedMember.id_), valid_start: values.valid_start, valid_end: values.valid_end, }).then(response =>{
            if(response.data.result == 'success'){
                setUpdateCompleteShow(true);
                getCouponInfo();
            }else{
                setUpdateFailedShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const deleteCoupon = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/deleteCoupon',
        { params : {coupon_id : location.state.coupon_id}}).then(response =>{
            if(response.data.result == 'success'){
                setCouponExpiredCompleteShow(true);
                navigate('/admin/couponList')
            }else{
                setCouponExpiredFailedShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    useEffect(() => {}, [values, serviceType]);
    useEffect(() => {
        if(memberSearch.length > 0)
            searchMember(memberSearch);
        else
            setMemberListShow(false);
    }, [memberSearch]);
    useEffect(() => {
        
        getCouponInfo();
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={5}>
                                <Form.Group>
                                    <Form.Label>쿠폰번호</Form.Label>
                                    <Form.Control required type="text" name="order_id" defaultValue={coupon_id} readOnly/>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>쿠폰상태</Form.Label>
                                    <br/>
                                    <strong>{values.invalid ? "만료됨" : "사용가능"}</strong>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>회원아이디</Form.Label>
                                    {
                                        values.user_id != undefined ? 
                                            <>
                                                <br/>
                                                <strong>{values.user_id}</strong>
                                            </>
                                            
                                        :
                                        <>
                                            <Form.Control type="text" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none"}} value={memberSearch} onChange={handleMemberSearch} placeholder="회원 검색" />
                                            <ListGroup style={{zIndex:"99"}} className={`${memberListShow ? "" : "d-none"} position-absolute`}>
                                                {memberList.map(function(item, idx){
                                                    return(<ListGroup.Item action key={idx} onClick={memberSelectHandler} uid={item.uid} id_={item.id} name_={item.name}>{item.name}({item.id})</ListGroup.Item>)
                                                })}
                                            </ListGroup>
                                        </>
                                    }
                                    
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>회원명</Form.Label>
                                    <br/>
                                    {
                                        values.user_id != undefined ? 
                                            <Form.Control  type="text" defaultValue={values.user_name} readOnly/>
                                        :
                                            <strong>{selectedMember.name_}</strong>
                                    }
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>회원닉네임</Form.Label>
                                    {
                                        values.user_id != undefined ? 
                                            <Form.Control  type="text" defaultValue={values.user_nickname} readOnly/>
                                        :
                                            <>
                                                <br/>
                                                <strong>{selectedMember.name_}</strong>
                                            </>
                                    }
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>발급일시</Form.Label>
                                    <Form.Control  type="text" defaultValue={values.created_date} readOnly/>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>서비스유형</Form.Label>
                                    <Form.Control  type="text" defaultValue={serviceType.length > 0 && values.service_uid != null ? serviceType[getServiceIndex(values.service_uid)].service_name : ""} readOnly/>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>쿠폰금액</Form.Label>
                                    <Form.Control  type="text" defaultValue={values.pay_amount} readOnly/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2}>
                                <Form.Group className="">
                                    <Form.Label className="m-0">유효시작일자</Form.Label><br/>
                                    <ReactDatePicker className="form-control" showTimeSelect dateFormat="yyyy-MM-dd hh:mm" dateFormatCalendar="yyyy년 MM월" selected={values.valid_start} onChange={(date) => setValidStart(date)} selectsStart startDate={values.valid_start} endDate={values.valid_end}/>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="m-0">만료일자</Form.Label><br/>
                                    <ReactDatePicker className="form-control" showTimeSelect dateFormat="yyyy-MM-dd hh:mm" dateFormatCalendar="yyyy년 MM월"  selected={values.valid_end} onChange={(date) => setValidEnd(date)} selectsStart startDate={values.valid_start} endDate={values.valid_end}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            {location.state.coupon_id.length > 0 && values.invalid == false ? 
                                <>
                                    <Col>
                                        <Button  className="text-start" type="button" variant="danger"  style={{marginTop:"2rem"}} onClick={()=>{setCouponExpiredShow(true)}}>쿠폰 만료</Button>
                                    </Col>
                                </>
                                :
                                ""
                            }
                            <Col className="text-end">
                                {values.invalid == false ? 
                                    <Button type="button" className={` btn btn-secondary `}  style={{marginTop:"2rem"}} onClick={()=>{setUpdateShow(true)}}>쿠폰 수정</Button>
                                    :
                                    ""
                                }
                                
                            </Col>
                        </Row>
                    </Container>
                </Form>
                <Modal_alert show={update_show} handleClose={()=>{setUpdateShow(false)}} handleSubmit={handleSubmit_update} modal_title="" content={`쿠폰정보를 수정하시겠습니까?`} secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={update_complete_show} handleClose={()=>{setUpdateCompleteShow(false); navigate("/admin/couponList")}} modal_title="쿠폰정보수정완료" content="쿠폰정보가 수정되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={update_failed_show} handleClose={()=>{setUpdateFailedShow(false);}} modal_title="쿠폰정보수정실패" content="쿠폰정보 수정에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={coupon_expired_show} handleClose={()=>{setCouponExpiredShow(false);}} handleSubmit={deleteCoupon} modal_title="쿠폰만료경고" content="쿠폰이 만료되면 돌이킬 수 없습니다. 정말로 만료시키시겠습니까?."  secondary={true} primary_btn={primary_btn} />
                <Modal_alert show={coupon_expired_complete_show} handleClose={()=>{setCouponExpiredCompleteShow(false);}} modal_title="쿠폰만료성공" content="쿠폰이 만료되었습니다." primary_btn={primary_btn} />
                <Modal_alert show={coupon_expired_failed_show} handleClose={()=>{setCouponExpiredFailedShow(false);}} modal_title="쿠폰만료실패" content="쿠폰만료에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>

            </div>
        </>
        );

}

export default ModifyCoupon