import React, {useState, useEffect} from "react";
import Table from 'react-bootstrap/Table'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading.js";
import ListSearch from "../../listSearch.js";
import PageSelector from "../../page_selector.js";


function APILogList(){
    const navigate = useNavigate();
    const [title, setTitle] = useState("크레딧 사용 로그 조회");
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [page, setPage]  = useState(1);
    const [totalSize, setTotalSize] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [pagelimit, setPagelimit] = useState(20);
    const [serviceType, setServiceType]= useState([]);
    const [searchFilter, setSearchFilter] = useState([{val:"service", name:"서비스유형"},{val:"id", name:"아이디"},{val:"name", name:"이름"},{val:"user_agent", name:"접속기기"},{val:"user_ip", name:"접속IP"},{val:"is_demo", name:"데모여부"}]);
    const [search_kind, setSearchKind] = useState(searchFilter[0].val);
    const [query, setQuery]= useState("");

    const PageHandler = (page)=>{
        setPage(()=>{return page});
        //requestList(search_kind, query);
    }
    
    const getServiceType = () =>{
        axios.get('/rest/admin/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setServiceType(()=>{return response.data});
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );

    }
    const getServiceIndex = (uid)=>{
        if(serviceType.length == 0)
            getServiceType();
        let res_idx = -1;
        serviceType.map(function(item, idx){
            if(item.uid == uid){
                res_idx  = idx;
                return;
            }
        });
        console.log("idx : "+res_idx);
        return res_idx;
    }

    const getServiceUid = (service_name)=>{
        let res_idx = -1;
        serviceType.map(function(item, idx){
            if(item.service_name == service_name){
                res_idx  = item.uid;
                return;
            }
        });
        return res_idx;
    }


    const requestList = (q_type, q) =>{
        setLoading(true);
        getServiceType();
        axios.get('/rest/admin/getAPILogList', 
            {params:
            {
                page : page-1,
                page_limit : pagelimit,
                q_type : q_type,
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                let totalSize = response.data.count;
                let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setListData(() => {return response.data.list});
                setTotalSize(totalSize);
                setTotalPage(totalPage_);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }

    useEffect(()=>{}, [search_kind, query]);
    //
    useEffect(() => {
        requestList(search_kind,query);
      },[page]);

    return(
        <>
        {loading == true ? <Loading/> : ""}
        <div className={`content card-shadow`}>
            <div>
                <h5><strong>{title}</strong></h5>
            </div>
            <ListSearch goSearch={requestList}  search_kind={search_kind} setSearchKind={setSearchKind} query={query} setQuery={setQuery} filter={searchFilter}/>
            <div>
                <span>총 <strong>{totalSize}개</strong>의 결과가 있습니다.</span>
            </div>
            <div style={{overflowX:'Auto'}}>
                <Table striped bordered hover size="sm" style={{minWidth:'1300px'}} >
                    <thead>
                        <tr>
                            <th>순서</th>
                            <th>아이디</th>
                            <th>이름</th>
                            <th>서비스유형</th>
                            <th>API이름</th>
                            <th>API버전</th>
                            <th>차감크레딧</th>
                            <th>접속IP</th>
                            <th>접속기기</th>
                            <th>데모여부</th>
                            <th>실패로그</th>
                            <th>접속일시</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData.map(function(a, idx){
                            return (
                                <tr key={idx}>
                                    <td>{idx+1}</td>
                                    <td>{a.id}</td>
                                    <td>{a.name}</td>
                                    <td>{serviceType.length > 0 && a.service_uid != null ? serviceType[getServiceIndex(a.service_uid)].service_name : ""}</td>
                                    <td>{a.api_name}</td>
                                    <td>{a.api_version}</td>
                                    <td>{a.discharge}</td>
                                    <td>{a.user_ip}</td>
                                    <td><span  style={{overflow:"hidden", width:"200px", textOverflow:"ellipsis", wordBreak:"break-all", whiteSpace:"nowrap", display:"block"}}>{a.user_agent}</span></td>
                                    <td>{a.is_demo == true ? "O" : "X"}</td>
                                    <td>{a.failed_body}</td>
                                    <td>{a.created_date}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            <PageSelector curPage={page} totalPage={totalPage} page_limit={pagelimit} PageHandler_={PageHandler}/>
        </div>
        </>
    );

}

export default APILogList;