import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, InputGroup, ListGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";


import Modal_alert from "../../../common/modal";


function RegisterOrder(){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [serviceType, setServiceType]= useState([]);

    const [btnText, setBtntext] = useState("");
    
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [delete_show, setDeleteShow] = useState(false);
    const [delete_complete_show, setDeleteCompleteShow] = useState(false);
    const [error_show, setErrShow] = useState(false);
    const [validated, setValidated] = useState(false);

    const [memberSearch, setMembersearch] = useState("");
    const [memberList, setMemberList] = useState([]);
    const [selectedMember, setSelectedMember] = useState([]);
    const [memberListShow, setMemberListShow] = useState(false);

    const [payplanSearch, setPayplansearch] = useState("");
    const [payplanList, setPayplanList] = useState([]);
    const [selectedPayplan, setSelectedPayplan] = useState([]);
    const [payplanListShow, setPayplanListShow] = useState(false);

    const [personFee, setPersonFee] = useState(0);
    const [totalFee, setTotalFee] = useState(0);

    const[values, setValues] = useState({});

    const checkDuplicaton = (arr, target)=> {
        for(let i=0; i<arr.length; i++){
            if(arr[i].uid == target)
                return true;
        }
        return false;
    }
    const deleteMember = (uid, e) =>{
        console.log("deletemember "+uid);
        setSelectedMember(selectedMember.filter(item => item.uid != uid));
    }
    const deletePayplan = (uid, e) =>{
        console.log("deletepayplan "+uid);
        setSelectedPayplan(searchPayplan.filter(item => item.uid != uid));
   }
    const setEa = (uid, e) =>{
        setSelectedPayplan(
        selectedPayplan.map(item => 
            item.uid == uid ? {...item, ea : e.target.value} : item
        ));
    }

    const setEaBtn = (uid, direction,e) =>{
        if(direction == "+"){
            setSelectedPayplan(
            selectedPayplan.map(item => 
                item.uid == uid ? {...item, ea : item.ea+1} : item
            ));
        }else if(direction == "-"){
            setSelectedPayplan(
            selectedPayplan.map(item => 
                item.uid == uid ? {...item, ea : item.ea-1} : item
            ));
        }
    }

    const handleMemberSearch = e => {
        setMembersearch(e.target.value);
    }
    const handlePayplanSearch = e => {
        setPayplansearch(e.target.value);
    }

    const memberSelectHandler = (e) => {
        if(!checkDuplicaton(selectedMember, e.target.getAttribute("uid"))){
            setSelectedMember([
                ...selectedMember,
                {uid : e.target.getAttribute("uid"), id_ : e.target.getAttribute("id_"), name_: e.target.getAttribute("name_")},
            ])
        }
        setMembersearch("");
        setMemberListShow(false);
    }
    const payplanSelectHandler = (e) => {
        if(!checkDuplicaton(selectedPayplan, e.target.getAttribute("uid"))){
            setSelectedPayplan([
                ...selectedPayplan,
                {uid : e.target.getAttribute("uid"), name_: e.target.getAttribute("name_"), ea:1, fee:e.target.getAttribute("fee")},
            ])
        }
        setPayplansearch("");
        setPayplanListShow(false);
    }

    const handleChange = e => {
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
      }

    const handleSubmit_delete = (event) => {
        event.preventDefault();
        //setDeleteShow(false);
        //deletePayplan();
      };

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false ||(selectedMember.length ==0 || selectedPayplan.length ==0)) {
        event.stopPropagation();
        setErrShow(true);
      }else{
        regist();
      }

      setValidated(true);
      
    };
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/OrderList");
    };
    const handleShow = () => setShow(true);

    
    const getServiceType = () =>{
        axios.get('/rest/admin/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setServiceType(()=>{return response.data});
                if(location.state != null && location.state.mode != 'mod'){
                    setValues({
                        ...values,
                        servicetype: response.data[0].uid,
                    })
                }
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const searchMember = (q) =>{
        axios.get('/sso/rest/admin/getUserList', 
            {params:
            {
                list_type:"detail",
                page : 0,
                page_limit : 10,
                q_type : 'email',
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setMemberList(()=>{return response.data.list});           
                
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const searchPayplan = (q) =>{
        axios.get('/rest/admin/getPayplanList', 
            {params:
            {
                list_type:"detail",
                page : 0,
                page_limit : 10,
                q_type : 'plan',
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setPayplanList(()=>{return response.data.list});           
                
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const regist = () =>{
        setLoading(true);
        getServiceType();
        console.log(values.ispublic);
        axios.post('/rest/admin/registerOrderBatch',
        { 
            members:selectedMember,
            payplans : selectedPayplan
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    setModalTitle("주문서 등록 성공");
                    setModalContent("주문서등록에 성공하였습니다.");
                    setRegisterResult(true);
                }else{
                    setModalTitle("주문서 등록 실패");
                    setModalContent("주문서등록에 실패하였습니다.");
                    setRegisterResult(false);
                }
                handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    useEffect(()=>{

    }, [personFee, totalFee]);
    useEffect(() => {
        if(memberList.length > 0){
            setMemberListShow(true);
        }else{
            setMemberListShow(false);
        }

    }, [memberList]);
    useEffect(() => {
        if(payplanList.length > 0){
            setPayplanListShow(true);
        }else{
            setPayplanListShow(false);
        }

    }, [payplanList]);
    useEffect(()=>{
        let membercnt = selectedMember.length;
        let totalfee = 0;
        selectedPayplan.map(function(item, idx){
            totalfee += Number(item.fee)*item.ea;
        });
        console.log(membercnt+" "+totalfee);
        setTotalFee(membercnt*totalfee);
        setPersonFee(totalfee);
    },[selectedMember, selectedPayplan])
    useEffect(() => {
        if(memberSearch.length > 0)
            searchMember(memberSearch);
        else
            setMemberListShow(false);
    }, [memberSearch]);
    useEffect(() => {
        if(payplanSearch.length > 0)
            searchPayplan(payplanSearch);
        else
            setPayplanListShow(false);
    }, [payplanSearch]);
    useEffect(() => {
        getServiceType();
        if(location.state != null && location.state.mode == 'mod'){
            setTitle(()=>{
                return "주문서 수정";
            })
            setBtntext(()=>{
                return "수정";
            })
        }else{
            setTitle(()=>{
                return "주문서 추가";
            })
            setBtntext(()=>{
                return "등록";
            })
            setValues({});
        }
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form noValidate validated={validated} onSubmit={(e)=>{e.preventDefault()}}>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={5}>
                                <Form.Group>
                                    <Form.Label>주문번호</Form.Label>
                                    <Form.Control required type="text" name="order_id" value={values.orderid} onChange={handleChange} placeholder="주문서 생성 후 표시됩니다." readOnly/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row  className="mb-3">
                            <Col lg={5}>
                                <Form.Group>
                                    <Form.Label>주문자검색</Form.Label>
                                    <Form.Control type="text" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none"}} value={memberSearch} onChange={handleMemberSearch} placeholder="주문자 검색" />
                                    <ListGroup className={`${memberListShow ? "" : "d-none"} position-absolute`}>
                                        {memberList.map(function(item, idx){
                                            return(<ListGroup.Item action key={idx} onClick={memberSelectHandler} uid={item.uid} id_={item.id} name_={item.name}>{item.name}({item.id})</ListGroup.Item>)
                                        })}
                                    </ListGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={7}>
                                <Form.Group>
                                        <Form.Label>주문자목록</Form.Label>
                                    <Container className="border p-3">
                                        {selectedMember.map(function(item, idx){
                                            return(<Row key={idx} className="border-bottom lh-lg">
                                                <Col lg={5}>
                                                    {item.id_}
                                                </Col>
                                                <Col lg={6}>
                                                    {item.name_}
                                                </Col>
                                                <Col lg={1}>
                                                    <Button variant="outline-danger" className="btn btn-sm py-0" onClick={(e)=>{deleteMember(item.uid, e)}}>X</Button>
                                                </Col>
                                            </Row>)
                                        })}
                                    </Container>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row  className="mb-3">
                            <Col lg={5}>
                                <Form.Group>
                                    <Form.Label>플랜검색</Form.Label>
                                    <Form.Control type="text" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none", paddingRight:0}} value={payplanSearch} onChange={handlePayplanSearch} placeholder="플랜 검색" />
                                    <ListGroup className={`${payplanListShow ? "" : "d-none"} position-absolute`}>
                                        {payplanList.map(function(item, idx){
                                            return(<ListGroup.Item action key={idx} onClick={payplanSelectHandler} uid={item.uid} name_={item.plan_name} fee={item.fee}>{item.plan_name}</ListGroup.Item>)
                                        })}
                                    </ListGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={7}>
                                <Form.Group>
                                        <Form.Label>플랜목록</Form.Label>
                                    <Container className="border p-3">
                                        {selectedPayplan.map(function(item, idx){
                                            return(<Row key={idx} className="border-bottom lh-lg">
                                                <Col lg={5}>
                                                    {item.name_}
                                                </Col>
                                                <Col lg={3}>
                                                    <InputGroup className="pt-1 pe-5">
                                                        <Button variant="outline-secondary" onClick={(e)=>{setEaBtn(item.uid, "-", e)}} className="py-0">
                                                            -
                                                        </Button>
                                                        <Form.Control type="number" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none", textAlign:"center"}} value={item.ea} onChange={(e)=>{setEa(item.uid,e)}} className="py-0 px-0"/>
                                                        <Button variant="outline-secondary"  onClick={(e)=>{setEaBtn(item.uid, "+", e)}} className="py-0">
                                                            +
                                                        </Button>
                                                    </InputGroup>
                                                </Col>
                                                <Col lg={3}>
                                                    <strong>{item.fee * item.ea}원</strong>
                                                </Col>
                                                <Col lg={1}>
                                                    <Button variant="outline-danger" className="btn btn-sm py-0" onClick={(e)=>{deletePayplan(item.uid, e)}}>X</Button>
                                                </Col>
                                            </Row>)
                                        })}
                                    </Container>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="text-end">
                            <h6><strong>주문자별 주문금액(VAT포함) : {personFee}원</strong></h6>
                        </Row>
                        <Row  className="text-end">
                            <h6><strong>총 주문금액(VAT포함) : {totalFee}원</strong></h6>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            
                            <Col sm={1}>
                                <Button type="button" className={`w-100 btn btn-secondary ${location.state != null && location.state.mode == 'mod' ? "" : "d-none"}`} onClick={()=>{setDeleteShow(true)}}>삭제</Button>
                            </Col>
                            <Col sm={1}>
                                <Button type="button" onClick={handleSubmit} className="w-100">{btnText}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
                {location.state != null && location.state.mode == 'mod' ? 
                <><Modal_alert show={delete_show} handleClose={()=>{setDeleteShow(false)}} handleSubmit={handleSubmit_delete} modal_title="" content="정말 플랜을 삭제하시겠습니까?" secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={delete_complete_show} handleClose={()=>{setDeleteCompleteShow(false); navigate("/admin/Payplan"); }} modal_title="삭제완료" content="플랜이 삭제되었습니다." primary_btn={primary_btn}/></>
                :
                ""
                }
                <Modal_alert show={error_show} handleClose={()=>{setErrShow(false)}} modal_title="입력값 오류!" content="이용자정보와 플랜정보를 다시한번 확인해주세요." primary_btn={primary_btn}/>
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
            </div>
        </>
        );

}

export default RegisterOrder;