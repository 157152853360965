import axios from "axios";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Container, Form, Image, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function TTS_demo_play(props){
    const navigate = useNavigate();
    
    const [text, setText] = useState("");
    const audio_obj = useRef();
    const [audio, setAudio] = useState({audio_ : "", uid : -1});
    const [audioPlay, setAudioPlay] = useState(false);
    const api_list = ["2023-08-14", "2023-08-15", "2023-08-16", "2023-08-17"];
    const api_code = "tts_demo";
    const [api_version, setAPIVersion] = useState(api_list[2]);

    


    const [batchQueueLog, setBatchQueueLog] = useState([]);
    const [batchQueueTimer, setBatchQueueTimer] = useState(true);
    const [queuelog, setQueueLog] = useState("");
    const queueloguid = useRef(0);
    const status = {ready :"준비", running:"진행중", finished:"완료", failed:"실패"};

    const onChangeAPIVersion = (e)=>{
        setAPIVersion(e.target.value);
    };

    const RequestBatch = ()=>{
        if(!text){
            alert("글자를 입력해주세요!");
            return;
        }
        if(!api_version){
            alert("API버전을 선택해주세요!");
            return;
        }
        props.setLoading(true);
        axios.post('/rest/webapiDemo',{
            service_uid : 3,
            api_code : api_code,
            api_subname : "korean",
            'api-version' : api_version,
            text : text,
        }).then((response)=>{
            if(response.data.data.msg === 'Credit Exceed'){
                alert("1회 사용 가능 횟수를 초과하였습니다. 크레딧을 구매하시거나, 24시간 후 다시 시도해 주세요.");
                window.open('/subscribe');
            }else{
                var uid = batchQueueLog.length+1;
                var url = `data:audio/mp3;base64,${response.data.data.data}`//blobToURL(response.blob());
                setBatchQueueLog([{download:url, progress:100, status:"finished", uid:uid, request_id:"데모음원"+uid}, ...batchQueueLog]);
            }
            props.setLoading(false);
        }).catch((error)=>{
            alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
            props.setLoading(false);
        });

    }


    const resetConfig = ()=>{
        queueloguid.current = 0;
        setAPIVersion("");
        setBatchQueueLog([]);
        setQueueLog("");
        setText("");
    }
    const downloadFile = (data) => {      
        const byteString = atob(data.split(",")[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        let type = data.substring(data.indexOf(":")+1, data.indexOf(";"));

        if(type=="image/jpg")
            type="image/jpeg";

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
            type: type,
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download="";
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
            window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
    };

    const downloadLink = (url) => {      
        const a = document.createElement('a');
        a.href = url;
        a.download="";
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
            window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
    };


    const toggleAudioPlay = (url) => {setAudioPlay(!audioPlay);}

    useEffect(() => {
        if(audioPlay==true && audio.uid >0){
            audio_obj.current.load();
            audio_obj.current.play()
         }else{
            audio_obj.current.pause();
         } 
      },
      [audioPlay]
    );

    useEffect(()=>{audio_obj.current.addEventListener('ended', () => setAudioPlay(false)); setAudioPlay(true);}, [audio]);
    
    useEffect(() => {
      /*
      return () => {
        audio.audio_.removeEventListener('ended', () => setAudioPlay(false));
      };*/
    }, []);

    return(
        <>
            <Breadcrumb className="section-content">
                <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                <Breadcrumb.Item href="/mypage/dashboard">
                    마이페이지
                </Breadcrumb.Item>
                <Breadcrumb.Item active>음성합성 AI 웹 API 데모</Breadcrumb.Item>
            </Breadcrumb>
            <section className="sections-white">
                <Container className="section-content">
                    <Tabs onSelect={(key)=>{resetConfig(); if(key=="ttskorean"){setBatchQueueTimer(true);props.setLoading(true);}else{setBatchQueueTimer(false);}}}>
                        <Tab eventKey="ttskorean" title="한국어"  className="p-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ 본 데모는 API의 성능을 확인하기 위한 용도로, 데모에 한정하여 <br/>배경음악이 같이 출력됩니다.</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>배경음악이 없는 결과물이 필요하시면 크레딧을 구매하시기 바랍니다.</span>
                                                    </Row>
                                                    <Row>
                                                        <span>하루 최대 5회, 한번에 최대 20자까지 가능합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row>
                                            <Form.Label>API버전 선택</Form.Label>
                                        </Row>
                                        <Row>
                                            <InputGroup>
                                                <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                    {
                                                        api_list.map(function(a, idx){
                                                            return(
                                                            <option key={idx} value={a}>{a}</option>
                                                            );
                                                        })
                                                    }
                                                </Form.Select>
                                            </InputGroup>
                                        </Row>
                                        <Row className="mt-3">
                                            <Form.Group>
                                                <Form.Label>합성할 단어 또는 문장 입력</Form.Label>
                                                <Form.Control as="textarea"style={{backgroundColor:"white"}} rows={8} value={text} onChange={(e)=>{if(e.target.value.length > 20){alert("데모 버전에서는 최대 20자까지 가능합니다."); e.preventDefault();}else{setText(e.target.value)}}}/>
                                            </Form.Group>
                                        </Row>
                                        <Row className="float-end mt-2"> 
                                            <InputGroup>
                                                <Button variant="secondary" onClick={()=>{resetConfig();}}>초기화</Button>
                                                <Button onClick={()=>{RequestBatch();}}>음성합성</Button>
                                            </InputGroup>
                                        </Row>
                                        <Row className="mt-5">
                                            <Form.Group>
                                                <Form.Label>상세로그</Form.Label>
                                                <Form.Control as="textarea"style={{backgroundColor:"white"}} rows={8} value={queuelog} readOnly/>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                    <span>2023-08-14 : 한국지능정보사회진흥원에서 제작한 여성(1) 목소리입니다.<br/>침착하고 부드러운 음성이 특징입니다.</span><br/>
                                                        <span>2023-08-15 : 한국지능정보사회진흥원에서 제작한 남성(1) 목소리입니다.<br/>담담한 목소리가 특징입니다.</span>
                                                        <span>2023-08-16 : 한국지능정보사회진흥원에서 제작한 여성(2) 목소리입니다.<br/>또렷한 음색을 가지고 있습니다.</span>
                                                        <span>2023-08-17 : 한국지능정보사회진흥원에서 제작한 남성(2) 목소리입니다.<br/>또렷한 목소리가 특징입니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="h-100">
                                        <Form.Label>작업목록</Form.Label>
                                        <audio ref={audio_obj} className="d-none">
                                            <source src={audio.audio_}/>
                                        </audio>
                                        <Container className="border py-3 m-lg-3  p-lg-3" style={{overflowY:"scroll", height:"700px"}}>
                                            {
                                                batchQueueLog.map(function(a, idx){
                                                    return(
                                                        <Row key={idx} className="mb-3  mx-auto" style={{height:"fit-content"}}>
                                                            <Card>
                                                                <Card.Body className=" px-1 py-3 p-lg-3">
                                                                    <Card.Title className="justify-content-between d-flex">
                                                                        <span>{a.request_id}</span>
                                                                        <span>
                                                                            { a.status == "running" ?
                                                                                <img style={{width:"20px", marginRight:"1rem"}} src={process.env.PUBLIC_URL + '/assets/loading_circle.gif'}/>
                                                                                :
                                                                                ""
                                                                            }
                                                                            {a.progress}%
                                                                        </span>
                                                                    </Card.Title>
                                                                    <div className="mb-2 justify-content-between d-flex">
                                                                        <span>
                                                                            <span className="fs-6">시작시각 : 정식버전에서 이용가능합니다.</span><br/>
                                                                                {
                                                                                    a.status == "finished" ?
                                                                                    <span className="fs-6">종료시각 : 정식버전에서 이용가능합니다.</span>
                                                                                    :
                                                                                    ""
                                                                                }
                                                                        </span>
                                                                        <span style={{minWidth:"40px"}}>{status[a.status]}</span>
                                                                    </div>
                                                                    <Row>
                                                                        <span>
                                                                            <Button variant="outline-secondary" className="me-3" onClick={()=>{alert("정식버전에서 이용가능합니다.");}} >로그보기</Button>
                                                                            {a.status == "finished" ?
                                                                                <>
                                                                                    <Button variant="outline-primary" className="me-3" onClick={()=>{if(audio.uid == a.uid){toggleAudioPlay();}else{setAudio(()=>{return {audio_ : a.download, uid : a.uid}}); toggleAudioPlay();}}} >{audioPlay==true && audio.uid == a.uid ? "정지" : "미리듣기"}</Button>
                                                                                    <Button variant="outline-primary" onClick={()=>{downloadLink(a.download)}} >다운로드</Button>
                                                                                </>
                                                                                :
                                                                                ""
                                                                            }
                                                                        </span>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Row>
                                                    );
                                                })
                                            }
                                        </Container>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>

                    </Tabs>
                </Container>
           </section>
        </>
    );

}

export default TTS_demo_play;