
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { Navigate } from "react-router-dom";
import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './css/font/SpoqaHanSansNeo.css'
import './App.css';
//import './css/bootstrap_5.1.3.css'
import 'bootstrap/dist/css/bootstrap.css';
import './css/common.css';
import MainLayout from "./common/Main_layout.js";
import BannerIndex from './common/banner_index.js';
import Subscribe from './pages/subscribe';
import Admin_Login from "./pages/admin/login.js";
import Dashboard from "./pages/admin/dashboard";
import Privacy from "./pages/privacy";
import Mypage_Dashboard from "./pages/elements/mypage/dashboard";
import ApiLog from "./pages/elements/mypage/apiLog";
import Agreement from "./pages/agreement";
import ApiDashboard from "./pages/elements/mypage/apiDashboard";
import Register_intro from "./pages/register/register_intro";
import Register_form from "./pages/register/register_form";
import ModifyInfo from "./pages/elements/mypage/modifyInfo";
import WebAPI from "./pages/elements/mypage/webapi/webapi";
import WebAPI_DEMO from "./pages/webapi_demo";
import Order_view from "./pages/elements/mypage/order_view";
import Order from "./pages/elements/order/order";
import FindID from "./pages/register/findid";
import FindPW from "./pages/register/findpw";
import CouponList_user from "./pages/elements/mypage/couponlist";
import Orderlist_user from "./pages/elements/mypage/orderlist";
import Introduction from "./pages/intro/introduction";
import Docs from "./pages/docs/docs";
import Receipt_Form from "./pages/elements/mypage/modules/receipt_form";

function App() {



  return (
     <div className="App">
      <Routes>
        <Route path="/" exact element={<Navigate to ="/index"/>}/>
        <Route path="/mypage" exact element={<Navigate to ="/mypage/dashboard"/>}/>
        <Route element={<MainLayout/>}>
          <Route path="/index" exact element={<BannerIndex/>}/>
          <Route path="/findid" exact element={<FindID />}/>
          <Route path="/findpw" exact element={<FindPW />}/>
          <Route path="/policy/privacy" exact element={<Privacy/>}/>
          <Route path="/policy/agreement" exact element={<Agreement/>}/>
          <Route path="/order" exact element={<Order/>}/>
          <Route path="/subscribe" exact element={<Subscribe />}/>
          <Route path="/demo/nlp" exact element={<WebAPI_DEMO service_uid={1}/>}/>
          <Route path="/demo/bgrm" exact element={<WebAPI_DEMO service_uid={2}/>}/>
          <Route path="/demo/tts" exact element={<WebAPI_DEMO service_uid={3}/>}/>
          <Route path="/mypage/dashboard" exact element={<Mypage_Dashboard />}/>
          <Route path="/mypage/viewAPILog" exact element={<ApiLog />}/>
          <Route path="/mypage/apiDashboard" exact element={<ApiDashboard />}/>
          <Route path="/mypage/modifyInfo" exact element={<ModifyInfo />}/>
          <Route path="/mypage/webapi" exact element={<WebAPI />}/>
          <Route path="/mypage/order" exact element={<Order_view />}/>
          <Route path="/mypage/couponlist" exact element={<CouponList_user />}/>
          <Route path="/mypage/orderlist" exact element={<Orderlist_user />}/>
          <Route path="/register/intro" exact element={<Register_intro/>}/>
          <Route path="/register/goRegister" exact element={<Register_form/>}/>
          <Route path="/intro/nlp" exact element={<Introduction page="nlp"/>}/>
          <Route path="/intro/bgrm" exact element={<Introduction page="bgrm"/>}/>
          <Route path="/intro/tts" exact element={<Introduction page="tts"/>}/>
          <Route path="/docs/nlp" exact element={<Docs page="nlp"/>}/>
          <Route path="/docs/bgrm" exact element={<Docs page="bgrm"/>}/>
          <Route path="/docs/tts" exact element={<Docs page="tts"/>}/>
        </Route>
        <Route path="/mypage/getReceipt/:order_id" exact element={<Receipt_Form />}/>
        <Route path="/admin/login/" exact element={<Admin_Login/>}/>
        <Route path="/admin/dashboard" element={<Dashboard/>}/>
        <Route path="/admin/serviceTypeList" element={<Dashboard menu="servicetypelist"/>}/>
        <Route path="/admin/ModifyServiceType" element={<Dashboard menu="modifyservicetype"/>}/>
        <Route path="/admin/UserList" element={<Dashboard menu="userlist"/>}/> 
        <Route path="/admin/CreditList" element={<Dashboard menu="creditlist"/>}/> 
        <Route path="/admin/Payplan" element={<Dashboard menu="payplan"/>}/> 
        <Route path="/admin/OrderList" element={<Dashboard menu="orderlist"/>}/> 
        <Route path="/admin/APILogList" element={<Dashboard menu="apiloglist"/>}/> 
        <Route path="/admin/registerPayplan" element={<Dashboard menu="registerpayplan"/>}/>
        <Route path="/admin/registerOrder" element={<Dashboard menu="registerorder"/>}/>
        <Route path="/admin/modifyOrder" element={<Dashboard menu="modifyorder"/>}/>
        <Route path="/admin/CouponList" element={<Dashboard menu="couponlist"/>}/>
        <Route path="/admin/registerCoupon" element={<Dashboard menu="registercoupon"/>}/>
        <Route path="/admin/modifyCoupon" element={<Dashboard menu="modifycoupon"/>}/>
        <Route path="/admin/registerCouponSingle" element={<Dashboard menu="single_registercoupon"/>}/>
        <Route path="/admin/visitorLog" element={<Dashboard menu="visitorlog"/>}/> 
        <Route path="/admin/receiptRequestList" element={<Dashboard menu="receiptrequestlist"/>}/>
      </Routes>
      <a id="chat-channel-button" style={{position:"fixed", right:"0", bottom:"-1px"}} href="javascript:chatChannel()">
		    <img src="/assets/img/consult_small_yellow_pc.png" alt="카카오톡 채널 채팅하기 버튼" />
	    </a>
      </div>


    /*<div className="App">

    <Routes>
        <Route path="/admin/*" exact>
          <Route path="/admin/login/" exact element={<Admin_Login/>}/>
        </Route>
      </Routes>


      <Header />
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
  </header>
    </div>*/
  );
}

export default App;
