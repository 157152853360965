import axios from "axios";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Container, Form, Image, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ScrollMenu, VisibilityContext  } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import ListSearch from "../../../../common/listSearch";

import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';


var background_img_offset = 2;

function WebAPI_BGRM(props){
    const navigate = useNavigate();
    const [img_preview, setImgPreview] = useState(process.env.PUBLIC_URL + '/assets/img/select_img.png')
    const [img_upload, setImgUpload] = useState("");
    const [img_get, setImgGet] = useState("");
    const [editor_show, setEditorShow] = useState(false);
    const [img_result, setImgResult] = useState(process.env.PUBLIC_URL + '/assets/img/ready_to_bgrm.png')
    const [transparent, setTransparent] = useState(true);

    const [img_result_type, setImgResultType] = useState("");


    const [page, setPage]  = useState(1);
    const [pagelimit, setPagelimit] = useState(5);
    const [searchFilter, setSearchFilter] = useState([{val:"hashtag", name:"해시태그"},{val:"name", name:"이름"}]);
    const [search_kind, setSearchKind] = useState(searchFilter[0].val);
    const [query, setQuery]= useState("");

    const imageEditor = React.createRef();

    const [bg_img_upload, setBGImgUpload] = useState("");
    const [background_img, setBackgroundImage] = useState("");
    const [background_img_list, setBackgroundImageList] = useState([]);
    const [background_select, setBackgroundSelect] = useState(false);
    
    const bg_input = useRef();

    const api_code = "bgrm";
    const [api_subname, setAPISubname] = useState("singleImage");
    const [api_version, setAPIVersion] = useState("");

    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);
    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const [batchQueueLog, setBatchQueueLog] = useState([]);
    const [batchQueueTimer, setBatchQueueTimer] = useState(false);
    const [queuelog, setQueueLog] = useState("");
    const queueloguid = useRef(0);
    const status = {ready :"준비", running:"진행중", finished:"완료", failed:"실패"};


    const namiTheme = {
        'common.bisize.width': '0px',
        'common.bisize.height': '0px',
        'common.backgroundColor': '#fff',
        'common.border': '1px solid #c1c1c1',
        'header.backgroundColor' : '#fff',
        'downloadButton.backgroundColor': '#0097dd',
        "header.display": "none"
      };
      
    var locale_ko_KR = {
        Apply: "적용",
        Arrow: "화살표",
        "Arrow-2": "화살표-2",
        "Arrow-3": "화살표-3",
        Blend: "블렌드",
        Blur: "흐리게",
        Bold: "두껍게",
        Brightness: "밝기",
        Bubble: "말풍선",
        Cancel: "취소",
        Center: "중앙",
        Circle: "원",
        Color: "색상",
        "Color Filter": "색상 필터",
        Crop: "자르기",
        Custom: "별도선택",
        "Custom icon": "별도 아이콘",
        Delete: "삭제",
        "DeleteAll": "전체삭제",
        Distance: "거리",
        Download: "다운로드",
        Draw: "그리기",
        Emboss: "엠보스",
        Fill: "채우기",
        Filter: "필터",
        Flip: "뒤집기",
        "Flip X": "가로로 뒤집기",
        "Flip Y": "세로로 뒤집기",
        Free: "자유롭게",
        Grayscale: "회색톤",
        Hand:"이동",
        Heart: "하트",
        History:"기록",
        Icon: "아이콘",
        Invert: "반전",
        Italic: "기울임",
        Left: "왼쪽",
        Load: "로드",
        "Load Mask Image": "마스크 이미지 로드",
        Location: "경로",
        Mask: "마스크",
        Multiply: "",
        Noise: "노이즈",
        Pixelate: "픽셀화",
        Polygon: "도형",
        Range: "범위",
        Rectangle: "직사각형",
        Redo: "앞으로",
        "Remove White": "흰색 지우기",
        Reset: "초기화",
        Right: "오른쪽",
        Rotate: "회전",
        Sepia: "세피아",
        Sepia2: "세피아2",
        Shape: "모양",
        Sharpen: "날카롭게",
        Square: "정사각형",
        "Star-1": "별-1",
        "Star-2": "별-2",
        Straight: "직선",
        Stroke: "획",
        Text: "글자",
        "Text size": "글자 크기",
        Threshold: "임계점",
        Tint: "틴트",
        Triangle: "삼각형",
        Underline: "밑줄",
        Undo: "되돌리기",
        Value:"값",
        ZoomIn:"확대",
        ZoomOut:"축소",
    };

    const handleClick = (id) =>({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    if(id >= background_img_offset){
        setBackgroundImage("data:image/jpeg;base64,"+background_img_list[id-background_img_offset].image); 
    }else if(id ==0){
        setBackgroundImage("");
        setEditorShow(false);
    }else if(id == 1){
        bg_input.current.click();
    }else if(id == -1){
        setPage(()=>{return page+1});
    }


    };


    const onLoadFile = (e)=>{
        setImgUpload(e.target.files);
    }

    const onLoadBGFile = (e)=>{
        setBGImgUpload(e.target.files);
    }

    const onChangeAPIVersion = (e)=>{
        setAPIVersion(e.target.value);
    };

    const handleTransparent = () =>{
        setTransparent(!(transparent));
    };

    const getBackgroundImageList = (q_type, q) =>{
        axios.get('/rest/getBackgroundImageList',{params:
            {
                page : page-1,
                page_limit : pagelimit,
                q_type : q_type,
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("작업목록을 가져오는데 실패했습니다.");
                navigate("/");
            }else{
                if(response.data.result == "success"){
                    setBackgroundImageList(background_img_list.concat(response.data.data));
                }else{
                    alert("이미지목록을 가져오는데 실패했습니다.");
                    navigate("/");
                }
                props.setLoading(false);
            }
        }).catch(
            error=>{
                alert("이미지목록을 가져오는데 실패했습니다.");
                console.log(error);
                props.setLoading(false);
                navigate("/");
            }
        );
    }

    const setupImageEditor = ()=>{
        props.setLoading(true);
        const instance_ = imageEditor.current.getInstance();
        instance_.loadImageFromURL(background_img, "bg").then(()=>{instance_.addImageObject(`data:image/png;base64,${img_get.data}`).then(() => {props.setLoading(false);})});
        //setTimeout(() => { instance_.addImageObject(`data:image/png;base64,${img_get.data}`).then(() => {}); }, 100);
        
    }

    const synthImage = (image, bg)=>{
        props.setLoading(true);
        let ratio = 1;
        const bg_obj = document.createElement('img');
        bg_obj.src = bg;
        bg_obj.onload = ()=>{
            var canvas = document.createElement('canvas');
            canvas.width = bg_obj.width;
            canvas.height = bg_obj.height;
            var context = canvas.getContext("2d");
            context.drawImage(bg_obj, 0, 0, bg_obj.width, bg_obj.height);
            const img_obj = document.createElement('img');
            if(img_result_type == "jpg")
                img_obj.src = `data:image/jpeg;base64,${image.data}`;
            else if (img_result_type == "png")
                img_obj.src = `data:image/png;base64,${image.data}`;
                img_obj.onload = ()=>{
                    if(canvas.width > canvas.height){
                        ratio = canvas.height/img_obj.height;
                    }else{
                        ratio = canvas.width/img_obj.width;
                    }
                context.drawImage(img_obj, (bg_obj.width/2)-((img_obj.width*ratio)/2), (bg_obj.height/2)-((img_obj.height*ratio)/2), img_obj.width*ratio, img_obj.height*ratio);
                var dataURI = canvas.toDataURL("image/png");
                setImgResult(()=>{return dataURI});  
                props.setLoading(false);
            }
        }
        
    }

    const getBatchQueueLog = () =>{
        axios.get('/rest/getBatchQueueList',{params:
            {
                service_uid : 2
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("작업목록을 가져오는데 실패했습니다.");
                navigate("/");
            }else{
                if(response.data.result == "success"){
                    setBatchQueueLog(response.data.data);
                    if(queueloguid.current > 0){
                        for(var i=0; i<response.data.data.length; i++){
                            if(response.data.data[i].uid == queueloguid.current)
                                setQueueLog(response.data.data[i].log);
                        }
                    }
                }else{
                    alert("작업목록을 가져오는데 실패했습니다.");
                    navigate("/");
                }
                props.setLoading(false);
            }
        }).catch(
            error=>{
                alert("작업목록을 가져오는데 실패했습니다.");
                console.log(error);
                props.setLoading(false);
                navigate("/");
            }
        );
    }

    const RequestBatch = ()=>{
        if(!img_upload){
            alert("압축파일을 선택해주세요!");
            return;
        }
        if(!api_version){
            alert("API버전을 선택해주세요!");
            return;
        }
        if(img_upload[0].type !== 'application/zip' && img_upload[0].type !== 'application/x-zip-compressed'){
            alert("ZIP 파일만 업로드 가능합니다."+img_upload[0].type);
            return;
        }
        props.setLoading(true);
        let data = {
            service_uid : 2,
            api_code : api_code,
            api_subname : "batchImage",
            'api-version' : api_version,
            transparent : transparent == true ? "1" : "0",
        };
        let json_data = JSON.stringify(data);
        const formData = new FormData();
        formData.append("body", json_data);
        formData.append("file", img_upload[0])
        axios.post('/rest/webapi',formData).then((response)=>{
            if(response.data.result == 'success'){
                getBatchQueueLog();
                queueloguid.current = Number(response.data.data.log_id);
                alert("작업이 시작되었습니다. 작업목록을 확인해주세요");
            }else{
                if(response.data.data != undefined){
                    if(response.data.data.error == 425||response.data.data.error == 427){
                        alert("크레딧이 모두 소진되었습니다. 크레딧을 구입해 주세요.");
                        window.open('/subscribe');
                    }else{
                        alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                    }
                }else{
                    alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                }
            }
            props.setLoading(false);
        }).catch((error)=>{
            alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
            props.setLoading(false);
        });

    }

    const Request = ()=>{
        if(!img_upload){
            alert("이미지를 선택해주세요!");
            return;
        }
        if(!api_version){
            alert("API버전을 선택해주세요!");
            return;
        }
        if(img_upload[0].type !== 'image/jpeg' && img_upload[0].type !== 'image/jpg' && img_upload[0].type !== 'image/png'){
            alert("JPG, PNG 파일만 업로드 가능합니다.");
            return;
        }
        if(img_preview){
            props.setLoading(true);
            let data = {
                service_uid : 2,
                api_code : api_code,
                api_subname : api_subname,
                'api-version' : api_version,
                transparent : transparent == true ? "1" : "0",
                image : img_preview.substring(img_preview.indexOf("base64,")+7),
            };
            let json_data = JSON.stringify(data);
            const formData = new FormData();
            formData.append("body", json_data);
            axios.post('/rest/webapi',formData).then((response)=>{
                if(response.data.result == 'success'){
                    setImgGet(response.data.data);
                    setBackgroundSelect(true);
                }else{
                    if(response.data.data != undefined){
                        if(response.data.data.error == 425||response.data.data.error == 427){
                            alert("크레딧이 모두 소진되었습니다. 크레딧을 구입해 주세요.");
                            window.open('/subscribe');
                        }else{
                            alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                        }
                    }else{
                        alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                    }
                }
                props.setLoading(false);
            }).catch((error)=>{
                alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                props.setLoading(false);
            });
        }

    }

    const resetConfig = ()=>{
        queueloguid.current = 0;
        document.getElementById("img_file").value="";
        document.getElementById("zip_file").value="";
        setImgPreview(process.env.PUBLIC_URL + '/assets/img/select_img.png');
        setImgUpload("");
        setImgGet("");
        setImgResult(process.env.PUBLIC_URL + '/assets/img/ready_to_bgrm.png');
        setTransparent(true);
        setAPIVersion("");
        setBatchQueueLog([]);
        setQueueLog("");
        setBackgroundImage("");
        setBGImgUpload("");
        setEditorShow(false);
        
    }

    const downloadFile = (data) => {      
        const byteString = atob(data.split(",")[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        let type = data.substring(data.indexOf(":")+1, data.indexOf(";"));

        if(type=="image/jpg")
            type="image/jpeg";

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
            type: type,
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download="";
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
            window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
    };

    const downloadLink = (url) => {      
        const a = document.createElement('a');
        a.href = url;
        a.download="";
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
            window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
    };
    const setImageResult = () =>{
        if(!img_get) return;
        //let imageurl = URL.createObjectURL(img_get);
        if(transparent){
            setImgResultType("png");
            setImgResult(`data:image/png;base64,${img_get.data}`);
        }else{
            setImgResultType("jpg");
            setImgResult(`data:image/jpg;base64,${img_get.data}`);
        }
    }
    useEffect(()=>{
        if(!img_upload || img_upload.length == 0){ 
            resetConfig();    
            return;
        }
        const reader = new FileReader();
        reader.onload = ()=>(setImgPreview(reader.result));
        reader.readAsDataURL(img_upload[0]);
    }, [img_upload]);
    useEffect(()=>{
        setEditorShow(false);
        setImageResult();
    }, [img_get]);

    useEffect(()=>{
        if(background_img == ""){
            setImageResult();
        }else{
            //synthImage(img_get,background_img);
            if(editor_show == false){
                setEditorShow(true);
            }else{
                setupImageEditor();
            }
        }
    }, [background_img]);
    useEffect(()=>{getBackgroundImageList(search_kind,query);},[page]);
    useEffect(()=>{
        if(editor_show == true)
            setupImageEditor();
    }, [editor_show]);
    useEffect(()=>{
        getBackgroundImageList(search_kind,query);
    }, []);
    useEffect(()=>{
        if(!bg_img_upload || bg_img_upload.length == 0){ 
            resetConfig();    
            return;
        }
        setBackgroundImage(bg_img_upload[0]);
        const reader = new FileReader();
        reader.onload = ()=>{setBackgroundImage(reader.result);};
        reader.readAsDataURL(bg_img_upload[0]);
    },[bg_img_upload])
    useEffect(()=>{
        if(batchQueueTimer == true){
            const timer = setInterval(()=>{
                getBatchQueueLog();
            }, 1000);
            return ()=>clearInterval(timer);
        }
    }, [batchQueueTimer])

    return(
        <>
            <Breadcrumb className="section-content">
                <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                <Breadcrumb.Item href="/mypage/dashboard">
                    마이페이지
                </Breadcrumb.Item>
                <Breadcrumb.Item active>이미지배경제거 AI 웹 API</Breadcrumb.Item>
            </Breadcrumb>
            <section className="sections-white">
                <Container className="section-content">
                    <Tabs onSelect={(key)=>{resetConfig(); if(key=="batchImage"){setBatchQueueTimer(true);props.setLoading(true);}else{setBatchQueueTimer(false);}}}>
                        <Tab eventKey="singleImage" title="단일 이미지"  className="p-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Row>
                                            <Form.Label>API버전 선택</Form.Label>
                                        </Row>
                                        <Row>
                                            <InputGroup>
                                                <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                    <option value="" >API버전 선택</option>
                                                    <option value="2024-01-08" >2024-01-08</option>
                                                    <option value="2023-08-23" >2023-08-23</option>
                                                    <option value="2023-04-25" >2023-04-25</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Form.Group className="my-3">
                                                <Form.Label>이미지 선택</Form.Label>
                                                <Form.Control type="file" accept="image/jpg, image/jpeg, image/png" id="img_file" onChange={onLoadFile}/>
                                            </Form.Group>
                                            <Form.Control type="file" className="d-none" accept="image/jpg, image/jpeg, image/png" id="img_bg_file" ref={bg_input} onChange={onLoadBGFile}/>
                                        </Row>
                                        <Row className="my-2">
                                            <Form.Group>
                                                <Form.Check name="transparent" type="switch" checked={transparent} onChange={handleTransparent} label="배경 투명하게"></Form.Check>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Container className="text-secondary">
                                                <Row>
                                                    <strong className="fs-6">※ 배경 투명하게가 선택되면 png파일로 다운로드되고, 선택되지 않은 경우<br/> jpg파일로 다운로드 됩니다.</strong>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="mt-3">
                                            <Form.Label>이미지 미리보기</Form.Label>
                                            <Image src={img_preview} className="m-auto border" style={{maxWidth:"300px"}}/>
                                        </Row>
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>2023-04-25 : 신경망 기반 인공지능이 피사체를 제외한 이미지의 배경을 제거합니다.</span>
                                                        <span>2023-08-23 : 사람의 신체가 피사체인 이미지의 배경을 제거하는데 적합합니다.</span>
                                                        <span>2024-01-08[권장] : 통계적 알고리즘이 사용된 고성능 배경제거 모델을 사용합니다. 주로 인물 사진에 적합합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="float-end mt-4">
                                            <InputGroup>
                                                <Button variant="secondary" onClick={()=>{resetConfig();}}>초기화</Button>
                                                <Button onClick={()=>{Request();}}>배경제거</Button>
                                            </InputGroup>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>결과이미지</Form.Label>
                                        <Row>
                                            {
                                                editor_show == false ?
                                                    <Image src={img_result} className="m-auto border mt-5" style={{maxWidth:"450px"}}/>
                                                :
                                                <ImageEditor ref={imageEditor}  language="ko-KR"
                                                    includeUI={{
                                                      loadImage: {
                                                        path: background_img,
                                                        name: 'SampleImage',
                                                      },
                                                      locale: locale_ko_KR,
                                                      theme: namiTheme,
                                                      menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'mask', 'filter'],
                                                      uiSize: {
                                                        width: '100%',
                                                        height: '600px',
                                                      },
                                                      menuBarPosition: 'top',
                                                    }}
                                                    cssMaxHeight={500}
                                                    cssMaxWidth={500}
                                                    selectionStyle={{
                                                      cornerColor: '#0097dd',
                                                      cornerSize: 60,
                                                      rotatingPointOffset: 70,
                                                      allowTouchScrolling: true
                                                    }}
                                                    usageStatistics={true}
                                                />
                                            }
                                        </Row>
                                        <Row className="mt-5">
                                            {img_get !== "" ?
                                                <InputGroup>
                                                    <Button className="m-auto" onClick={()=>{editor_show ? downloadFile(imageEditor.current.getInstance().toDataURL()) : downloadFile(img_result)}}>다운로드</Button>
                                                </InputGroup>
                                                :
                                                ""
                                            }
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            { background_select ? 
                                <Container className="mt-5">
                                    <ListSearch goSearch={getBackgroundImageList}  search_kind={search_kind} setSearchKind={setSearchKind} query={query} setQuery={setQuery} filter={searchFilter}/>
                                    <ScrollMenu>
                                        <CardGalleryItem itemId={0} a={{image_name:"투명하게"}} onClick={handleClick(0)}/>
                                        <CardGalleryItem itemId={1} a={{image_name:"배경선택"}} onClick={handleClick(1)}/>
                                        {background_img_list.map(function(a, idx){
                                            return(
                                                <CardGalleryItem itemId={idx+background_img_offset} a={a} key={idx+1} onClick={handleClick(idx+background_img_offset)}/>
                                            )
                                        })}
                                        <CardGalleryItem itemId={-1} a={{image_name:"더보기"}} onClick={handleClick(-1)}/>
                                    </ScrollMenu>
                                </Container>
                                :
                                ""
                            }
                        </Tab>
                        <Tab eventKey="batchImage" title="여러 이미지"  className="p-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Row>
                                            <Form.Label>API버전 선택</Form.Label>
                                        </Row>
                                        <Row>
                                            <InputGroup>
                                                <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                    <option value="" >API버전 선택</option>
                                                    <option value="2024-01-08" >2024-01-08</option>
                                                    <option value="2023-08-23" >2023-08-23</option>
                                                    <option value="2023-04-25" >2023-04-25</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Form.Group className="my-3">
                                                <Form.Label>이미지 압축파일 선택</Form.Label>
                                                <Form.Control type="file" accept="application/zip" id="zip_file" onChange={onLoadFile}/>
                                            </Form.Group>
                                        </Row>
                                        <Row className="my-2">
                                            <Form.Group>
                                                <Form.Check name="transparent" type="switch" checked={transparent} onChange={handleTransparent} label="배경 투명하게"></Form.Check>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Container className="text-secondary">
                                                <Row>
                                                    <strong className="fs-6">※ 배경 투명하게가 선택되면 png파일로 다운로드되고, 선택되지 않은 경우<br/> jpg파일로 다운로드 됩니다.</strong>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="float-end"> 
                                            <InputGroup>
                                                <Button variant="secondary" onClick={()=>{resetConfig();}}>초기화</Button>
                                                <Button onClick={()=>{RequestBatch();}}>배경제거</Button>
                                            </InputGroup>
                                        </Row>
                                        <Row className="mt-5">
                                            <Form.Group>
                                                <Form.Label>상세로그</Form.Label>
                                                <Form.Control as="textarea"style={{backgroundColor:"white"}} rows={13} value={queuelog} readOnly/>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>2023-04-25 : 신경망 기반 인공지능이 피사체를 제외한 이미지의 배경을 제거합니다.</span>
                                                        <span>2023-08-23 : 사람의 신체가 피사체인 이미지의 배경을 제거하는데 적합합니다.</span>
                                                        <span>2024-01-08[권장] : 통계적 알고리즘이 사용된 고성능 배경제거 모델을 사용합니다. 주로 인물 사진에 적합합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="h-100">
                                        <Form.Label>작업목록</Form.Label>
                                        <Container className="border py-3 m-lg-3  p-lg-3" style={{overflowY:"scroll", height:"700px"}}>
                                            {
                                                batchQueueLog.map(function(a, idx){
                                                    return(
                                                        <Row key={idx} className="mb-3  mx-auto" style={{height:"fit-content"}}>
                                                            <Card>
                                                                <Card.Body className=" px-1 py-3 p-lg-3">
                                                                    <Card.Title className="justify-content-between d-flex">
                                                                        <span>{a.request_id}</span>
                                                                        <span>
                                                                            { a.status == "running" ?
                                                                                <img style={{width:"20px", marginRight:"1rem"}} src={process.env.PUBLIC_URL + '/assets/loading_circle.gif'}/>
                                                                                :
                                                                                ""
                                                                            }
                                                                            {a.progress}%
                                                                        </span>
                                                                    </Card.Title>
                                                                    <div className="mb-2 justify-content-between d-flex">
                                                                        <span>
                                                                            <span className="fs-6">시작시각 : {a.start_date}</span><br/>
                                                                                {
                                                                                    a.status == "finished" ?
                                                                                    <span className="fs-6">종료시각 : {a.finished_date}</span>
                                                                                    :
                                                                                    ""
                                                                                }
                                                                        </span>
                                                                        <span style={{minWidth:"40px"}}>{status[a.status]}</span>
                                                                    </div>
                                                                    <Row>
                                                                        <span>
                                                                            <Button variant="outline-secondary" className="me-3" onClick={()=>{queueloguid.current = a.uid; setQueueLog(a.log)}} >로그보기</Button>
                                                                            {a.status == "finished" ?
                                                                                <Button variant="outline-primary" onClick={()=>{downloadLink(a.download)}} >다운로드</Button>
                                                                                :
                                                                                ""
                                                                            }
                                                                        </span>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Row>
                                                    );
                                                })
                                            }
                                        </Container>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>

                    </Tabs>
                </Container>
           </section>
        </>
    );

}

  function CardGalleryItem({ onClick, a, itemId }) {  
    return (
        itemId<background_img_offset ?
        <Card onClick={() => onClick(itemId)} className="text-black bg-light d-inline-block me-3"  style={{width:"250px",height:"180px",cursor:"pointer"}}>
            <Card.ImgOverlay>
                <Card.Title style={{lineHeight:"150px", textAlign:"center"}}>{a.image_name}</Card.Title>
            </Card.ImgOverlay>
        </Card>
        :
        <Card onClick={() => onClick(itemId)} tabIndex={0} className="me-3 text-white bg-black d-inline-block" style={{width:"250px",height:"180px",cursor:"pointer"}}>
            <Card.Img className="h-100 opacity-50" src={`data:image/png;base64,${a.image}`}></Card.Img>
            <Card.ImgOverlay>
                <Card.Text>
                    {
                        a.hashtags != undefined ? 
                        a.hashtags.map(function(b, idx_){
                            return <span key={idx_}>#{b}</span>
                        })
                        :
                        ""
                    }
                </Card.Text>
            </Card.ImgOverlay>
        </Card>
    );
  }

export default WebAPI_BGRM;